import React from 'react'
import {Link} from 'react-router-dom'
import {Col, Row} from '../common/bootstrap'
import gql from 'graphql-tag'
import LazyLoad from 'react-lazyload'

import routesHelper from '@prepler/core/lib/routesHelper'
import eachSlice from '@prepler/core/lib/utils/eachSlice'

import SchoolItemSquare from './schoolItemSquare'
import TrendingSchool from './trendingSchool'
import Carousel from '../carousel'
import withStyles from './styles'

class Collection extends React.Component {
	constructor(props) {
		super(props);

		this.setPerPageBound = this.setPerPage.bind(this);

		this.state = {
			perPage: 1
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.setPerPageBound);
		this.setPerPageBound();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setPerPageBound);
	}

	setPerPage() {
		const {type} = this.props;
		const perPageArr = type === 'partners' ? [[992, 6], [768, 4], [600, 3], [500, 2], [0, 1]] : [[992, 3], [768, 2], [0, 1]];
		const perPage = perPageArr.find(([width, _perPage]) => window.innerWidth >= width)[1];
		if (this.state.perPage !== perPage) {
			this.setState({perPage});
		}
	}

	renderUniversities() {
		const {type, collection: {universities}, classes} = this.props;

		if (type === 'partners') return (
			<Carousel className={classes.carousel} perPage={this.state.perPage} controlSize={48}>
				{universities.slice(0, 9).map(({id, slug, logo, name}) => (
					<Link
						key={id}
						to={routesHelper.universityPath({id: slug || id})}
						title={name}
						className={classes.center}
					>
						<LazyLoad>
							<img alt="" className={classes.logoImage} loading="lazy" src={logo.gallery}/>
						</LazyLoad>
					</Link>
				))}
			</Carousel>
		);

		if (type === 'aside') return (
			eachSlice(universities, 2).map((items, index) =>
				<Row key={index}>
					{items.map((university) =>
						<Col key={university.id} xs={6}>
							<TrendingSchool university={university}/>
						</Col>
					)}
				</Row>
			)
		);

		return (
			<Carousel className={classes.carousel} perPage={this.state.perPage} controlSize={48}>
				{universities.slice(0, 9).map(university => (
					<SchoolItemSquare key={university.id} university={university}/>
				))}
			</Carousel>
		);
	}

	render() {
		const {type, collection, classes} = this.props;
		if (!collection || collection.universities.length === 0) return <div className={classes.collection}/>;
		const {id, name, description, universitiesCount} = collection;

		return (
			<div className={classes.collection} key={id}>
				{/*<Link to={{pathname: routesHelper.searchIdPath({id, section: 'collection'})}}>*/}
				{type === 'aside' ? (
					<p className={classes.h4}>{name}</p>
				) : (
					<p className={classes.h2}>{name}</p>
				)}
				{/*</Link>*/}
				{description && (
					// <Link to={{pathname: routesHelper.searchIdPath({id, section: 'collection'})}}>
					<p className={classes.h3}>{description.replace('%count%', universitiesCount)}</p>
					// </Link>
				)}
				{this.renderUniversities()}
			</div>
		);
	}
}

Collection.fragments = {
	university: (type) => {
		if (type === 'partners') return (gql`
			fragment CollectionUniversity on University {
				id
				slug
				logo {
					gallery
				}
				name
			}
		`);
		if (type === 'aside') return (gql`
			fragment CollectionUniversity on University {
				id
				...TrendingSchoolUniversity
			}
			${TrendingSchool.fragments.university}
		`);
		return (gql`
			fragment CollectionUniversity on University {
				id
				...SchoolItemSquareUniversity
			}
			${SchoolItemSquare.fragments.university}
		`);
	},
};

export default withStyles(Collection);
