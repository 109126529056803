import React, {useCallback, useState} from 'react'
import {useApolloClient} from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {Tooltip} from 'react-tippy'
import ReactSVG from 'react-svg'

import {destroySessionMinimal} from '@prepler/core/lib/actions/sessionActions'
import {checkUserRoles} from '@prepler/core/lib/utils'
import routesHelper from '@prepler/core/lib/routesHelper'

import {clearAuthToken} from '../../apollo/client'
import {clearAuthToken as clearAuthTokenCookie} from '../../utils/cookies'
import shareWithMe from '../../assets/images/shared-with-me.svg'
import useHandleOutsideClick from '../../utils/useHandleOutsideClick'
import {useStyles} from './userStyle'

const AuthorizedUser = ({className, transparent, user, profile}) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const apolloClient = useApolloClient();
	const signOut = () => dispatch(destroySessionMinimal(user)).then(() => clearAuthToken(apolloClient)).then(clearAuthTokenCookie);
	const goalsCount = useSelector((state) => (state.profile && state.profile.goals_count) || 0);
	const sharedGoalsCount = useSelector((state) => (state.profile && state.profile.shared_goals_count) || 0);

	const {push} = useHistory();

	const [open, setOpen] = useState(false);
	const toggleMenu = () => setOpen(!open);
	const closeMenu = () => setOpen(false);
	const ref = useHandleOutsideClick(useCallback(closeMenu, []));

	const renderAvatar = (options) => {
		const {profile_picture: profilePicture, first_name: firstName, last_name: lastName} = profile;
		options = Object.assign({large: false}, options);
		if (profilePicture.endsWith('profile_pictures/_default/original.jpg')) {
			return (
				<div
					className={classNames(classes.image, classes.defaultImage, {
						[classes.large]: options.large, [classes.transparent]: options.transparent
					})}
					onDoubleClick={onAvatarDoubleClick}
				>
					{firstName && firstName[0].toUpperCase()}{lastName && lastName[0].toUpperCase()}
				</div>
			);
		} else {
			return (
				<div
					className={classNames(classes.image, {[classes.large]: options.large})}
					style={{backgroundImage: `url(${profilePicture})`}}
					onDoubleClick={onAvatarDoubleClick}
				/>
			);
		}
	};

	const onAvatarDoubleClick = () => {
		checkUserRoles(user, ['admin', 'operator']) && push(routesHelper.adminPath());
	};

	return (
		<div className={classNames(className, classes.container, {[classes.transparent]: transparent})}>
			<div className={classes.favorites}>
				<Tooltip
					title="My Schools"
					arrow={true}
					delay={500}
					theme="prepler"
				>
					<Link to={routesHelper.accountPath({tab: 'my_schools'})}>
						<i className="ion-ios-heart-outline"/>
						<span>{user.universitiesCount}</span>
					</Link>
				</Tooltip>
			</div>
			<div className={classes.favorites}>
				<Tooltip
					title="Degree Plans"
					arrow={true}
					delay={500}
					theme="prepler"
				>
					<Link to={routesHelper.goalsPath()}>
						<i className="ion-compass"/>
						<span>{goalsCount}</span>
					</Link>
				</Tooltip>
			</div>
			<div className={classes.favorites}>
				<Tooltip
					title="Shared Degree Plans"
					arrow={true}
					delay={500}
					theme="prepler"
				>
					<Link to={routesHelper.sharedGoalsPath()}>
						<ReactSVG src={shareWithMe} className={classes.shareWithMe} wrapper="div"/>
						<span>{sharedGoalsCount}</span>
					</Link>
				</Tooltip>
			</div>
			<div className={classes.info}>
				<a key={open} onClick={toggleMenu}>
					{renderAvatar({large: false, transparent})}
				</a>
			</div>
			<div className={classNames(classes.menu, {[classes.open]: open})} ref={ref}>
				<div className={classNames({[classes.arrow]: open})}/>
				<ul>
					<li>
						{renderAvatar({large: true, transparent})}
						<div className={classes.infoLarge}>
							<div className={classes.infoName}>
								{profile.first_name}
								<br/>
								{profile.last_name}
							</div>
							{profile.completion && (
								<div className={classes.infoCompletion}>
									Profile completion: {Math.round(profile.completion)}%
								</div>
							)}
						</div>
					</li>
					<li className={classNames(classes.hover, classes.separator)}>
						<Link to={routesHelper.accountPath()} onClick={closeMenu}>
							<span>Personal</span>
						</Link>
					</li>
					<li className={classNames(classes.hover)}>
						<Link to={routesHelper.accountPath({tab: 'about'})} onClick={closeMenu}>
							<span>About</span>
						</Link>
					</li>
					<li className={classNames(classes.hover)}>
						<Link to={routesHelper.accountPath({tab: 'my_schools'})} onClick={closeMenu}>
							<span>My Schools</span>
							{user.universitiesCount > 0 && <span className={classes.badge}>{user.universitiesCount}</span>}
						</Link>
					</li>
					<li className={classNames(classes.hover)}>
						<Link to={routesHelper.goalsPath()} onClick={closeMenu}>
							<span>My Degree Plans</span>
							{goalsCount > 0 && <span className={classes.badge}>{goalsCount}</span>}
						</Link>
					</li>
					<li className={classNames(classes.hover)}>
						<Link to={routesHelper.sharedGoalsPath()} onClick={closeMenu}>
							<span>Shared Degree Plans</span>
							{sharedGoalsCount > 0 && <span className={classes.badge}>{sharedGoalsCount}</span>}
						</Link>
					</li>
					<li className={classNames(classes.hover)}>
						<Link to={routesHelper.freeMembershipPath()} onClick={closeMenu}>
							<span>Free Membership</span>
						</Link>
					</li>
					<li className={classNames(classes.hover)}>
						<Link to={routesHelper.demoPath()} onClick={closeMenu}>
							<span>Request a Demo</span>
						</Link>
					</li>
					<li className={classNames(classes.hover, classes.separator)}>
						<a onClick={signOut}>
							Sign Out
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

AuthorizedUser.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired
};

export default AuthorizedUser;
