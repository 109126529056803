import React from 'react'
import {Redirect, Router, Route, Switch} from 'react-router'
import {withRouter} from 'react-router-dom'
import {createBrowserHistory as createHistory} from 'history'
import ReactGA from 'react-ga'
// import ReactPixel from 'react-facebook-pixel'
import App from '@prepler/core/lib/app'
import routesHelper, {routes, bestUniversitiesPath, bestUniversitiesUrl} from '@prepler/core/lib/routesHelper'
import ApplicationLayout from './components/layout/applicationLayout'
import ArticleLayout from './components/layout/articleLayout'
import RequireAuth from './components/common/requireAuth'
import WithStatus from './components/common/withStatus'
import HomeLayout from './components/layout/homeLayout'
import SearchLayout from './components/layout/searchLayout'
import UniversityLayout from './components/layout/universityLayout'
// import SmartBanner from './components/layout/smartBanner'
import CookiesPopup from './components/layout/cookiesPopup'
import Helmet from './components/common/helmet'
import RemoveTrailingSlash from './components/common/removeTrailingSlash'
import ScrollToTopOnMount from './components/common/scrollToTopOnMount'
import {getGist} from './initializers/gist'
// import NotAvailable from './components/notAvailable'
import Home from './components/home'

import {
	AdminRoutes,
	// Chat,
	Confirmation, About, PrivacyPolicy, CookiesPolicy, TermsOfUse, ContactUs, Promo, NotFound, NotFoundRedirect,
	AccountPage, ArticlesList, ArticleShow, FreeMembership, UniversityOverview, UniversityAdmission, UniversityTuition,
	UniversityLoanCalculator, UniversityAcademics, UniversityStudentLife, UniversityAfterGraduation, UniversityReviews,
	UniversityReviewNew, GpaCalculator, LoanCalculator, ForStudents, ForParents, ForPartners, UniversityGpaCalculator, UniversityCourses,
	Rankings, SportsList, SportsItem,
	ChatGPTConnect,
	// Learning
	Goals, GoalShow, GoalNew, GoalDuplicate, Vote
} from './components/loadable/routes'

import wrapHistory from './utils/wrapHistory'
import StaticRouter from './utils/staticRouter' // import {StaticRouter} from 'react-router'

const history = App.type === 'node' ? null : wrapHistory(createHistory());
App.history = history;

const gistUpdate = (location) => {
	getGist().trackPageView();
	const paths = [
		routesHelper.rootRegexp(),
		routesHelper.partnerRegexp(),
		routesHelper.forStudentsRegexp(),
		routesHelper.goalNewRegexp(),
		routesHelper.goalDuplicateRegexp(),
		routesHelper.universityGoalNewRegexp(),
		routesHelper.universityMajorGoalNewRegexp(),
		routesHelper.aboutRegexp(),
		routesHelper.contactUsRegexp(),
	];
	getGist().chat(paths.includes(location.pathname) ? 'show' : 'hide');
};

class Routes extends React.Component {
	componentDidMount() {
		ReactGA.pageview(`${history.location.pathname}${history.location.search}${history.location.hash}`);
		history.listen((location, _action) => ReactGA.pageview(`${location.pathname}${location.search}${location.hash}`));

		// ReactPixel.init('1555716201413712', {}, {autoConfig: true, debug: false});
		// ReactPixel.pageView();
		// history.listen((location, _action) => { ReactPixel.pageView(); });

		gistUpdate(history.location);
		history.listen((location, _action) => gistUpdate(location));
		document.addEventListener('gistChatReady', function () {
			gistUpdate(history.location);
		});
	}

	render() {
		const {routerContext: context, routerLocation: location} = this.props;
		const Component = context ? StaticRouter : Router;
		return (
			<Component {...{context, location, history}}>
				<React.Fragment>
					<Helmet
						title="Prepler"
						titleTemplate="%s"
						description="A better approach to college preparation and degree completion. Free personalized degree plans that help graduate sooner, with less student debt."
						author="prepler"
					>
						<html lang="en"/>
						<meta name="keyword" content=""/>
					</Helmet>
					<RemoveTrailingSlash />
					<ScrollToTopOnMount />
				  {/*<SmartBanner/>*/}
					<CookiesPopup/>
					<Switch>
						<Route path="/admin">
							<AdminRoutes/>
						</Route>

						{/*<ApplicationLayout exact path={routesHelper.chatRegexp()} component={RequireAuth(Chat, 'admin')} canonicalUrl={routesHelper.chatUrl}/>*/}
						<ApplicationLayout exact path={routesHelper.userConfirmationRegexp()} component={Confirmation}/>
						<Redirect exact from={routes.searchOldWithSection} to={routes.search}/>
						<Redirect exact from={routes.oldUniversity} to={routes.university}/>
						<Redirect exact from={routes.oldUniversityAdmission} to={routes.universityAdmission}/>
						<Redirect exact from={routes.oldUniversityTuition} to={routes.universityTuition}/>
						<Redirect exact from={routes.oldUniversityAcademics} to={routes.universityAcademics}/>
						<Redirect exact from={routes.oldUniversityStudentLife} to={routes.universityStudentLife}/>
						<Redirect exact from={routes.oldUniversityCampus} to={routes.universityCampus}/>
						<Redirect exact from={routes.oldUniversityAfterGraduation} to={routes.universityAfterGraduation}/>
						<Redirect exact from={routes.oldUniversityReviews} to={routes.universityReviews}/>
						<Redirect exact from={routes.oldUniversityReviewNew} to={routes.universityReviewNew}/>
						<Redirect exact from={routes.oldUniversityLoanCalculator} to={routes.universityLoanCalculator}/>
						<Redirect exact from={routes.oldUniversities} to={routes.universities}/>
						<Redirect exact from={routes.oldUniversityGoals} to={routes.universityGoals}/>
						<Redirect exact from={routes.oldUniversityMajorGoals} to={routes.universityMajorGoals}/>
						<Redirect exact from={routes.oldUniversityGoalNew} to={routes.universityGoalNew}/>
						<Redirect exact from={routes.oldUniversityMajorGoalNew} to={routes.universityMajorGoalNew}/>
						<Redirect exact from={routes.oldUniversityCalculator} to={routes.universityCalculator}/>
						<Redirect exact from="/best-privatecolleges" to="/best-private-colleges"/>
						<Redirect exact from="/best-for-profitcolleges" to="/best-for-profit-colleges"/>
						<Redirect exact from="/best-publiccolleges" to="/best-public-colleges"/>
						<Redirect exact from="/best-public-4-yearcolleges" to="/best-public-4-year-colleges"/>
						<Redirect exact from="/best-4-yearcolleges" to="/best-4-year-colleges"/>
						<Redirect exact from="/best-communitycolleges" to="/best-community-colleges"/>
						<Redirect exact from="/best-juniorcolleges" to="/best-junior-colleges"/>
						<Redirect exact from="/best-religiouscolleges" to="/best-religious-colleges"/>
						<Redirect exact from="/best-catholiccolleges" to="/best-catholic-colleges"/>
						{[
							{from: 'computer-sciences', to: 'computer-science'},
							{from: 'veterinary', to: 'veterinary-studies'},
							{from: 'marine-biology', to: 'marine-biology-and-oceanography'},
							{from: 'aviation', to: 'aeronautics-and-aviation-technology'},
							{from: 'pre-medical', to: 'pre-medicine-studies'}
						].map(({from, to}) => <Redirect key={`redirect-best-${from}`} exact from={routes.bestUniversitiesByMajor.replace(/:rating_major_query[^\/]+/, from)} to={routes.bestUniversitiesByMajor.replace(/:rating_major_query[^\/]+/, to)}/>)}
						<Redirect exact from={routes.bestUniversitiesByMajor.replace(/:rating_major_query[^\/]+/, 'liberal-arts')} to={routes.bestUniversities.replace(/:bestSection[^\/]+/, 'liberal-arts')}/>

						<SearchLayout
							exact path={routesHelper.searchRegexp()}
							routePath={routesHelper.searchPath} canonicalUrl={routesHelper.searchUrl}
						/>
						<SearchLayout
							exact path={routesHelper.searchQueryRegexp()}
							routePath={routesHelper.searchQueryPath} canonicalUrl={routesHelper.searchUrl}
						/>
						<SearchLayout
							exact path={routesHelper.searchIdRegexp()}
							routePath={routesHelper.searchIdPath} canonicalUrl={routesHelper.searchUrl}
						/>
						<SearchLayout
							exact path={routesHelper.searchIdWithQueryRegexp()}
							routePath={routesHelper.searchIdWithQueryPath} canonicalUrl={routesHelper.searchUrl}
						/>
						<SearchLayout
							exact path={routesHelper.searchLocationStateRegexp()}
							routePath={routesHelper.searchLocationStatePath} canonicalUrl={routesHelper.searchUrl}
						/>
						<SearchLayout
							exact path={[
								routesHelper.bestUniversitiesRegexp(),
								routesHelper.bestUniversitiesBySatRegexp(),
								routesHelper.bestUniversitiesByMajorRegexp()]}
							routePath={bestUniversitiesPath}
							canonicalUrl={bestUniversitiesUrl}
						/>

						<ApplicationLayout exact path={routesHelper.accountRegexp()} component={AccountPage} canonicalUrl={routesHelper.accountUrl}/>
						<ApplicationLayout exact path={routesHelper.aboutRegexp()} component={About} wide={true} canonicalUrl={routesHelper.aboutUrl}/>
						<ApplicationLayout exact path={routesHelper.privacyPolicyRegexp()} component={PrivacyPolicy} wide={true} canonicalUrl={routesHelper.privacyPolicyUrl}/>
						<ApplicationLayout exact path={routesHelper.cookiesPolicyRegexp()} component={CookiesPolicy} wide={true} canonicalUrl={routesHelper.cookiesPolicyUrl}/>
						<ApplicationLayout exact path={routesHelper.termsOfUseRegexp()} component={TermsOfUse} wide={true} canonicalUrl={routesHelper.termsOfUseUrl}/>
						<ApplicationLayout exact path={routesHelper.contactUsRegexp()} component={ContactUs} canonicalUrl={routesHelper.contactUsUrl}/>
						{/*<ApplicationLayout exact path={routesHelper.promoRegexp()} component={Promo} canonicalUrl={routesHelper.promoUrl}/>*/}
						<Redirect exact from={routes.promo} to={routes.root}/>
						<ApplicationLayout exact path={routesHelper.demoRegexp()} component={ContactUs} canonicalUrl={routesHelper.demoUrl}/>
						<ApplicationLayout exact path={routesHelper.goalsRegexp()} component={Goals} wide={true} canonicalUrl={routesHelper.goalsUrl}/>
						<ApplicationLayout exact path={routesHelper.sharedGoalsRegexp()} component={Goals} wide={true} canonicalUrl={routesHelper.sharedGoalsUrl}/>
						<ApplicationLayout exact path={[
							routesHelper.goalNewRegexp(),
							routesHelper.universityGoalNewRegexp(),
							routesHelper.universityMajorGoalNewRegexp()
						]} component={GoalNew} wide={true}/>
						<ApplicationLayout exact path={routesHelper.goalDuplicateRegexp()} component={GoalDuplicate} wide={true}/>
						<ApplicationLayout exact path={routesHelper.universityGoalsRegexp()} component={Goals} wide={true}/>
						<ApplicationLayout exact path={routesHelper.universityMajorGoalsRegexp()} component={Goals} wide={true}/>
						<ApplicationLayout exact path={routesHelper.goalsVoteRegexp()} component={Vote} wide={true} hideFooter={true}/>
						<ApplicationLayout exact path={routesHelper.goalRegexp()} component={GoalShow} wide={true} hideFooter={true}/>
						<UniversityLayout exact path={routesHelper.universityRegexp()} component={UniversityOverview}/>
						<UniversityLayout exact path={routesHelper.universityAdmissionRegexp()} component={UniversityAdmission}/>
						<UniversityLayout exact path={routesHelper.universityTuitionRegexp()} component={UniversityTuition}/>
						<UniversityLayout exact path={routesHelper.universityGpaCalculatorRegexp()} component={UniversityGpaCalculator}/>
						<UniversityLayout exact path={routesHelper.universityLoanCalculatorRegexp()} component={UniversityLoanCalculator}/>
						<UniversityLayout exact path={routesHelper.universityAcademicsRegexp()} component={UniversityAcademics}/>
						<UniversityLayout exact path={[
							routesHelper.universityCoursesRegexp(),
							routesHelper.universityCoursesByLetterRegexp(),
							routesHelper.universityCourseRegexp()
						]} component={UniversityCourses}/>
						<UniversityLayout exact path={routesHelper.universityStudentLifeRegexp()} component={UniversityStudentLife}/>
						<UniversityLayout exact path={routesHelper.universityAfterGraduationRegexp()} component={UniversityAfterGraduation}/>
						<UniversityLayout exact path={routesHelper.universityReviewsRegexp()} component={UniversityReviews} routePath={routesHelper.universityReviewsPath}/>
						<UniversityLayout exact path={routesHelper.universityReviewNewRegexp()} component={withRouter(RequireAuth(UniversityReviewNew, {type: 'current', path: routesHelper.universityReviewsPath}))}/>
						<ApplicationLayout exact path={routesHelper.gpaCalculatorRegexp()} component={GpaCalculator} canonicalUrl={routesHelper.gpaCalculatorUrl}/>
						<ApplicationLayout exact path={routesHelper.loanCalculatorRegexp()} component={LoanCalculator} canonicalUrl={routesHelper.loanCalculatorUrl}/>
						<ApplicationLayout exact path={routesHelper.rankingsRegexp()} component={Rankings} canonicalUrl={routesHelper.rankingsUrl}/>
						<ApplicationLayout exact path={routesHelper.sportsRegexp()} component={SportsList} canonicalUrl={routesHelper.sportsUrl}/>
						<ApplicationLayout exact path={routesHelper.sportRegexp()} component={SportsItem}/>
						<ArticleLayout exact path={routesHelper.articlesRegexp()} component={ArticlesList}/>
						<ArticleLayout exact path={routesHelper.articleRegexp()} component={ArticleShow}/>
						<ApplicationLayout exact path={routesHelper.freeMembershipRegexp()} component={RequireAuth(FreeMembership)} canonicalUrl={routesHelper.freeMembershipUrl}/>
						<ApplicationLayout exact path={routesHelper.chatGPTConnectRegexp()} component={withRouter(RequireAuth(ChatGPTConnect, {type: 'current'}))} canonicalUrl={routesHelper.chatGPTConnectUrl}/>
						<Redirect exact from="/privacy_policy" to={routesHelper.privacyPolicyPath()}/>
						<Redirect exact from="/cookies_policy" to={routesHelper.cookiesPolicyPath()}/>
						<Redirect exact from="/terms_of_use" to={routesHelper.termsOfUsePath()}/>
						<Redirect exact from="/contact_us" to={routesHelper.contactUsPath()}/>
						<Redirect exact from="/become_a_partner" to={routesHelper.partnerPath()}/>
						<Redirect exact from="/studyplans" to={routesHelper.goalsPath()}/>
						<Redirect exact from="/study-plans" to={routesHelper.goalsPath()}/>
						<Redirect exact from="/study-plans/:id" to={routesHelper.goalRegexp()}/>
						<Redirect exact from="/shared-study-plans" to={routesHelper.sharedGoalsRegexp()}/>
						<Redirect exact from="/create-new-study-plan" to={routesHelper.goalNewPath()}/>
						<Redirect exact from="/study-plans/:id/duplicate" to={routesHelper.goalDuplicateRegexp()}/>
						<HomeLayout exact path={routesHelper.partnerRegexp()} component={ForPartners} canonicalUrl={routesHelper.partnerUrl}/>
						<HomeLayout exact path={routesHelper.forStudentsRegexp()} component={ForStudents} canonicalUrl={routesHelper.forStudentsUrl}/>
						<HomeLayout exact path={routesHelper.forParentsRegexp()} component={ForParents} canonicalUrl={routesHelper.forParentsUrl}/>
						<HomeLayout exact path="/(|#[a-zA-Z]+)" component={Home} canonicalUrl={routesHelper.rootUrl}/>
						<ApplicationLayout path="/404" component={WithStatus(NotFound, 404)}/>
						<ApplicationLayout path="*" component={window._static && App.type !== 'node' ? NotFoundRedirect : WithStatus(NotFound, 404)}/>
					</Switch>
				</React.Fragment>
			</Component>
		);
	}
}

export default Routes;
