import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {withRouter} from 'react-router-dom'
import injectSheet from 'react-jss'

const styles = theme => ({
	link: {
		cursor: 'pointer'
	}
});

// Functional component
class LinkModal extends React.Component {
	path() {
		const {history} = this.props;
		return history.location.pathname + history.location.search;
	}

	hash() {
		const {modal} = this.props;
		return modal ? '#modal' + modal : null;
	}

	handleClick() {
		const {history: {push}, modal, disabled, state} = this.props;
		!disabled && push(this.path() + this.hash(), modal ? {modal: modal, ...state} : null)
	}

	isActive() {
		const {history} = this.props;
		return this.hash() === history.location.hash;
	}

	render() {
		const {modal, match, location, history, staticContext, activeClassName, className, state, children, classes, ...rest} = this.props;
		if (children && children.type) {
			return <children.type
				{...children.props}
				className={classNames(classes.link, className, (children.props || {}).className, {[activeClassName]: this.isActive()})}
				onClick={this.handleClick.bind(this)}
			/>;
		} else {
			return <a
				className={classNames(classes.link, className, {[activeClassName]: this.isActive()})}
				onClick={this.handleClick.bind(this)}
				{...rest}
			>
				{children}
			</a>;
		}
	}
}
LinkModal.defaultProps = {
	activeClassName: 'active',
	disabled: false
};

LinkModal.propTypes = {
	modal: PropTypes.string,
	disabled: PropTypes.bool
};

export default injectSheet(styles)(withRouter(LinkModal));
