import React from 'react'
import {connect} from 'react-redux'
import isEqual from 'lodash-es/isEqual'
import App from '@prepler/core/lib/app'
import {denormalizeUser} from '@prepler/core/lib/schemas'
import {checkUserRoles} from '@prepler/core/lib/utils'
export {checkUserRoles} from '@prepler/core/lib/utils'

const RequireAuth = (ComposedComponent, options) => {
	!(typeof options === 'object' && options.length === undefined) && (options = {role: options});
	options = {type: 'default', role: undefined, check: App.type !== 'node', ...options};
	// eslint-disable-next-line react/no-deprecated
	class Authentication extends React.Component {
		UNSAFE_componentWillMount() {
			options.check && this.check();
		}

		componentDidUpdate() {
			options.check && this.check();
		}

		check() {
			const {authentication: {authorized, user}} = this.props;
			if (!authorized) {
				this.redirect('You are not authorized!');
				return;
			}
			try {
				checkUserRoles(user, options.role, {error: true});
			} catch (e) {
				this.redirectToRoot(e.message);
			}
		}

		redirect(error) {
			const {history: {push}, location: {pathname, search, hash, state}, match: {params}} = this.props;
			if (options.type === 'current') {
				const pathOptions = options.path ? {closePath: options.path(params)} : {};
				if (hash !== '#modalSignIn' || !isEqual(state, pathOptions)) {
					push(`${pathname}${search}#modalSignIn`, pathOptions);
				}
				return;
			}
			push('/#modalSignIn', {error});
		}

		redirectToRoot(message) {
			const {history: {push}} = this.props;
			push('/');
			App.createNotification({type: 'error', message});
		}

		render() {
			const {authentication: {authorized, user}, ...props} = this.props;
			return authorized && checkUserRoles(user, options.role) || options.type === 'current' ? <ComposedComponent {...props}/> : null;
		}
	}

	function mapStateToProps(state) {
		const {authorized, token, user} = state.auth;
		return {
			authentication: {
				authorized: authorized && token,
				user: user || denormalizeUser(user && user.id, state.entities)
			},
		};
	}

	return connect(mapStateToProps)(Authentication);
};

const AuthenticationComponent = ({authorized, user, role, children}) => !authorized || !checkUserRoles(user, role) ? null : children;

function mapStateToProps(state) {
	const {authorized, token, user} = state.auth;
	return {
		authorized: authorized && token,
		user: user || denormalizeUser(user && user.id, state.entities)
	};
}

export const RequireAuthComponent = connect(mapStateToProps)(AuthenticationComponent);

export default RequireAuth;
