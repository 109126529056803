import React from 'react'
// import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {createUseStyles} from 'react-jss'

import routesHelper, {goalNewPath} from '@prepler/core/lib/routesHelper'

// import YoutubeIframe from './youtubeIframe'
import VimeoIframe from './vimeoIframe'
import NoSSRStatic from '../common/noSsrStatic'
import UkraineBanner from './ukraineBanner'

const useStyles = createUseStyles(theme => ({
	root: {
		position: 'relative',
		alignItems: 'center',
		backgroundColor: '#123456',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: '0 30px',
		minHeight: '500px',
		'@media screen and (min-width: 992px)': {
			minHeight: '700px',
		}
	},
	mask: {
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		backgroundColor: '#222222a0',
	},
	titlePadding: {
		flexGrow: '1',
		zIndex: '1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '1300px',
		flexDirection: 'column',
		paddingTop: '100px',
		paddingBottom: '20px',
		'@media screen and (min-width: 420px)': {
			paddingTop: '70px'
		},
		'@media screen and (min-width: 768px)': {
			flexDirection: 'row',
		},
		'@media screen and (min-width: 992px)': {
			paddingTop: '63px'
		},
		'& > div > *': {
			'@media screen and (min-width: 768px)': {
				textAlign: 'left',
			},
		},
		'& > div:first-child': {
			'@media screen and (min-width: 768px)': {
				paddingRight: '15px',
			},
		},
	},
	video: {
		width: '280px',
		height: '157px',
		'@media screen and (min-width: 640px)': {
			width: '373px',
			height: '210px',
		},
		'@media screen and (min-width: 992px)': {
			width: '448px',
			height: '252px',
		},
		'@media screen and (min-width: 1200px)': {
			width: '610px',
			height: '343px',
		},
	},
	title: {
		color: theme.colors.white,
		fontFamily: theme.fonts.brand,
		lineHeight: '1.3',
		fontSize: '22px',
		// fontWeight: theme.fontWeight.bold,
		marginBottom: '35px',
		letterSpacing: '-1.3px',
		textAlign: 'center',
		textShadow: '0px 1px 1px #000000',
		'@media screen and (min-width: 640px)': {
			// lineHeight: '1.1',
			fontSize: '30px'
		},
		'@media screen and (min-width: 768px)': {
			// lineHeight: '1.1',
			fontSize: '27px'
		},
		'@media screen and (min-width: 992px)': {
			fontSize: '39px'
		},
		'@media screen and (min-width: 1200px)': {
			fontSize: '42px'
		}
	},
	subtitle: {
		color: '#bfcede',
		fontFamily: theme.fonts.base,
		fontSize: '12px',
		lineHeight: '1.5',
		// fontWeight: theme.fontWeight.normal,
		// letterSpacing: '-0.65px',
		marginBottom: '26px',
		padding: '0px',
		textAlign: 'center',
		// textShadow: '0px 1px 1px #000000',
		width: '100%',
		maxWidth: '430px',
		'@media screen and (min-width: 370px)': {
			padding: '0 18px',
		},
		'@media screen and (min-width: 640px)': {
			fontSize: '12px',
		},
		'@media screen and (min-width: 768px)': {
			maxWidth: '390px',
			padding: '0px',
		},
		'@media screen and (min-width: 992px)': {
			maxWidth: '560px',
			fontSize: '17px',
			marginBottom: '40px'
		},
		'@media screen and (min-width: 1200px)': {
			maxWidth: '590px',
			// fontSize: '18px',
			// marginBottom: '40px'
		}
	},
	actions: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'@media screen and (min-width: 768px)': {
			justifyContent: 'left',
		},
		'& a': {
			display: 'block',
			margin: '0 0px 20px',
			'@media screen and (min-width: 768px)': {
				margin: '0',
			},
			// fontWeight: theme.fontWeight.bold,
		},
		'& a.btn-success:not(:hover)': {
			// backgroundColor: theme.colors.success,
		},
		'& a.btn-primary:not(:hover)': {
			backgroundColor: theme.colors.primary,
		},
	},
	noBorder: {
		border: 'none',
		fontWeight: theme.fontWeight.normal,
		fontSize: '12px',
		padding: '10px',
		'@media screen and (min-width: 768px)': {
			fontSize: '17px',
			padding: '16px',
		},
	},
	academics: {
		zIndex: '1',
		flex: '0 0 auto',
		width: 'calc(100% + 60px)',
		display: 'flex',
		justifyContent: 'center',
		padding: '0px 30px',
		backgroundColor: '#12253a',
	},
	academicsContainer: {
		display: 'flex',
		width: '100%',
		maxWidth: '1300px'
	},
	academicsContent: {
		flex: '1 1 auto',
		color: '#fff',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '11px',
		padding: '6px 0px',
		flexWrap: 'wrap',
		'@media screen and (min-width: 420px)': {
			flexWrap: 'nowrap',
			justifyContent: 'space-between',
		},
		'@media screen and (min-width: 768px)': {
			fontSize: '14px',
			padding: '7px 0px',
		},
		'@media screen and (min-width: 992px)': {
			fontSize: '16px',
			padding: '9px 0px'
		},
		'@media screen and (min-width: 1200px)': {
			fontSize: '17px',
			padding: '11px 0px'
		},
		'& .glyphicon': {
			fontSize: '125%',
			verticalAlign: 'sub'
		},
		'& .btn': {
			'@media screen and (min-width: 420px)': {
				marginLeft: '10px',
			},
			backgroundColor: theme.colors.mediumBlue,
		},
		'& a': {
			color: '#fff',
		},
	},
	phone: {
		display: 'flex',
		alignItems: 'center',
		'& > a': {
			color: '#fff',
			'& > i': {
				fontSize: '1.5em',
				marginRight: '10px',
				verticalAlign: 'sub',
			},
		},
	},
	edit: {
		display: 'inline',
		color: '#fff',
		fontWeight: theme.fontWeight.bold,
		'&:hover': {
			color: '#fff',
			textDecoration: 'none'
		}
	}
}));

const DiscoverHome = () => {
	const classes = useStyles();
	// const authorized = useSelector(({auth: {authorized}}) => authorized);

	return (
		<div className={classes.root}>
			{/*<div className={classes.mask} />*/}
			<div className={classes.titlePadding}>
				<div>
					<h1 className={classes.title}>
						The college course planner<br/>you've been looking for
					</h1>
					<h3 className={classes.subtitle}>
						Our free online guidance platform provides college students like you
						with step-by-step recommendations and automated tools
						to help you graduate college quickly.
					</h3>
					<div className={classes.actions}>
						<NoSSRStatic>
							{/*<Link to={authorized ? goalNewPath() : '#modalSignIn'} className={classNames('btn', 'btn-lg', 'btn-success', classes.noBorder)}>*/}
							<Link to={goalNewPath()} className={classNames('btn', 'btn-lg', 'btn-success', classes.noBorder)}>
								GET STARTED
							</Link>
						</NoSSRStatic>
					</div>
					<UkraineBanner/>
				</div>
				<div className={classes.video}>
					{/*<YoutubeIframe id="SZB0BSAtcbI" title="Product demo"/>*/}
					<VimeoIframe id="420413661" imgId="1257808752-ff3c023ea9317e07bcc8491a75e97811dc564f434e1093b14" title="Product demo"/>
				</div>
			</div>
			{/*
			<div className={classes.academics}>
				<div className={classes.academicsContainer}>
					<div className={classes.academicsContent}>
						<Link to={routesHelper.demoPath()}>
							Can Prepler help your campus? Let’s talk.
						</Link>
						<div className={classes.phone}>
							<a href="tel:1.833.773.7537">
								<i className="ion-ios-telephone"/>
								1.833.PREPLER (773.7537)
							</a>
						</div>
					</div>
				</div>
			</div>
			*/}
		</div>
	);
};

export default DiscoverHome;
