import {createUseStyles} from 'react-jss'

const styles = (theme) => ({
	minimized: {},
	search: {
		zIndex: '1',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		flexGrow: '1',
		width: '100%',
		'&$minimized': {
			flexGrow: '0',
			width: '100%',
			maxWidth: '380px',
			'@media screen and (min-width: 870px)': {
				width: 'calc(100% - 360px)',
			},
			'@media screen and (min-width: 992px)': {
				width: 'calc(100% - 375px)',
			},
			// '@media screen and (min-width: 1025px)': {
			// 	width: 'calc(100% - 410px)',
			// },
			// '@media screen and (min-width: 1175px)': {
			// 	width: 'calc(100% - 560px)',
			// },
			// '@media screen and (min-width: 1235px)': {
			// 	width: 'calc(100% - 620px)',
			// },
		},
		'& $form': {
			border: '1px solid #c6cad0',
			borderRadius: '3px',
			backgroundColor: '#eef0f3',
		},
		'&$minimized$transparent $form': {
			color: '#fff',
			borderColor: '#c6cad060',
			backgroundColor: '#eef0f340',
			transition: 'all .25s ease-out',
		},
		'& $form $input': {
			padding: '0px 5px',
		},
		'&$minimized $input': {
			padding: '0px 5px',
		},
		'&$minimized$transparent $input::placeholder': {
			color: '#ddd',
		},
		'& $button': {
			fontSize: '40px',
			paddingRight: '5px',
		},
		'&$minimized $button': {
			fontSize: '26px',
			paddingRight: '0px',
		},
		'&$minimized$transparent $button': {
			color: '#fff',
		},
		transition: 'all .25s ease-out',
		margin: '0 10px 0 15px',
		'@media screen and (min-width: 992px)': {
			margin: '0 25px 0 25px',
		}
	},
	typesSelect: {
		'@media screen and (min-width: 768px)': {
			left: '24px',
		},
		display: 'flex',
		width: '105px',
	},
	form: {
		width: '100%',
		display: 'flex',
		height: '32px',
		padding: '5px',
	},
	input: {
		width: 'inherit',
		minWidth: '50px',
		transition: 'all .15s ease-in',
		background: 'transparent',
		border: 'none',
		fontFamily: theme.fonts.base,
		fontSize: '12px',
		'&$transparent': {
			borderColor: '#666'
		},
		'&::placeholder': {
			color: '#888',
		},
		'@media screen and (min-width: 992px)': {
			fontSize: '13px'
		}
	},
	transparent: {},
	button: {
		alignItems: 'center',
		border: 'none',
		borderRadius: '0 2px 2px 0',
		color: '#7b7b7b',
		display: 'flex',
		cursor: 'pointer',
		'@media screen and (min-width: 992px)': {
		}
	},
	suggestionsContainer: {
		width: '100%',
		backgroundColor: '#fff',
		overflowY: 'auto',
		overflowX: 'hidden',
		minHeight: '1px',
		zIndex: '14',
		maxHeight: '240px',
		'@media screen and (min-width: 992px)': {
			maxHeight: '500px',
		},
		'& a': {
			textDecoration: 'none'
		},
		top: '32px',
		position: 'absolute',
		marginTop: '-1px',
		display: 'none',
		'&$hasSuggestions': {
			minHeight: '0px',
			borderRadius: '0 0 2px 2px',
			marginTop: '-1px',
			borderTop: '1px solid #eee',
			'&$open': {
				// width: 'calc(100% - 5px)',
				display: 'block',
				borderTopColor: 'transparent',
				borderRight: `1px solid ${theme.colors.borderGray}`,
				borderBottom: `1px solid ${theme.colors.borderGray}`,
				borderLeft: `1px solid ${theme.colors.borderGray}`,
				zIndex: '-1',
				boxShadow: '0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2)',
			},
		},
	},
	open: {},
	border: {},
	hasSuggestions: {},
});

export default createUseStyles(styles);
