import React from 'react'
import {createUseStyles} from 'react-jss'

import {useToggleFavoriteUniversity} from '../../graphql/mutations/useToggleFavoriteUniversity'
import heartActive from './assets/heart-active.svg'
import heart from './assets/heart.svg'

const useStyles = createUseStyles({
	favoriteHeart: {
		marginLeft: '30px',
		width: '30px',
		'@media screen and (min-width: 992px)': {
			width: '40px',
		},
		'@media screen and (min-width: 1200px)': {
			width: '42px',
		},
	},
});

const FavoriteHeart = ({university: {favorite, id}}) => {
	const classes = useStyles();
	const toggleFavoriteUniversity = useToggleFavoriteUniversity(favorite, id);

	return (
		<img
			className={classes.favoriteHeart}
			src={favorite ? heartActive : heart}
			alt="Favorite"
			onClick={toggleFavoriteUniversity}
		/>
	);
}

export default FavoriteHeart;
