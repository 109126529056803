import React, {useCallback, useState} from 'react'
import classNames from 'classnames'
import {createUseStyles} from 'react-jss'
import {Link} from 'react-router-dom'

import routesHelper, {bestUniversitiesPath} from '@prepler/core/lib/routesHelper'

import useHandleOutsideClick from '../../utils/useHandleOutsideClick'
// import useCurrentUser from '../../graphql/queries/useCurrentUser'

const useStyles = createUseStyles(theme => ({
	menu: {
		display: 'flex',
		alignItems: 'center',
		color: '#7b7b7b',
		textAlign: 'center',
		width: '34px',
		marginLeft: '-1px',
		fontSize: '26px',
		lineHeight: '33px',
		'@media screen and (min-width: 410px)': {
			fontSize: '33px',
			width: '43px',
		},
		'@media screen and (min-width: 992px)': {
			width: '50px',
			fontSize: '34px',
			lineHeight: '34px',
		},
		'&$transparent': {
			color: '#ffffff94',
		},
	},
	menuButton: {
		width: '34px',
		'@media screen and (min-width: 410px)': {
			width: '40px',
		},
		cursor: 'pointer',
		marginLeft: '-5px',
		// backgroundColor: '#fff2',
	},
	menuButtonOpened: {
		fontSize: '34px',
		lineHeight: '35px',
		marginTop: '3px',
		backgroundColor: '#fff',
		color: theme.colors.primary,
		borderTopLeftRadius: '8px',
		borderTopRightRadius: '8px',
		zIndex: '2',
		borderWidth: '1px 1px 0px 1px',
		borderStyle: 'solid',
		borderColor: '#e9ebee',
	},
	menuOpened: {
		position: 'absolute',
		boxShadow: '0px 0px 8px #00000030',
		zIndex: '1',
		top: '39px',
		left: '-6px',
		fontSize: '14px',
		'@media screen and (min-width: 992px)': {
			top: '42px',
			fontSize: '17px',
		},
		'@media screen and (min-width: 1200px)': {
			top: '47px',
		},
		width: '245px',
		textAlign: 'left',
		backgroundColor: '#fff',
		fontWeight: theme.fontWeight.normal,
		lineHeight: '2em',
		borderWidth: '1px 1px 1px 1px',
		borderStyle: 'solid',
		borderColor: '#e9ebee',
		borderRadius: '0 5px 5px 5px',
		// boxShadow: '0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2)',
	},
	menuItem: {
		padding: '0px',
		borderTop: '1px solid #e9ebee',
		'&:first-child': {
			borderTop: 'none',
		},
		'&:hover': {
			backgroundColor: '#e9ebee',
		},
		'& > a': {
			width: '100%',
			padding: '10px 15px',
			'&:hover': {
				textDecoration: 'none'
			}
		},
	},
	transparent: {},
}));

const LeftDropDownMenu = ({transparent}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen(!open);
	const containerRef = useHandleOutsideClick(useCallback(() => setOpen(false), []));
	// const {legacyProfile, ...currentUser} = useCurrentUser();
	// const debug = (
	// 	<pre className={classes.menuOpened} style={{fontSize: '10px'}}>
	// 		{JSON.stringify(currentUser, null, 2)}
	// 	</pre>
	// );
	const debug = null;

	return (
		<div
			className={classNames(classes.menu, {[classes.transparent]: transparent})}
			ref={containerRef} onClick={toggle}
		>
			<i
				className={classNames(classes.menuButton, {
					[classes.menuButtonOpened]: open,
					'ion-android-close': open,
					'ion-android-menu': !open})}
			/>
			{open ? (
				<div className={classes.menuOpened}>
					<ul>
						<li className={classNames(classes.menuItem)}>
							<Link to={routesHelper.goalsPath()}>
								Degree Planner
							</Link>
						</li>
						<li className={classNames(classes.menuItem)}>
							<Link to={bestUniversitiesPath()}>
								College Match
							</Link>
						</li>
						<li className={classNames(classes.menuItem)}>
							<Link to={routesHelper.gpaCalculatorPath()}>
								College GPA Calculator
							</Link>
						</li>
						<li className={classNames(classes.menuItem)}>
							<Link to={routesHelper.loanCalculatorPath()}>
								Student Loan Calculator
							</Link>
						</li>
						<li className={classNames(classes.menuItem)}>
							<Link to={routesHelper.forStudentsPath()}>
								For Students
							</Link>
						</li>
						<li className={classNames(classes.menuItem)}>
							<Link to={routesHelper.forParentsPath()}>
								For Parents
							</Link>
						</li>
						<li className={classNames(classes.menuItem)}>
							<Link to={routesHelper.partnerPath()}>
								For Colleges
							</Link>
						</li>
						<li className={classNames(classes.menuItem)}>
							<Link to={routesHelper.articlesPath()}>
								Blog
							</Link>
						</li>
					</ul>
				</div>
			) : debug}
		</div>

	);
};

export default LeftDropDownMenu;
