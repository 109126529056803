import React from 'react'
import {withRouter} from 'react-router-dom'

// eslint-disable-next-line react/no-deprecated
class ScrollToTopOnMount extends React.Component {
	componentDidMount() {
		const {history: {action}} = this.props;
		if (action === 'PUSH') {
			try {
				window.scroll({top: 0, left: 0});
			} catch (error) {
				window.scrollTo(0, 0);
			}
		}
	}

	UNSAFE_componentWillReceiveProps({history: {action}, location: {key}}) {
		if (action === 'PUSH' && key !== this.props.location.key) {
			try {
				window.scroll({top: 0, left: 0});
			} catch (error) {
				window.scrollTo(0, 0);
			}
		}
	}

	render() {
		return null
	}
}

export default withRouter(ScrollToTopOnMount);
