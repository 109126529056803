import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles(theme => ({
	footer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 auto',
		padding: '5px 0 22px',
		color: '#506690',
		width: '100%',
		// '@media screen and (min-width: 992px)': {
		// 	maxWidth: '1400px',
		// },
		'@media screen and (min-width: 992px)': {
			width: '920px',
			margin: '0px',
			// flex: '1 0 auto',
		},
		'@media screen and (min-width: 1200px)': {
			width: '1100px',
		},
	},
	logo: {
		width: '100%',
		marginBottom: '20px',
		'@media screen and (min-width: 640px)': {
			width: '31% !important',
			marginBottom: '0px',
			order: '1',
		},
		'@media screen and (min-width: 768px)': {
			width: '28% !important',
		},
		paddingTop: '20px',
		'& > img': {
			display: 'block',
			width: '140px',
			margin: '0 auto',
			'@media screen and (min-width: 640px)': {
				margin: '0',
			},
		},
	},
	talk: {
		width: '100%',
		// marginBottom: '20px',
		fontSize: '16px',
		marginTop: '20px',
		'@media screen and (min-width: 640px)': {
			width: '69% !important',
			order: '2',
			marginTop: '30px',
		},
		'@media screen and (min-width: 768px)': {
			fontSize: '20px',
		},
		flexGrow: '1',
		// margin: '0px 0 20px',
		paddingBottom: '20px',
		// borderTop: '1px solid #96a6c5',
		borderBottom: '1px solid #96a6c5',
		textAlign: 'center',
		'& a': {
			display: 'inline',
			fontSize: 'inherit !important',
			color: '#fff !important',
		}
	},
	columns: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	links: {
		// minWidth: '250px',
		flexGrow: '0',
		flexShrink: '0',
		color: '#96a6c5',
		'& a': {
			// color: '#96a6c5',
			color: '#96a6c5',
			'&:focus': {
				textDecoration: 'none',
			},
			fontSize: '10px',
			lineHeight: '20px',
			'@media screen and (min-width: 992px)': {
				fontSize: '13px',
				lineHeight: '26px',
			},
		},
		'& > img': {
			display: 'block',
			margin: '5px 0 20px',
		},
		// width: '100%',
		'@media screen and (min-width: 480px)': {
			width: '50%',
		},
		'@media screen and (min-width: 640px)': {
			paddingTop: '20px',
			width: '28%',
		},
		'@media screen and (min-width: 768px)': {
			width: '24%',
		},
		'&:nth-child(1)': {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap',
			'@media screen and (min-width: 480px)': {
				width: '28% !important',
			},
			// paddingRight: '15px',
			paddingTop: '0px',
			'& > a': {
				color: '#fff',
				fontSize: '13px',
				lineHeight: '29px',
				whiteSpace: 'nowrap',
				width: '50%',
				'@media screen and (min-width: 480px)': {
					fontSize: '16px',
					width: '100%',
				},
			},
		},
		// '&:nth-child(2)': {
		// 	display: 'block',
		// },
		'&:nth-child(3)': {
			display: 'block',
			'@media screen and (min-width: 480px)': {
				display: 'none',
			},
			'@media screen and (min-width: 640px)': {
				display: 'block',
			},
		},
		'&:nth-child(4)': {
			display: 'none',
			'@media screen and (min-width: 768px)': {
				display: 'block',
			},
		},
	},
	logos: {
		display: 'flex',
		flexWrap: 'wrap',
		flexShrink: '0',
		margin: '0 auto',
		'@media screen and (min-width: 480px)': {
			width: '160px',
			margin: '0',
		},
		color: theme.colors.gray[2],
		// flex: '1 0 50%',
		// justifyContent: 'center',
		padding: '15px 0 0',
		// fontSize: '30px',
		// '@media screen and (min-width: 992px)': {
		// 	margin: '0px'
		// },
		'& svg': {
			width: '30px',
			height: '30px',
			margin: '0 15px 20px 0',
		},
		'& a:nth-child(n+3) svg': {
			margin: '0 15px 15px 0'
		}
	},
	fineText: {
		borderTop: '1px solid #96a6c5',
		marginTop: '5px',
		// color: theme.colors.gray[2],
		padding: '10px 0px 10px',
		width: '100%',
		'& p': {
			margin: '0px',
			padding: '3px 0',
			fontSize: '10px',
			lineHeight: '10px',
		},
	},
	copy: {
		// flex: '1 0 50%',
		margin: '0px 10px',
		maxWidth: '890px',
		'@media screen and (min-width: 992px)': {
			maxWidth: '905px',
			margin: '0px',
		},
		'@media screen and (min-width: 1200px)': {
			maxWidth: '1080px',
		},
		'& p': {
			textAlign: 'center',
			margin: '0px',
			padding: '3px 0',
			fontSize: '12px',
			lineHeight: '18px',
			color: '#96a6c5',
		},
	},
}));

export default useStyles;
