import React from 'react'
import {createUseStyles} from 'react-jss'
import {originalLargeHeight} from '../header/style'

const useStyles = createUseStyles(theme => ({
	container: {
		display: 'flex',
		background: '#0356b2',
		width: '100%',
		color: '#f5cf12',
		alignItems: 'center',
		flexDirection: 'column',
		fontSize: '18px',
		padding: '6px',
		textTransform: 'uppercase',
		height: '36px',
		zIndex: 100,
		'@media screen and (min-width: 992px)': {
			position: 'fixed',
			marginTop: `${originalLargeHeight + 1}px`,
			marginBottom: '-56px'
		},
		'@media screen and (min-width: 1200px)': {
			position: 'fixed',
			marginTop: `${originalLargeHeight + 11}px`,
			marginBottom: '-56px'
		},
	},
	button: {
		background: '#f5cf12',
		color: 'black',
		padding: '0px 26px',
		borderRadius: '10px',
		marginLeft: '20px'
	}
}));

export const url = 'https://www.paypal.com/donate/?hosted_button_id=F9W2KMJ7VBHNS'

const UkraineBanner = () => {
	const classes = useStyles();
	return <div className={classes.container}>
		<div>
			Stand with Ukraine
			<a href={url} target="_blank" className={`btn ${classes.button}`}>Donate</a>
		</div>
	</div>;
}

export default UkraineBanner;
