import React from 'react'
import Helmet from '../common/helmet'
import DiscoverHome from './discoverHome'
import Sections from './sections'
import CollegesOnPrepler from '../collections/collegesOnPrepler'

const Home = () => {
	return (
		<>
			<Helmet
				titleTemplate="%s"
				title="Prepler | Your college degree planner"
			/>
			<DiscoverHome/>
			<Sections/>
			<CollegesOnPrepler/>
		</>
	);
};

export default Home;
