import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as Loan } from '../assets/menu/loan.svg'

export const active = {
	'& .c1': {
		fill: '#ff871c'
	},
	'& .c2': {
		fill: '#cb3820'
	},
	'& .c6': {
		fill: '#68c949'
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .c1': {
			fill: '#9f9f9f'
		},
		'& .c2': {
			fill: '#616161'
		},
		'& .c2, & .c4, & .c5': {
			stroke: '#707070',
			strokeWidth: '2px'
		},
		'& .c2, & .c4, & .c5, & .c8, & .c9': {
			strokeMiterlimit: '10'
		},
		'& .c3': {
			fill: '#fff'
		},
		'& .c4, & .c5, & .c8, & .c9': {
			fill: 'none'
		},
		'& .c4, & .c9': {
			strokeLinecap: 'round'
		},
		'& .c6': {
			fill: '#9e9e9e'
		},
		'& .c7': {
			fill: '#707070'
		},
		'& .c8, & .c9': {
			stroke: '#fff'
		},
		'& .c8': {
			strokeWidth: '0.5px'
		},
		'& $active, &:hover': {
			extend: [active]
		}
	},
	active: {},
}));

const IconLoan = ({active, className, ...props}) => {
	const classes = useStyles();
	return <Loan {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconLoan.propTypes = {
	active: PropTypes.bool,
};

IconLoan.defaultProps = {
	active: false
};

export default IconLoan
