import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useHistory, useParams} from 'react-router-dom'
// import {Link} from 'react-router-dom'
import classNames from 'classnames'
import IsolatedScroll from 'react-isolated-scroll'

import routesHelper from '@prepler/core/lib/routesHelper'

import useDebounce from '../../utils/useDebounce'
import Suggestions from './suggestions'
import useHandleOutsideClick from '../../utils/useHandleOutsideClick'
import useStyles from './styles'

const searchButtonPath = (query, hasSchools, hasLocations, hasMajors, hasCareers) => {
	if (query === '') return null;
	if (hasSchools) return routesHelper.searchQueryPath({section: 'name', query});
	if (hasLocations) return routesHelper.searchQueryPath({section: 'location', query});
	if (hasMajors) return routesHelper.searchQueryPath({section: 'major', query});
	if (hasCareers) return routesHelper.searchQueryPath({section: 'career', query});
	return routesHelper.searchQueryPath({section: 'name', query});
};

const keyCodeToName = (keyCode) => {
	return {
		'13': 'enter',
		'38': 'up',
		'40': 'down',
		'27': 'esc',
	}[keyCode];
};

const SEARCH_FORM = gql`
	query SearchForm {
		searchFormQuery @client
		searchFormTitle @client
	}
`;

export const useQueryTitle = () => {
	const {client, data} = useQuery(SEARCH_FORM);

	const setQuery = (searchFormQuery) => client.writeData({data: {searchFormQuery}});
	const setTitle = (searchFormTitle) => client.writeData({data: {searchFormTitle}});
	const {searchFormQuery, searchFormTitle} = data || {};

	return [searchFormQuery, setQuery, searchFormTitle, setTitle];
}

const SEARCH_ALL = gql`
	query SearchAll($query: String!) {
		searchAll(query: $query)
	}
`;

const initialSuggestions = {schools: [], locations: [], majors: [], careers: []};
const useSuggestions = (query) => {
	const [suggestions, setSuggestions] = useState({...initialSuggestions});

	useEffect(() => {
		if (query === '') {
			setSuggestions({...initialSuggestions});
		}
	}, [query]);

	const queryDebounced = useDebounce(query, 350);
	const {loading, error, data} = useQuery(SEARCH_ALL, {
		variables: {query: queryDebounced}, skip: queryDebounced === ''
	});

	useEffect(() => {
		if (!loading && !error && data) {
			setSuggestions(data.searchAll);
		}
	}, [loading, error, data]);

	return suggestions;
}

const useSuggestionsQueryTitle = () => {
	const [query, setQuery, title, setTitle] = useQueryTitle();

	const {query: matchQuery} = useParams();
	useEffect(() => {
		setQuery(matchQuery);
	}, []);

	const [localQuery, setLocalQuery] = useState(matchQuery || query);
	useEffect(() => {
		setQuery(localQuery);
	}, [localQuery]);

	const suggestions = useSuggestions(query || '');

	return [suggestions, localQuery, setLocalQuery, title, setTitle];
}

const SearchFormUnified = ({transparent, minimized, minimize, maximize}) => {
	const classes = useStyles();

	const containerRef = useHandleOutsideClick(useCallback(minimize, []));
	const inputRef = useRef({});
	const listRef = useRef({});

	const [suggestions, query, setQuery, title, setTitle] = useSuggestionsQueryTitle();

	const setTitleAndMinimize = (title) => {
		setTitle(title);
		minimize();
	};

	const inputValue = (!minimized || title === '') ? query : title;
	const hasSchools = suggestions.schools.length > 0;
	const hasLocations = suggestions.locations.length > 0;
	const hasMajors = suggestions.majors.length > 0;
	const hasCareers = suggestions.careers.length > 0;
	const hasSuggestions = hasSchools || hasLocations || hasMajors || hasCareers;
	const activeSections = (hasSchools ? 1 : 0) + (hasLocations ? 1 : 0) + (hasMajors ? 1 : 0) + (hasCareers ? 1 : 0);
	const perSection = activeSections > 2 ? 3 : activeSections > 1 ? 4 : 5;

	const {push} = useHistory();
	const onKeyDown = ({keyCode}) => {
		const keyName = keyCodeToName(keyCode);
		if (keyName === 'esc') {
			minimize();
		} else if (keyName === 'enter' && query !== '') {
			minimize();
			push(searchButtonPath(query, hasSchools, hasLocations, hasMajors, hasCareers));
		}
	};

	const searchOnClick = () => {
		if (minimized) {
			maximize();
			inputRef.current.focus();
		} else {
			minimize();
		}
	};

	return (
		<div
			className={classNames(classes.search, {[classes.minimized]: minimized, [classes.transparent]: transparent})} ref={containerRef}
		>
			<div className={classNames(classes.form, {[classes.hasSuggestions]: hasSuggestions})}>
				<input
					autoFocus={!minimized}
					placeholder={minimized ? 'Find your college match' : 'Find your college match'}
					className={classNames(classes.input, {[classes.transparent]: transparent})}
					value={inputValue}
					title={inputValue}
					ref={inputRef}
					onChange={({target: {value}}) => setQuery(value)}
					onFocus={maximize}
					onKeyDown={onKeyDown}
				/>
				<span className={classes.button} onClick={searchOnClick}>
					<i className={minimized ? 'ion-ios-search-strong' : 'ion-ios-close-empty'}/>
				</span>
			</div>
			<IsolatedScroll
				className={classNames(classes.suggestionsContainer, {
					// [classes.open]: true,
					[classes.open]: query !== '' && !minimized,
					[classes.hasSuggestions]: hasSuggestions,
				})}
			>
				<Suggestions
					query={query} suggestions={suggestions}
					setTitleAndMinimize={setTitleAndMinimize} perSection={perSection} listRef={listRef}
				/>
			</IsolatedScroll>
		</div>
	);
};

export default SearchFormUnified;
