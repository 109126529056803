import React from 'react'
import gql from 'graphql-tag'
import {Link, useHistory} from 'react-router-dom'
import classNames from 'classnames'

import routesHelper from '@prepler/core/lib/routesHelper'

import {useToggleFavoriteUniversity} from '../../../graphql/mutations/useToggleFavoriteUniversity'
import HeartIcon from '../../icons/heartIcon'
import AdmissionChanceSlider from '../../universities/tile/admissionChanceSlider'
import theme from '../../../theme'
import useStyles from './style'

const Address = ({classes, university: {city, stateAbbr}}) => {
	const address = [city, stateAbbr].filter(item => item).join(', ');
	return (
		<div className={classes.textEllipsis} title={address}>
			{address}
		</div>
	)
};

const FavoriteButton = ({classes, university: {id, favorite}}) => {
	const toggleFavoriteUniversity = useToggleFavoriteUniversity(favorite, id);

	const onClickFavorite = (e) => {
		e.stopPropagation();
		toggleFavoriteUniversity();
		return false;
	};

	return (
		<button className={classes.btnHeart} onClick={onClickFavorite}>
			<HeartIcon
				size="32px"
				aria-label="Add to my schools"
				fillColor={favorite ? theme.colors.red[1] : theme.colors.gray[4]}
			/>
		</button>
	);
};

const AdditionalBlock = ({classes, university, university: {
	levelShort = '',
	totalCost = '',
	totalCostOrStickerPrice = '',
	stickerPrice = ''
}}) => {
	return (
		<>
			<div className={classes.additional}>
				<Address classes={classes} university={university}/>
				{totalCostOrStickerPrice !== 'N/A' && stickerPrice !== totalCost && (
					<div
						className={classNames(classes.textEllipsis, classes.rtl)}
						title={`Sticker Price: $${stickerPrice.toLocaleString('en')}/yr`}
					>
						Sticker Price:&nbsp;${stickerPrice.toLocaleString('en')}<small>/yr</small>
					</div>
				)}
			</div>
			<div className={classes.additional}>
				<div className={classes.textEllipsis} title={levelShort}>
					{levelShort}
				</div>
				{totalCostOrStickerPrice === 'N/A' ? (
					<div className={classNames(classes.textEllipsis, classes.rtl)} title="Price: N/A">
						Price:&nbsp;<strong>N/A</strong>
					</div>
				) : (
					<div
						className={classNames(classes.textEllipsis, classes.rtl)}
						title={`${totalCost !== stickerPrice ? 'Your ' : ''}Price $${totalCost.toLocaleString('en')}/yr`}
					>
						{totalCost !== stickerPrice && 'Your '}
						Price:&nbsp;<strong>${totalCost.toLocaleString('en')}</strong><small>/yr</small>
					</div>
				)}
			</div>
		</>
	)
};

const PhotoStyle = ({university: {id, photo}}) => (
	<style>
		{`
			.university-background-${id} {
				background-color: #eee; background-image: url('${photo.gallery}')
			}
			@media all and (-webkit-min-device-pixel-ratio : 1.5) and (min-width: 992px),
							all and (-o-min-device-pixel-ratio: 3/2) and (min-width: 992px),
							all and (min--moz-device-pixel-ratio: 1.5) and (min-width: 992px),
							all and (min-device-pixel-ratio: 1.5) and (min-width: 992px) {
				.university-background-${id} {
					background-image: url('${photo.gallery2x}')
				}
			}
		`}
	</style>
);

const SchoolItemSquare = ({university, university: {id, slug, admissionChance, name, rating}}) => {
	const classes = useStyles();
	const {push} = useHistory();

	return (
		<div className={classes.schoolItemPresentation}>
			<PhotoStyle university={university}/>
			<div
				className={classNames(classes.infoTop, `university-background-${id}`)}
				onClick={() => push(routesHelper.universityPath({id: slug || id}))}
			>
				<FavoriteButton classes={classes} university={university}/>
				<div className={classes.info}>
					{rating && (
						<span>
							#{rating} Best Colleges in America
						</span>
					)}
					<Link to={routesHelper.universityPath({id: slug || id})} className={classes.schoolItemLink}>
						<h3>{name}</h3>
					</Link>
					<AdditionalBlock classes={classes} university={university}/>
				</div>
			</div>
			<div className={classes.infoBottom}>
				<AdmissionChanceSlider admissionChance={admissionChance} />
			</div>
		</div>
	);
}

SchoolItemSquare.fragments = {
	university: gql`
		fragment SchoolItemSquareUniversity on University {
			id
			slug
			admissionChance
			city
			favorite
			levelShort
			name
			photo {
				gallery
				gallery2x
			}
			rating
			stateAbbr
			totalCost
			totalCostOrStickerPrice
			stickerPrice
		}
	`,
}

export default SchoolItemSquare;
