import React from 'react'
import {Route} from 'react-router-dom'
import injectSheet from 'react-jss'
import App from '@prepler/core/lib/app'
import UniversityPage from '../universities/universityPage'
// import University from '../universities/universityOverview'
import Header from '../header'
import Footer from '../footer'
import Modals from './modals'
import Notifications from './notifications'
import UkraineBanner from './ukraineBanner'
// import {preloadBase, preloadLearning, preloadUniversities} from '../../preload'
import styles from './styles'

class UniversityLayout extends React.Component {
	// componentDidMount() {
	// 	setTimeout(preloadBase, 7000);
	// 	setTimeout(preloadUniversities, 8000);
	// 	setTimeout(preloadLearning, 9000);
	// }
	// constructor(props) {
	// 	super(props);
	//
	// 	this.setPageYOffsetBound = this.setPageYOffset.bind(this);
	// 	this.setBodySizeBound = this.setBodySize.bind(this);
	//
	// 	this.state = {
	// 		scrolledDown: false,
	// 		galleryRows: 1
	// 	}
	// }
	//
	// componentDidMount() {
	// 	window.addEventListener('scroll', this.setPageYOffsetBound, {passive: true});
	// 	this.setPageYOffset();
	//
	// 	window.addEventListener('resize', this.setBodySizeBound);
	// 	this.setBodySize()
	// }
	//
	// componentWillUnmount() {
	// 	window.removeEventListener('scroll', this.setPageYOffsetBound);
	// 	window.removeEventListener('resize', this.setBodySizeBound);
	// }
	//
	// setPageYOffset() {
	// 	const scrolledDown = window.pageYOffset > this.state.transitionYOffset;
	// 	if (this.state.scrolledDown !== scrolledDown) {
	// 		this.setState({scrolledDown});
	// 	}
	// }
	//
	// setBodySize() {
	// 	const galleryRows = window.innerWidth > 991 ? 2 : 1;
	// 	const transitionYOffset = window.innerHeight > 902 ? 540 : 315;
	//
	// 	if (this.state.galleryRows !== galleryRows || this.state.transitionYOffset !== transitionYOffset) {
	// 		this.setState({galleryRows, transitionYOffset});
	// 	}
	// }

	render() {
		const {classes, component, routePath, ...rest} = this.props;
		// const {scrolledDown, galleryRows} = this.state;

		return <Route {...rest} render={matchProps => (
			(App.history = matchProps.history) &&
			<div className={classes.layout}>
				<Notifications />
				<header className={classes.header}>
					{/* <Header showMenu={!scrolledDown} smallLogo={scrolledDown}/> */}
					<Header/>
				</header>
				<UkraineBanner/>

				<UniversityPage
					component={component}
					defaultMainClassName={classes.main}
					mainClassName={classes.universityMain}
					// scrolledDown={scrolledDown}
					// galleryRows={galleryRows}
					routePath={routePath}
					{...matchProps}
				/>
				{/*<University defaultMainClassName={classes.main} mainClassName={classes.universityMain} scrolledDown={scrolledDown} galleryRows={galleryRows} {...matchProps}/>*/}

				<footer className={classes.footer}>
					<Footer/>
				</footer>

				<Modals history={matchProps.history}/>
			</div>
		)} />;
	}
}

export default injectSheet(styles)(UniversityLayout)
