import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {Tooltip} from 'react-tippy'

import routesHelper from '@prepler/core/lib/routesHelper'

import LinkModal from '../common/linkModal'
import {useStyles} from './userStyle'

const UnauthorizedUser = ({className, transparent, implicit, user}) => {
	const classes = useStyles();

	return (
		<div className={classNames(className, classes.container, {[classes.transparent]: transparent})}>
			{implicit && <div className={classes.favorites}>
				<Tooltip
					title="My Schools"
					arrow={true}
					delay={500}
					theme="prepler"
				>
					<Link to={routesHelper.accountPath({tab: 'my_schools'})}>
						<i className="ion-ios-heart-outline"/>
						{user.universitiesCount > 0 && <span>{user.universitiesCount}</span>}
					</Link>
				</Tooltip>
			</div>}
			{implicit ? (
				<Link className={classNames(classes.userNavigationItem, classes.borderRight)} to={routesHelper.accountPath()}>
					Edit
				</Link>
			) : (
				<LinkModal className={classNames(classes.userNavigationItem, classes.borderRight)} modal="SignIn">
					Sign in
				</LinkModal>
			)}
			<LinkModal className={classes.userNavigationItem} modal="SignUp">
				Join
			</LinkModal>
		</div>
	);
};

UnauthorizedUser.propTypes = {
	className: PropTypes.string,
};

export default UnauthorizedUser;
