import React from 'react'
import {__RouterContext as RouterContext} from 'react-router'
import App from '@prepler/core/lib/app'

const WithStatus = (ComposedComponent, status) => {
	return status === 200 || window._static || App.type !== 'node' ? ComposedComponent : (props) => {
		return <RouterContext.Consumer>
			{({staticContext: context}) => {
				context && (context.status = status);
				return <ComposedComponent {...props}/>;
			}}
		</RouterContext.Consumer>;
	};
};

export default WithStatus;


