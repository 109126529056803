import {smallHeight, largeHeight} from '../header/style'
import {createUseStyles} from 'react-jss'

const styles = theme => ({
	container: {
		display: 'flex',
		color: '#000',
		'&$transparent': {
			'& $info a': {
				color: '#fff',
				'& i': {
					color: '#fff'
				}
			},
			'& $profileCompletion': {
				color: '#fff'
			},
			'& $favorites': {
				color: '#fff',
				'& a i:before': {
					color: '#fff'
				},
				'& $shareWithMe svg': {
					color: '#fff'
				},
				'& span': {
					border: '2px solid #123456'
				},
			},
			'& $userNavigationItem': {
				color: '#fff'
			}
		}
	},
	info: {
		// margin: '0px 13px 0px 0px',
		'@media screen and (min-width: 410px)': {
			// margin: '0px 0px 0px 25px',
		},
		'& a': {
			color: '#555',
			fontSize: '12px',
			fontWeight: theme.fontWeight.normal,
			'@media screen and (min-width: 992px)': {
				fontSize: '16px'
			},
		},
		'& a:hover': {
			textDecoration: 'none'
		}
	},
	image: {
		background: 'transparent no-repeat center center',
		backgroundSize: 'cover',
		backgroundColor: '#c6cad0',
		borderRadius: '50%',
		height: '22px',
		width: '22px',
		'@media screen and (min-width: 410px)': {
			height: '30px',
			width: '30px',
		},
		flexShrink: '0',
		'&$large': {
			display: 'none',
			'@media screen and (min-width: 410px)': {
				display: 'block',
				margin: '0 8px 0 0',
				height: '90px',
				width: '90px'
			}
		},
		'@media screen and (min-width: 992px)': {
			height: '35px',
			width: '35px',
		}
	},
	defaultImage: {
		backgroundColor: theme.colors.primary,
		color: '#fff',
		textAlign: 'center',
		lineHeight: '22px',
		fontSize: '12px',
		'@media screen and (min-width: 410px)': {
			lineHeight: '30px',
		},
		'@media screen and (min-width: 992px)': {
			lineHeight: '36px',
			fontSize: '14px',
		},
		fontWeight: theme.fontWeight.normal,
		'&$large': {
			lineHeight: '90px',
			fontSize: '40px',
		},
		'&$transparent:not($large)': {
			backgroundColor: '#ffffff94',
			color: theme.colors.mediumBlue,
		},
	},
	large: {},
	infoLarge: {
		color: '#fff',
		flex: '1 0 auto',
		wordBreak: 'break-all',
		maxWidth: '200px',
		// alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
		fontSize: '12px',
		'@media screen and (min-width: 992px)': {
			color: '#ccc',
			fontSize: '14px',
		},
		'& a': {
			color: '#000',
			fontWeight: theme.fontWeight.bold,
		},
		'& a:hover': {
			textDecoration: 'none'
		}
	},
	infoName: {
		display: 'flex',
		alignItems: 'center',
		// justifyContent: 'center',
		fontSize: '20px',
		lineHeight: '1.2em',
		color: '#555',
		flex: '1 0 auto',
		wordBreak: 'break-all',
		'@media screen and (min-width: 410px)': {
			fontSize: '24px'
		},
	},
	infoCompletion: {
		flex: '0 0 auto',
		color: '#555'
	},
	profileCompletion: {
		color: theme.colors.black,
		fontFamily: theme.fonts.base,
		fontSize: '10px',
		lineHeight: '14px',
		display: 'none',
		'@media screen and (min-width: 992px)': {
			display: 'block',
			fontSize: '12px',
			lineHeight: '16px'
		}
	},
	favorites: {
		position: 'relative',
		color: '#ccc',
		display: 'flex',
		flexDirection: 'column',
		margin: '0px 10px 0px 1px',
		fontSize: '24px',
		'@media screen and (min-width: 410px)': {
			margin: '0px 15px 0px 5px',
			fontSize: '30px',
		},
		'@media screen and (min-width: 992px)': {
			margin: '0px 30px 0px 1px',
			fontSize: '30px',
		},
		'& i:before': {
			color: '#7b7b7b',
			fontWeight: 'bolder'
		},
		'& i.ion-compass:before': {
			fontWeight: 'lighter',
		},
		'& span': {
			top: '-6px',
			left: '14px',
			minWidth: '16px',
			height: '16px',
			fontSize: '7px',
			padding: '0px 3px',
			lineHeight: '13px',
			'@media screen and (min-width: 410px)': {
				left: '17px',
				minWidth: '19px',
				height: '19px',
				fontSize: '10px',
				padding: '0px 5px',
				lineHeight: '15px',
			},
			'@media screen and (min-width: 992px)': {
				left: '20px',
				minWidth: '22px',
				height: '21px',
				fontSize: '10px',
				padding: '1px 5px'
			},
			color: '#fff',
			backgroundColor: theme.colors.primary,
			border: '2px solid #fff',
			borderRadius: '11px',
			position: 'absolute',
			textAlign: 'center',
			verticalAlign: 'middle',
		}
	},
	badge: {
		marginLeft: '7px',
		padding: '0 7px',
		color: theme.colors.white,
		backgroundColor: theme.colors.primary,
		borderRadius: '11px'
	},
	logout: {
		alignItems: 'center',
		color: '#818181 !important',
		display: 'flex',
		fontSize: '16px',
		justifyContent: 'center',
		height: '20px',
		width: '20px'
	},
	open: {},
	arrow: {
		position: 'absolute',
		display: 'block',
		width: '1.6rem',
		height: '.8rem',
		margin: '0 .3rem',
		top: '-9px',
		right: '7px',
		'@media screen and (min-width: 992px)': {
			right: '6px',
		},
		'&:before, &:after': {
			position: 'absolute',
			display: 'block',
			content: '""',
			borderColor: 'transparent',
			borderStyle: 'solid',
			borderWidth: '0 .8rem .8rem .8rem'
		},
		'&:before': {
			top: 0,
			borderBottomColor: '#e9ebee',
		},
		'&:after': {
			top: '1px',
			borderBottomColor: '#fff'
		}
	},
	menu: {
		display: 'none',
		'&$open': {
			display: 'block'
		},
		position: 'absolute',
		zIndex: 20,
		top: `${smallHeight + 7}px`,
		right: '-4px',
		'@media screen and (min-width: 992px)': {
			top: `${largeHeight + 5}px`,
			right: '0px',
		},
		'@media screen and (min-width: 1200px)': {
			top: `${largeHeight + 10}px`,
		},
		width: '240px',
		fontSize: '12px',
		'@media screen and (min-width: 410px)': {
			width: '320px',
			fontSize: '14px'
		},
		backgroundColor: '#fff',
		border: '1px solid #e9ebee',
		borderRadius: '5px 5px 5px 5px',
		// boxShadow: '0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2)',
		'& ul li': {
			display: 'flex',
			padding: '10px',
			'& $info': {
				fontSize: '24px',
				color: '#555',
				flex: '1 0 auto',
				wordBreak: 'break-all',
				alignItems: 'center',
				justifyContent: 'center'
			},
			'&$hover': {
				padding: '0px'
			},
			'&$hover:hover': {
				backgroundColor: '#e9ebee'
			}
		},
		'& ul li$hover a': {
			padding: '10px',
			'&:hover': {
				textDecoration: 'none'
			}
		}
	},
	hover: {
	},
	separator: {
		borderTop: '1px solid #e9ebee'
	},
	userNavigationItem: {
		position: 'relative',
		alignItems: 'center',
		display: 'flex',
		fontFamily: theme.fonts.base,
		fontSize: '14px',
		fontWeight: theme.fontWeight.normal,
		color: '#555',
		justifyContent: 'center',
		height: '20px',
		padding: '0px 0px 0px 7px',
		whiteSpace: 'nowrap',
		'@media screen and (min-width: 410px)': {
			padding: '0px 0px 0px 15px',
		},
		'@media screen and (min-width: 992px)': {
			// fontSize: '16px',
			height: '25px',
			padding: '0px 0px 0px 25px',
		},
	},
	borderRight: {
		// borderRight: '1px solid #fff',
		paddingLeft: '1px',
		// '@media screen and (min-width: 992px)': {
		// 	marginLeft: '10px',
		// },
	},
	transparent: {},
	shareWithMe: {
		marginBottom: '-2px',
		'& svg': {
			width: '22px',
			color: '#7b7b7b',
			'@media screen and (min-width: 410px)': {
				width: '28px',
			},
		}
	}
});

export const useStyles = createUseStyles(styles);

export default styles
