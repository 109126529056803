import React from 'react'
import {Link} from 'react-router-dom'
import withStyles from './admissionChanceSliderStyles'
import routesHelper from '@prepler/core/lib/routesHelper'

class AdmissionChanceSlider extends React.Component {
	renderAddmissionChance(admissionChance, classes) {
		return <div className={classes.container}>
			<Link className={classes.link} to={routesHelper.accountPath()}>
				<h5 className={classes.h5}>
					<span className={classes.your}>Your </span>
					Admission Chance
				</h5>
				<div className={classes.captions}>
					<div className={classes.number}>0%</div>
					<div className={classes.slider}>
						<div className={classes.tooltip} style={{left: `${admissionChance}%`}}>
							{admissionChance}<small>%</small>
						</div>
						<div className={classes.solidGradient} />
						<div
							className={classes.inactiveGradient}
							style={{marginLeft: `${admissionChance}%`, width: `${100-admissionChance}%`}}
						/>
					</div>
					<div className={classes.number}>100%</div>
				</div>
			</Link>
		</div>;
	}

	renderNoAddmissionChance(classes) {
		return <div className={classes.linkGet}>
			<Link to={routesHelper.accountPath()}>
				Get&nbsp;Your Admission&nbsp;Chance
			</Link>
		</div>;
	}


	render() {
		const {admissionChance, classes} = this.props;
		if (admissionChance) {
			return this.renderAddmissionChance(admissionChance > 99 ? 99 : admissionChance, classes);
		} else {
			return this.renderNoAddmissionChance(classes);
		}
	}
}

export default withStyles(AdmissionChanceSlider);
