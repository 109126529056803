import React from 'react'
import {withRouter} from 'react-router-dom'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import Header from '../../header'
import Footer from '../../footer'
import Modals from '../modals'
import Notifications from '../notifications'
// import {preloadBase, preloadLearning, preloadUniversities} from '../../../preload'
import Helmet from '../../common/helmet'
import styles from './styles'

class HomeLayout extends React.Component {
	constructor(props) {
		super(props);

		this.setPageYOffsetBound = this.setPageYOffset.bind(this);

		this.state = {
			scrolledUp: true,
			open: false
		};
	}

	componentDidMount() {
		// setTimeout(preloadBase, 7000);
		// setTimeout(preloadUniversities, 8000);
		// setTimeout(preloadLearning, 9000);

		this.props.transitionYOffset && window.addEventListener('scroll', this.setPageYOffsetBound, {passive: true});
		this.setPageYOffset();
	}

	componentWillUnmount() {
		this.props.transitionYOffset && window.removeEventListener('scroll', this.setPageYOffsetBound);
	}

	setPageYOffset() {
		if (this.props.transitionYOffset) {
			const scrolledUp = window.pageYOffset < this.props.transitionYOffset;
			if (this.state.scrolledUp !== scrolledUp) {
				this.setState({scrolledUp});
			}
		}
	}

	render() {
		const {classes, history, component: Component, canonicalUrl} = this.props;
		const {scrolledUp} = this.state;

		return (
			<div className={classes.layout}>
				<Helmet
					titleTemplate="%s"
					title="Prepler – Create Your Degree Plan | Graduate Sooner"
				/>
				{canonicalUrl && <Helmet canonical={canonicalUrl()}/>}
				<Notifications />

				<header className={classNames(classes.header, {[classes.transparent]: scrolledUp})}>
					<Header showMenu={true} home={true} transparent={scrolledUp}/>
				</header>

				<main className={classes.main}>
					<Component/>
				</main>

				<footer className={classes.footer}>
					<Footer/>
				</footer>

				<Modals history={history}/>
			</div>
		)
	}
}

HomeLayout.defaultProps = {
	transitionYOffset: 70
};

export default withRouter(injectSheet(styles)(HomeLayout))
