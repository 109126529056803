import loadable from '@loadable/component'

export const Error = loadable(() => import(/* webpackChunkName: 'error' */'../modal/error'));
export const SignInUp = loadable(() => import(/* webpackChunkName: 'sign_in_up' */'../modal/signInUp'));
export const ConfirmationCreate = loadable(() => import(/* webpackChunkName: 'confirmation_create' */'../confirmation/confirmationCreate'));
export const ConfirmationSent = loadable(() => import(/* webpackChunkName: 'confirmation_sent' */'../confirmation/confirmationSent'));
export const Password = loadable(() => import(/* webpackChunkName: 'password' */'../password/password'));
export const PasswordSent = loadable(() => import(/* webpackChunkName: 'password_sent' */'../password/passwordSent'));
export const PasswordEdit = loadable(() => import(/* webpackChunkName: 'password_edit' */'../password/passwordEdit'));
export const Social = loadable(() => import(/* webpackChunkName: 'social' */'../social'));
export const HowItWorks = loadable(() => import(/* webpackChunkName: 'how_it_works' */'../home/howItWorks'));
export const BecomePartner = loadable(() => import(/* webpackChunkName: 'become_partner' */'../home/becomePartner'));
// Admin Namespace
export const CollectionNew = loadable(() => import(/* webpackChunkName: 'collection_new' */'../admin/collections/new'));
export const CollectionEdit = loadable(() => import(/* webpackChunkName: 'collection_edit' */'../admin/collections/edit'));
export const PolicyTermNew = loadable(() => import(/* webpackChunkName: 'policy_term_new' */'../admin/policyTerms/new'));
export const PolicyTermEdit = loadable(() => import(/* webpackChunkName: 'policy_term_edit' */'../admin/policyTerms/edit'));
export const UserEdit = loadable(() => import(/* webpackChunkName: 'user_edit' */'../admin/users/edit'));
export const UserNew = loadable(() => import(/* webpackChunkName: 'user_new' */'../admin/users/new'));
export const UserProfile = loadable(() => import(/* webpackChunkName: 'user_profile' */'../admin/users/profile'));
export const TariffModelNew = loadable(() => import(/* webpackChunkName: 'tariff_model_new' */'../admin/tariffModels/new'));
export const TariffModelEdit = loadable(() => import(/* webpackChunkName: 'tariff_model_edit' */'../admin/tariffModels/edit'));
export const CourseNew = loadable(() => import(/* webpackChunkName: 'course_new' */'../admin/courses/new'));
export const CourseEdit = loadable(() => import(/* webpackChunkName: 'course_edit' */'../admin/courses/edit'));
export const CalculationDetails = loadable(() => import(/* webpackChunkName: 'calculation_details' */'../admin/calculations/details'));
export const UniversityBrochure = loadable(() => import(/* webpackChunkName: 'university_brochure' */'../admin/universities/brochure'));
export const UniversityPhotoNew = loadable(() => import(/* webpackChunkName: 'university_photo_new' */'../admin/universities/photoNew'));
export const MajorBrochure = loadable(() => import(/* webpackChunkName: 'major_brochure' */'../admin/majors/brochure'));
// Learning
export const GoalIntro = loadable(() => import(/* webpackChunkName: 'goal_intro' */'../learning/goals/intro'));
export const GoalInfo = loadable(() => import(/* webpackChunkName: 'goal_info' */'../learning/goals/info'));
export const GoalRename = loadable(() => import(/* webpackChunkName: 'goal_rename' */'../learning/goals/rename'));
export const GoalActionRequired = loadable(() => import(/* webpackChunkName: 'goal_action_required' */'../learning/goals/actionRequired'));
export const GoalShare = loadable(() => import(/* webpackChunkName: 'goal_share' */'../learning/goals/share'));
export const GoalModalNew = loadable(() => import(/* webpackChunkName: 'goal_modal_new' */'../learning/goals/modalNew'));
export const Vote = loadable(() => import(/* webpackChunkName: 'voteModal' */'../learning/goals/voteModal'));
export const AddCourse = loadable(() => import(/* webpackChunkName: 'add_course' */'../learning/courses/add'));
export const SubstituteCourse = loadable(() => import(/* webpackChunkName: 'substitute_course' */'../learning/courses/substitute'));
export const PeriodEdit = loadable(() => import(/* webpackChunkName: 'period_edit' */'../learning/periods/edit'));
export const PeriodCourseDetails = loadable(() => import(/* webpackChunkName: 'period_course_details' */'../learning/periods/period/periodCourse/details'));
export const PeriodCourseConfirm = loadable(() => import(/* webpackChunkName: 'period_course_confirm' */'../learning/periods/period/periodCourse/confirm'));
export const PeriodCourseMark = loadable(() => import(/* webpackChunkName: 'period_course_mark' */'../learning/periods/period/periodCourse/mark'));
export const PeriodCourseUnits = loadable(() => import(/* webpackChunkName: 'period_course_mark' */'../learning/periods/period/periodCourse/units'));
export const MyCourseNew = loadable(() => import(/* webpackChunkName: 'my_course_new' */'../learning/periods/period/myCourse/new'));
export const MyCourseEdit = loadable(() => import(/* webpackChunkName: 'my_course_edit' */'../learning/periods/period/myCourse/edit'));
export const MyCourseDetails = loadable(() => import(/* webpackChunkName: 'my_course_details' */'../learning/periods/period/myCourse/details'));
export const MyCourseMark = loadable(() => import(/* webpackChunkName: 'my_course_mark' */'../learning/periods/period/myCourse/mark'));
