import React from 'react'
import gql from 'graphql-tag'
import {createUseStyles} from 'react-jss'
import {Link} from 'react-router-dom'

import routesHelper from '@prepler/core/lib/routesHelper'

import {useToggleFavoriteUniversity} from '../../graphql/mutations/useToggleFavoriteUniversity'
import HeartIcon from '../icons/heartIcon'
import theme from '../../theme'
import Rating from '../common/rating'

const useStyles = createUseStyles(theme => ({
	container: {
		border: '1px solid #d5d8dd',
		backgroundColor: '#fff',
		borderRadius: '4px',
		boxShadow: '0px 5px 10px #0000001a',
		marginBottom: '24px',
	},
	top: {
		borderBottom: '1px solid #d5d8dd',
		additional: {},
		display: 'flex',
		flexDirection: 'column',
		'@media screen and (min-width: 992px)': {
			flexDirection: 'row',
		},
	},
	basic: {
		flexGrow: '1',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		'@media screen and (min-width: 480px)': {
			flexDirection: 'row',
		},
	},
	image: {
		borderRadius: '4px',
		overflow: 'hidden',
		margin: '10px',
		'@media screen and (min-width: 480px)': {
			margin: '10px 0 10px 10px',
			width: '160px',
			minWidth: '160px',
		},
		'@media screen and (min-width: 640px)': {
			width: '240px',
			minWidth: '240px',
		},
		'@media screen and (min-width: 768px)': {
			width: '300px',
			minWidth: '300px',
		},
		'@media screen and (min-width: 992px)': {
			width: '180px',
			minWidth: '180px',
		},
		'@media screen and (min-width: 1100px)': {
			width: '240px',
			minWidth: '240px',
		},
		'@media screen and (min-width: 1200px)': {
			width: '300px',
			minWidth: '300px',
		},
		'& > a > img': {
			borderRadius: '4px',
			width: '100%',
			'@media screen and (min-width: 480px)': {
				width: '300px',
				marginLeft: '-70px',
			},
			'@media screen and (min-width: 640px)': {
				marginLeft: '-30px',
			},
			'@media screen and (min-width: 768px)': {
				marginLeft: '0px',
			},
			'@media screen and (min-width: 992px)': {
				marginLeft: '-60px',
			},
			'@media screen and (min-width: 1100px)': {
				marginLeft: '-30px',
			},
			'@media screen and (min-width: 1200px)': {
				marginLeft: '0px',
			},
		},
	},
	info: {
		padding: '0 10px',
		'@media screen and (min-width: 480px)': {
			padding: '10px',
		},
		'& > a > h2': {
			padding: '0 0 6px 0',
			fontSize: '20px',
			lineHeight: '1.1',
			textAlign: 'left',
		},
		'& > a': {
			textDecoration: 'none',
		},
		'& > p': {
			// padding: '20px 0 0',
			'@media screen and (min-width: 480px)': {
				padding: '0 0 12px 0',
			},
		},
	},
	level: {
		fontWeight: theme.fontWeight.normal,
	},
	limitHeight: {
		marginBottom: '8px',
		overflow: 'hidden',
		boxOrient: 'vertical',
		textOverflow: 'ellipsis',
		maxHeight: '70px',
		lineClamp: '3',
		fontSize: '13px',
		display: 'none',
		'@media screen and (min-width: 480px)': {
			display: '-webkit-box',
		},
		'@media screen and (min-width: 1200px)': {
			maxHeight: '92px',
			lineClamp: '4',
		},
	},
	rating: {
		fontWeight: theme.fontWeight.bold,
		padding: '4px 0 0',
	},
	additional: {
		flexGrow: '1',
		padding: '10px 0',
		'@media screen and (min-width: 992px)': {
			borderLeft: '1px solid #d5d8dd',
			minWidth: '200px',
			maxWidth: '200px',
			padding: '0px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
	},
	bottom: {
		display: 'flex',
		flexDirection: 'row',
	},
	admission: {
		flexGrow: '1',
		padding: '10px',
		'& > a:hover': {
			textDecoration: 'none',
		},
	},
	list: {
		display: 'flex',
		flexGrow: '1',
		padding: '10px',
		cursor: 'pointer',
		maxWidth: '44px',
		'@media screen and (min-width: 480px)': {
			maxWidth: '180px',
		},
		'@media screen and (min-width: 992px)': {
			borderLeft: '1px solid #d5d8dd',
			maxWidth: '200px',
		},
		'& > span': {
			display: 'none',
			'@media screen and (min-width: 480px)': {
				display: 'inline',
			},
		},
	},
	rowGroup: {
		display: 'flex',
		justifyContent: 'space-around',
		flexDirection: 'column',
		'@media screen and (min-width: 480px)': {
			flexDirection: 'row',
		},
		'@media screen and (min-width: 992px)': {
			flexDirection: 'column',
		},
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: '1',
		flexBasis: '50%',
		padding: '0 10px',
		justifyContent: 'space-between',
		'& > :first-child': {
			fontWeight: theme.fontWeight.normal,
		},
		width: '240px',
		margin: '0 auto',
		'@media screen and (min-width: 480px)': {
			maxWidth: '200px',
			margin: '0',
		},
		'@media screen and (min-width: 992px)': {
			padding: '12px',
		},
	},
}));

const SchoolItem = (
	{
		university: {
			id, slug, acceptanceRate, admissionChance,
			admissionScores: {actComposite25, actComposite75, satComposite25, satComposite75},
			city, control, description, enrolledTotal, favorite, levelShort, name, photo, rating,
			reviewsMeta, stateAbbr, stateFull, stickerPrice,
		}, ratingCaption
	}
) => {
	const classes = useStyles();
	const toggleFavoriteUniversity = useToggleFavoriteUniversity(favorite, id);

	const onClickFavorite = (e) => {
		e.stopPropagation();
		toggleFavoriteUniversity();
		return false;
	};

	return (
		<div className={classes.container}>
			<div className={classes.top}>
				<div className={classes.basic}>
					<div className={classes.image}>
						<Link to={routesHelper.universityPath({id: slug || id})}>
							<img src={photo.search} alt={name}/>
						</Link>
					</div>
					<div className={classes.info}>
						<Link to={routesHelper.universityPath({id: slug || id})}>
							<h2>{name}</h2>
						</Link>
						<p className={classes.level}>
							{levelShort} &nbsp;&bull;&nbsp; {city},&nbsp;{stateAbbr}
							{reviewsMeta && reviewsMeta.total > 0 && (
								<>
									{' '}&nbsp;&bull;&nbsp;{' '}
									<Rating value={reviewsMeta.avg} readOnly={true} small color="#555">
										{reviewsMeta.total} reviews
									</Rating>
								</>
							)}
						</p>
						<p>
							{name} is a {false && 'above - average'} {control.toLowerCase()} university
							based in {city}, {stateFull}.
							{enrolledTotal && (
								<>
									{' '}It is an {false && 'large'} {false && 'post - secondary'} institution
									with an enrollment of
									over {enrolledTotal.toLocaleString('en')} bachelor’s degree candidates.
								</>
							)}
							{acceptanceRate && (
								<> The admission criteria is {'somewhat competitive'} with the acceptance rate of {acceptanceRate}%.</>
							)}
						</p>
						{false && description && description[0] && (
							<p className={classes.limitHeight}>
								{description[0]}
							</p>
						)}
						<p className={classes.rating}>
							{ratingCaption ? (
								<>{ratingCaption}</>
							) : (
								<>#{rating} Best Colleges in America</>
							)}
						</p>
					</div>
				</div>
				<div className={classes.additional}>
					<div className={classes.rowGroup}>
						{acceptanceRate ? (
							<div className={classes.row}>
								<div>Acceptance Rate</div>
								<div>{acceptanceRate}%</div>
							</div>
						) : null}
						{stickerPrice ? (
							<div className={classes.row}>
								<div>Net Price</div>
								<div>${stickerPrice.toLocaleString('en')}</div>
							</div>
						) : null}
					</div>
					<div className={classes.rowGroup}>
						{(satComposite25 || satComposite75) ? (
							<div className={classes.row}>
								<div>SAT Range</div>
								<div>{satComposite25}-{satComposite75}</div>
							</div>
						) : null}
						{(satComposite25 || satComposite75) ? (
							<div className={classes.row}>
								<div>ACT Range</div>
								<div>{actComposite25}-{actComposite75}</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className={classes.bottom}>
				<div className={classes.admission}>
					{admissionChance ? (
						<>Your Admission Chance: {admissionChance}%</>
					) : (
						<Link to={routesHelper.accountPath()}>
							Get your Admission Chance
						</Link>
					)}
				</div>
				<div
					className={classes.list}
					onClick={onClickFavorite}
				>
					<HeartIcon
						size="24px"
						aria-label="Add to my schools"
						fillColor={favorite ? theme.colors.red[1] : theme.colors.gray[4]}
					/>
					<span>Add to My Schools</span>
				</div>
			</div>
		</div>
	);
};

SchoolItem.fragments = {
	university: gql`
		fragment SchoolItemUniversity on University {
			id
			slug
			acceptanceRate
			admissionChance
			admissionScores {
				actComposite25
				actComposite75
				satComposite25
				satComposite75
			}
			city
			control
			description
			enrolledTotal
			favorite
			levelShort
			name
			photo {
				search
			}
			rating
			reviewsMeta
			stateAbbr
			stateFull
			stickerPrice
		}
	`,
}

export default SchoolItem;
