import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import injectSheet from 'react-jss'
import * as notificationActions from '@prepler/core/lib/actions/notificationActions'
import Notification from '../../common/notification'
import styles from './styles'

// eslint-disable-next-line react/no-deprecated
class Notifications extends React.Component {
	constructor(props) {
		super(props);
		this.state = {timerId: null};
	}

	handleDismiss(index) {
		const {actions: {destroyNotification}} = this.props;
		destroyNotification(index);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.notifications.length > 0 && !this.state.timerId) {
			let timerId = setTimeout(() => {
				this.setState({timerId: null});
				this.handleDismiss(this.props.notifications.length - 1);
			}, 3000);
			this.setState({timerId: timerId})
		}
	}

	render() {
		const {notifications, classes} = this.props;
		return <div className={classes.notifications}>
			{notifications.map((notification, index) =>
				<Notification key={index} {...notification} onDismiss={() => this.handleDismiss(index)}/>
			)}
		</div>;
	}
}

function mapStateToProps(state, ownProps) {
	return {
		notifications: state.notifications
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(notificationActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(Notifications));
