import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as StudentLife } from '../assets/menu/studentLife.svg'

export const active = {
	'& .c2, & .c5': {
		fill: '#455c63'
	},
	'& .c4': {
		fill: '#84a5af'
	},
	'& .c7': {
		fill: '#ffec00'
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .c1, & .c6': {
			fill: 'none'
		},
		'& .c1, & .c3': {
			stroke: '#707070',
			strokeWidth: '2px'
		},
		'& .c1, & .c3, & .c5, & .c6': {
			strokeMiterlimit: '10'
		},
		'& .c2, & .c5': {
			fill: '#6b6b6b'
		},
		'& .c3': {
			fill: '#fff'
		},
		'& .c4': {
			fill: '#9c9c9c'
		},
		'& .c5, & .c6': {
			stroke: '#fff'
		},
		'& .c7': {
			fill: '#d8d8d8'
		},
		'& $active, &:hover': {
			extend: [active]
		}
	},
	active: {},
}));

const IconStudentLife = ({active, className, ...props}) => {
	const classes = useStyles();
	return <StudentLife {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconStudentLife.propTypes = {
	active: PropTypes.bool,
};

IconStudentLife.defaultProps = {
	active: false
};

export default IconStudentLife
