import React from 'react'
import gql from 'graphql-tag'
import {createUseStyles} from 'react-jss'
import {Link} from 'react-router-dom'

import routesHelper from '@prepler/core/lib/routesHelper'

const useStyles = createUseStyles(theme => ({
	container: {
		fontFamily: theme.fonts.brand,
		paddingBottom: '40px',
		'& a:hover': {
			textDecoration: 'none'
		},
	},
	img: {
		width: '100%',
		height: '100px',
		background: 'no-repeat center',
		backgroundSize: 'contain',
		cursor: 'pointer'
	}
}));

const TrendingSchool = ({university: {id, slug, logo, name}}) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Link to={routesHelper.universityPath({id: slug || id})}>
				<div
					className={classes.img}
					style={{backgroundImage: `url('${logo.gallery}')`}}
					title={name}
				/>
			</Link>
		</div>
	);
};

TrendingSchool.fragments = {
	university: gql`
		fragment TrendingSchoolUniversity on University {
			id
			slug
			logo {
				gallery
			}
			name
		}
	`,
}

export default TrendingSchool;
