import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as Tuition } from '../assets/menu/tuition.svg'

export const active = {
	'& .c1': {
		fill: '#2ac6be'
	},
	'& .c3': {
		fill: '#ffc200'
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .c1': {
			fill: '#969696'
		},
		'& .c2, & .c5': {
			fill: '#fff'
		},
		'& .c2, & .c3': {
			fillRule: 'evenodd'
		},
		'& .c3': {
			fill: '#bfbfbf'
		},
		'& .c4': {
			fill: 'none',
			stroke: '#707070',
			strokeLinecap: 'round',
			strokeLinejoin: 'round',
			strokeWidth: '2px'
		},
		'& $active, &:hover': {
			extend: [active]
		}
	},
	active: {},
}));

const IconTuition = ({active, className, ...props}) => {
	const classes = useStyles();
	return <Tuition {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconTuition.propTypes = {
	active: PropTypes.bool,
};

IconTuition.defaultProps = {
	active: false
};

export default IconTuition
