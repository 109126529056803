import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as Courses } from '../assets/menu/courses.svg'

export const active = {
	'& .st1': {
		fill: '#F2E7C7'
	},
	'& .st2': {
		fill: '#C1B495'
	},
	'& .st4': {
		fill: '#FF9100'
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .st0': {fill: '#FFFFFF', stroke: '#707070', strokeWidth: '2', strokeMiterlimit: '10',},
		'& .st1': {fill: '#f2f2f2', stroke: '#707070', strokeWidth: '2', strokeLinecap: 'round', strokeMiterlimit: '10',},
		'& .st2': {fill: '#c2c2c2', stroke: '#707070', strokeWidth: '2', strokeLinecap: 'round', strokeMiterlimit: '10',},
		'& .st3': {fill: 'none', stroke: '#FFFFFF', strokeWidth: '2',strokeLinecap: 'round', strokeMiterlimit: '10',},
		'& .st4': {fill: '#929292', fillRule: 'evenodd', clipRule: 'evenodd',},
		'& .st5': {fill: 'none', stroke: ':#707070', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round',},
		'& .st6': {fill: 'none', stroke: '#FFFFFF', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round',},
		'& .st7': {fill: '#FFFFFF', },
		'& .st8': {fill: '#707070', },
	},
	active: {}
}));

const IconCourses = ({active, className, ...props}) => {
	const classes = useStyles();
	return <Courses {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconCourses.propTypes = {
	active: PropTypes.bool,
};

IconCourses.defaultProps = {
	active: false
};

export default IconCourses
