import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as Overview } from '../assets/menu/overview.svg'

export const active = {
	'& .c3': {
		fill: '#ce670b'
	},
	'& .c5': {
		fill: '#418bc7'
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .c1, & .c2': {
			fill: '#fff'
		},
		'& .c1, & .c2, & .c3, & .c5, & .c6': {
			fillRule: 'evenodd'
		},
		'& .c2, & .c4': {
			stroke: '#707070',
			strokeLinecap: 'round',
			strokeLinejoin: 'round',
			strokeWidth: '2px',
		},
		'& .c3': {
			fill: '#7c7c7c'
		},
		'& .c4': {
			fill: 'none'
		},
		'& .c5': {
			fill: '#7b7b7b'
		},
		'& .c6': {
			fill: '#707070'
		},
		'& $active, &:hover': {
			extend: [active]
		}
	},
	active: {}
}));

const IconOverview = ({active, className, ...props}) => {
	const classes = useStyles();
	return <Overview {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconOverview.propTypes = {
	active: PropTypes.bool,
};

IconOverview.defaultProps = {
	active: false
};

export default IconOverview
