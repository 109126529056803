import React from 'react'
import classNames from 'classnames'
import injectSheet from 'react-jss'
import round from 'lodash-es/round'

import Star from '../icons/star'

const styles = {
	container: {
		display: 'flex',
		marginRight: '4px',
		'& > div': {
			paddingRight: '4px'
		},
		'&$active > div': {
			cursor: 'pointer'
		}
	},
	small: {
		verticalAlign: 'middle',
		display: 'inline-flex'
	},
	children: {
		display: 'none',
		'@media screen and (min-width: 480px)': {
			display: 'inline',
		},
	},
	active: {}
};

const Rating = ({classes, value, onChange, color, small, big, readOnly, children, ...props}) => {
	const valueRounded = value ? round(value) : 0;
	return <div className={classNames(classes.container, {[classes.small]: small, [classes.active]: !readOnly})} {...props}>
		{[1, 2, 3, 4, 5].map((index) =>
			<Star
				key={index}
				fill={valueRounded >= index} color={color}
				small={small} big={big}
				onClick={() => !readOnly && onChange(index)}
			/>
		)}
		<span className={classes.children}>{children}</span>
	</div>
};

export default injectSheet(styles)(Rating)
