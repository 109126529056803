import {largeHeight} from '../header/style'

const styles = theme => ({
	layout: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		alignItems: 'center',
		zIndex: '-3',
		backgroundColor: theme.colors.background,
	},
	header: {
		flex: '0 0 auto',
		padding: '0px 10px',
		'@media screen and (min-width: 410px)': {
			padding: '0px 30px',
			// marginBottom: '7px',
		},
		width: '100%',
		zIndex: '31',
		backgroundColor: '#fff',
		borderBottom: '1px solid #e9ebee',
		boxShadow: '0px 0px 2px #e9ebee',
		'@media screen and (min-width: 992px)': {
			position: 'fixed',
			left: '0px',
			display: 'flex',
			justifyContent: 'center'
		}
	},
	main: {
		minHeight: '650px',
		flex: '1 0 auto',
		width: '100%',
		maxWidth: '1460px',
		padding: '0px 10px',
		'@media screen and (min-width: 410px)': {
			padding: '0px 30px'
		},
		'@media screen and (min-width: 992px)': {
			marginTop: `${largeHeight + 1}px`,
			paddingTop: 0
		},
		'@media screen and (min-width: 1200px)': {
			marginTop: `${largeHeight + 16 - 5}px`,
		},
	},
	mainSearch: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	mainWide: {
		minHeight: '650px',
		position: 'relative',
		flex: '1 0 auto',
		width: '100%',
		'@media screen and (min-width: 992px)': {
			marginTop: `${largeHeight + 1}px`,
			paddingTop: 0
		},
		'@media screen and (min-width: 1200px)': {
			marginTop: `${largeHeight + 16 - 5}px`,
		},
	},
	universityMain: {
		'@media screen and (min-width: 992px)': {
			width: '100%',
			maxWidth: '1460px',
			flex: '1 0 auto',
			marginTop: '0px'
		}
	},
	footer: {
		flex: '0 0 auto',
		padding: '0px 10px',
		'@media screen and (min-width: 410px)': {
			padding: '0px 30px'
		},
		width: '100%',
		zIndex: '30',
		backgroundColor: theme.colors.mediumBlue,
		'@media screen and (min-width: 992px)': {
			display: 'flex',
			justifyContent: 'center'
		}
	}
});

export default styles;
