import React, {useState} from 'react'
import {withRouter} from 'react-router'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'

import routesHelper, {bestUniversitiesPath} from '@prepler/core/lib/routesHelper'

import SearchFormUnified from '../searchFormUnified'
import CurrentUser from './currentUser'
import LogoLink from '../common/logoLink'
import LeftDropDownMenu from './leftDropDownMenu'
import styles from './style'
import useCurrentUser from '../../graphql/queries/useCurrentUser'
import NoSSRStatic from '../common/noSsrStatic'

const useStyles = createUseStyles(styles);

const backToGoalsPaths = [
	routesHelper.goalRegexp(),
	routesHelper.goalNewRegexp(),
	routesHelper.universityGoalNewRegexp(),
	routesHelper.universityMajorGoalNewRegexp(),
	routesHelper.goalDuplicateRegexp()
];

const Header = ({match: {path, url}, showMenu, smallLogo, transparent}) => {
	const classes = useStyles();
	const currentUser = useCurrentUser();
	const {authorized, implicit, legacyProfile: profile} = currentUser;

	const [searchMinimized, setSearchMinimized] = useState(true);

	const goalsCount = useSelector((state) => (state.profile && state.profile.goals_count) || 0);
	const showBackToGoals = backToGoalsPaths.includes(path) && goalsCount !== 0;

	return (
		<div className={classNames(classes.header, {[classes.transparent]: transparent})}>
			<div className={classes.brand}>
				<LeftDropDownMenu transparent={transparent}/>
				<LogoLink smallLogo={smallLogo} transparent={transparent} />
			</div>

			{showMenu && !showBackToGoals && (
				<div className={classes.middleMenu}>
					{
						[
							{to: routesHelper.goalsPath(), title: 'Degree Planner'},
							{to: bestUniversitiesPath(), title: 'College Match'},
							// {to: routesHelper.forStudentsPath(), title: 'For Students'},
							// {to: routesHelper.forParentsPath(), title: 'For Parents'},
							{to: routesHelper.gpaCalculatorPath(), title: 'GPA Calculator'},
							{to: routesHelper.loanCalculatorPath(), title: 'Loan Calculator'}
						].map(({to, title}, index) => url === to ? <span key={index}>{title}</span> : <Link key={index} className={classes.menuLink} to={to}>{title}</Link>)
					}
				</div>
			)}
			{showMenu && !showBackToGoals && (
				<div className={classes.searchMenu}>
					<SearchFormUnified
						minimized={searchMinimized}
						minimize={() => setSearchMinimized(true)}
						maximize={() => setSearchMinimized(false)}
						transparent={transparent}
					/>
				</div>
			)}

			{showBackToGoals && (
				<div className={classNames(classes.searchLeft)}>
					<Link to={routesHelper.goalsPath()}>
						<i className="ion-chevron-left" style={{marginRight: '8px'}} />
						Back
						<span className={classes.whenLarge}> to my Degree Plans</span>
					</Link>
				</div>
			)}

			<div className={classes.navigation}>
				<NoSSRStatic>
					<CurrentUser
						authorized={authorized}
						className={classes.userNavigation}
						implicit={implicit}
						user={currentUser}
						profile={profile}
						transparent={transparent}
					/>
				</NoSSRStatic>
			</div>
		</div>
	);
};

Header.propTypes = {
	showMenu: PropTypes.bool,
	smallLogo: PropTypes.bool,
	transparent: PropTypes.bool,
};

Header.defaultProps = {
	showMenu: true
};

export default withRouter(Header);
