import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
	vimeo: {
		width: '100%',
		height: '100%',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& > iframe': {
			width: '100%',
			height: '100%',
		},
		'& > svg': {
			width: '68px',
			height: '48px',
		},
	},
});

// Get video preview
// http://vimeo.com/api/v2/video/videoId.json
const VimeoIframe = ({id, imgId, title, ...props}) => {
	const classes = useStyles();
	const [touched, setTouched] = useState(false);
	const [clicked, setClicked] = useState(false);
	return (
		<>
			<style>
				{imgId && !touched && !clicked && `
				.${classes.vimeo} {
					background-image: url(https://i.vimeocdn.com/video/${imgId}_200x150)
				}
				@media screen and (min-width: 768px) {
					.${classes.vimeo} {
						background-image: url(https://i.vimeocdn.com/video/${imgId}_640)
					}
				}
				`}
			</style>
			<div
				className={classes.vimeo}
				onMouseEnter={() => !touched && setTouched(true)}
				onClick={() => !clicked && setClicked(true)}
			>
				{(touched || clicked) ? (
					<iframe
						src={`https://player.vimeo.com/video/${id}${clicked ? '?autoplay=1' : ''}`}
						frameBorder="0" allowFullScreen title={title}
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						{...props}
					/>
				) : (
					<svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
						<path
							className="ytp-large-play-button-bg"
							d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
							fill="#212121" fillOpacity="0.8"
						/>
						<path d="M 45,24 27,14 27,34" fill="#fff"/>
					</svg>
				)}
			</div>
		</>
	);
};

export default VimeoIframe;
