import React from 'react'
import routesHelper from '@prepler/core/lib/routesHelper'
import SuggestionsItem from './suggestionsItem'

const SuggestionsSection = ({query, suggestions, sectionTitle, section, moreTitle, setTitle, pathFn}) => {
	return (
		<>
			{suggestions.map(({id, slug, title, rootTitle}, key) => (
				<SuggestionsItem
					key={`${section}-${id}`} index={key} sectionTitle={sectionTitle} title={title} rootTitle={rootTitle}
					onClick={() => setTitle(rootTitle)}
					path={pathFn({id: slug || id})}
				/>
			))}
			{query !== '' && suggestions.length > 0 && (
				<SuggestionsItem
					moreTitle={moreTitle}
					onClick={() => setTitle(query)}
					path={routesHelper.searchQueryPath({section, query})}
				/>
			)}
		</>
	)
};

export default SuggestionsSection;
