import React from 'react'
import gql from 'graphql-tag'
import {withRouter, Link} from 'react-router-dom'
import {createUseStyles} from 'react-jss'

import routesHelper, {bestUniversitiesPath} from '@prepler/core/lib/routesHelper'

import Helmet from '../common/helmet'

const useStyles = createUseStyles(theme => ({
	breadcrumbs: {
		display: 'flex',
		flexWrap: 'wrap',
		zIndex: '4',
		width: 'calc(100% - 20px)',
		fontSize: '11px',
		fontWeight: theme.fontWeight.normal,
		padding: '4px 0 4px 15px',
		borderRadius: '5px',
		'& > li': {
			display: 'flex',
			whiteSpace: 'nowrap',
			'&:not(:last-child):after': {
				content: '">"',
				transform: 'scaleX(0.7)',
				margin: '0 7px',
				textDecoration: 'none',
			},
			'& > *': {
				color: '#555',
				'&:hover': {
					color: '#3e8bca',
				},
			},
		},
	},
}));

const courseInfoTitle = (({abbr, title, letter}) => {
	if (abbr) {
		return `${abbr} - ${title}`;
	}
	if (letter) {
		return letter;
	}
	return 'Course info';
});

const Breadcrumbs = ({match: {path, params}, university: {id, slug, name, stateFull}}) => {
	const classes = useStyles();

	const linkingData = {
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		'itemListElement': [
			{
				'@type': 'ListItem',
				'item': {
					'@id': 'https://www.prepler.com/',
					'name': 'Home'
				},
				'position': '1'
			},
			{
				'@type': 'ListItem',
				'item': {
					'@id': bestUniversitiesPath({state: stateFull.replace(' ', '-').toLowerCase()}),
					'name': stateFull
				},
				'position': '2'
			},
			{
				'@type': 'ListItem',
				'item': {
					'@id': routesHelper.universityPath({id: slug || id}),
					'name': name
				},
				'position': '3'
			}
		]
	};

	return (<>
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(linkingData)}
			</script>
		</Helmet>
		<ul className={classes.breadcrumbs}>
			<li>
				<Link to={routesHelper.rootPath()}>
					Home
				</Link>
			</li>
			<li>
				<Link to={bestUniversitiesPath({state: stateFull.replace(' ', '-').toLowerCase()})}>
					{stateFull}
				</Link>
			</li>
			{path === '/colleges/:id' ? (
				<li>
					<span>{name}</span>
				</li>
			) : (
				<>
					<li>
						<Link to={routesHelper.universityPath({id: slug || id})}>
							{name}
						</Link>
					</li>
					{
						path.match('/colleges/:id/course-info/') && <li>
							<Link to={routesHelper.universityCoursesPath({id: slug || id})}>
								Course info
							</Link>
						</li>
					}
					<li>
						<span>
							{path === '/colleges/:id/admission' && 'Admission'}
							{path === '/colleges/:id/tuition' && 'Tuition'}
							{path === '/colleges/:id/gpa-calculator' && 'GPA calculator'}
							{path === '/colleges/:id/loan-calculator' && 'Loan calculator'}
							{path === '/colleges/:id/academics' && 'Academics'}
							{path.match('/colleges/:id/course-info') && courseInfoTitle(params)}
							{path === '/colleges/:id/student-life' && 'Student life'}
							{path === '/colleges/:id/after-graduation' && 'After graduation'}
							{path.match(/\/colleges\/:id\/reviews\/:category/) && 'Reviews'}
							{path === '/colleges/:id/reviews/new' && 'Reviews'}
						</span>
					</li>
				</>
			)}
		</ul>
	</>);
};

Breadcrumbs.fragments = {
	university: gql`
		fragment BreadcrumbsUniversity on University {
			id
			slug
			name
			stateFull
		}
	`,
};

export default withRouter(Breadcrumbs);
