export default theme => ({
	root: {
		display: 'flex',
		width: '100%'
	},
	control: {
		alignItems: 'center',
		color: theme.colors.gray[4],
		cursor: 'pointer',
		display: 'flex',
		flexShrink: 0,
		fontSize: '34px',
		justifyContent: 'flex-start',
		transition: 'color 250ms ease-in-out',
		'&:not($controlInactive):hover': {
			color: theme.colors.primary
		},
		'&:last-child': {
			justifyContent: 'flex-end'
		}
	},
	'control-size-20': {
		width: '20px',
		fontSize: '20px'
	},
	'control-size-34': {
		width: '34px',
		fontSize: '34px'
	},
	'control-size-48': {
		width: '48px',
		fontSize: '48px'
	},
	controlInactive: {
		color: theme.colors.gray[2]
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'hidden',
		width: '100%'
	},
	inner: {
		display: 'flex',
		transition: 'transform 250ms ease-in-out',
		width: '100%',
		alignItems: 'center',
	},
	center: {
		justifyContent: 'center',
	},
	item: {
		minWidth: '100%'
	},
	itemMany: {
		padding: '0 15px'
	},
	dots: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		height: '40px'
	},
	dot: {
		backgroundColor: theme.colors.gray[2],
		borderRadius: '50%',
		height: '4px',
		margin: '0 5px',
		transition: '250ms ease-in-out',
		width: '4px'
	},
	dotActive: {
		backgroundColor: theme.colors.primary,
		height: '6px',
		width: '6px'
	}
});
