import {useEffect} from 'react'
import {withRouter} from 'react-router-dom'

const RemoveTrailingSlash = ({history: {push, location: {pathname, search, hash}}}) => {
	useEffect(() => {
		let newPathname = pathname;
		if (newPathname !== newPathname.toLowerCase()) {
			newPathname = newPathname.toLowerCase();
		}
		if (newPathname.slice(-1) === '/' && newPathname !== '/') {
			newPathname = newPathname.slice(0, -1);
		}
		if (pathname !== newPathname) {
			push(`${newPathname}${search}${hash}`);
		}
	}, [pathname, search, hash]);

	return null;
}

export default withRouter(RemoveTrailingSlash);
