import React from 'react'
import gql from 'graphql-tag'

import Helmet from '../common/helmet'

const LinkingData = ({university: {address, city, description, name, phone, photo, reviewsMeta, stateAbbr, urls, zip}}) => {
	const collegeOrUniversityLinkingData = {
		'@context': 'http://schema.org',
		'@type': 'CollegeOrUniversity',
		'address': {
			'@type': 'PostalAddress',
			'addressLocality': city,
			'addressRegion': stateAbbr,
			'postalCode': zip,
			'streetAddress': address
		},
		'description': description && description[0],
		'name': name,
		'telephone': phone
	};

	if (photo && photo.gallery4x) {
		collegeOrUniversityLinkingData.image = photo.gallery4x;
	}

	if (reviewsMeta && parseInt(reviewsMeta.total) > 0) {
		const {
			min: worstRating, max: bestRating, avg: ratingValue, total: reviewCount
		} = reviewsMeta;
		collegeOrUniversityLinkingData.aggregateRating = {
			'@type': 'AggregateRating',
			worstRating,
			bestRating,
			ratingValue,
			reviewCount
		};
	}

	if (urls && urls.website) {
		collegeOrUniversityLinkingData.sameAs = urls.website;
	}

	return (
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(collegeOrUniversityLinkingData)}
				</script>
			</Helmet>
		</>
	);
};

LinkingData.fragments = {
	university: gql`
		fragment LinkingDataUniversity on University {
			id
			slug

			address
			city
			description
			name
			phone
			photo {
				gallery4x
			}
			reviewsMeta
			stateAbbr
			urls {
				website
			}
			zip
		}
	`,
};

export default LinkingData;
