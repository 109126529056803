import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles(theme => ({
	schoolItemPresentation: {
		position: 'relative',
		marginBottom: '24px',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		'&.small': {
			display: 'flex',
			flexDirection: 'column'
		}
	},
	schoolItemLink: {
		'&:hover': {
			textDecoration: 'none',
		},
	},
	info: {
		position: 'absolute',
		left: '0',
		right: '0',
		bottom: '0px',
		padding: '24px 15px 12px',
		color: '#fff',
		background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.25) 35%, rgba(0, 0, 0, 0.3) 100%)',
		'& p i': {
			display: 'inline-block',
			paddingRight: '5px',
			marginLeft: '1px'
		},
		'& h3': {
			color: '#fff',
			textShadow: '2px 2px 7px #222222',
			fontSize: '18px',
			fontWeight: theme.fontWeight.normal,
			lineHeight: '24px',
			'& a': {
				color: '#fff'
			}
		},
		'& span': {
			color: '#fff',
			textShadow: '2px 2px 7px #222222'
		},
		'@media screen and (min-width: 768px)': {
			padding: '24px 18px 12px',
			'& h3': {
				color: '#fff',
				fontSize: '20px',
				lineHeight: '24px'
			},
			'& p': {
				padding: '0',
				fontSize: '15px',
				lineHeight: '20px'
			}
		},
		'& $additional': {
			fontSize: '14px',
			display: 'flex',
			justifyContent: 'space-between',
			'& $textEllipsis': {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				fontWeight: theme.fontWeight.normal,
				textShadow: '2px 2px 7px #222222',
				padding: '0 2px 0 0',
				'&$rtl': {
					padding: '0'
				},
				'& strong': {
					fontWeight: theme.fontWeight.bold
				}
			}
		}
	},
	infoTop: {
		position: 'relative',
		cursor: 'pointer',
		background: 'no-repeat center',
		backgroundSize: 'cover',
		color: '#fff',
		height: '250px'
	},
	infoBottom: {
		textAlign: 'center',
		backgroundColor: theme.colors.darkBlue,
		borderRadius: '0px 0px 2px 2px',
		boxShadow: '0 7px 10px -3px #e6e8eb'
	},
	btnHeart: {
		float: 'right',
		margin: '12px 15px',
		width: '28px',
		height: '32px',
		opacity: '0.7',
		border: 'none',
		background: 'none',
		padding: '0',
		fontSize: '32px',
		'&:hover': {
			opacity: 1
		}
	},
	infoExt: {
		marginBottom: '24px',
		'& p': {
			padding: '0',
			'&$withLabel': {
				padding: '6px 0',
				'.label': {
					backgroundColor: theme.colors.primary,
					fontSize: '100%',
					'&:hover': {
						backgroundColor: theme.colors.success,
						textDecoration: 'none'
					}
				},
				'& a': {
					textDecoration: 'none',
					'&:hover': {
						textDecoration: 'none',
						color: 'inherit'
					}
				}
			},
			'& i': {
				paddingRight: '8px',
				marginLeft: '1px'
			}
		},
		'& h3': {
			'& a': {
				textDecoration: 'none',
				'&:hover': {
					textDecoration: 'underline',
					color: 'inherit'
				}
			}
		},
		'& a': {
			display: 'inline-block',
			textDecoration: 'underline',
			color: 'inherit'
		}
	},
	more: {
		padding: '6px 0 0',
		'& a': {
			padding: '5px 15px',
			color: theme.colors.primary,
			textDecoration: 'none',
			border: '1px solid #fff',
			'&:hover': {
				color: '#fff',
				background: theme.colors.primary,
				borderColor: theme.colors.primary
			},
			'& i': {
				paddingLeft: '8px'
			}
		}
	},
	rtl: {
		direction: 'rtl'
	},
	additional: {},
	textEllipsis: {},
	withLabel: {}
	// btnLive: {
	// 	float: 'left',
	// 	margin: '12px 15px',
	// 	padding: '6px 15px',
	// 	font: `normal 16px/24px ${theme.fonts.brand}`,
	// 	color: '#fff',
	// 	textTransform: 'uppercase',
	// 	background: 'none',
	// 	border: '2px solid rgba(255, 255, 255, 0.5)',
	// 	borderRadius: '2px',
	// 	'&:hover': {
	// 		color: theme.colors.primary,
	// 		background: '#fff'
	// 	},
	// 	'@media screen and (min-width: 768px)': {
	// 		padding: '6px 15px',
	// 		fontSize: '12px',
	// 		lineHeight: '18px'
	// 	}
	// }
}));

export default useStyles;
