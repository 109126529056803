const styles = theme => ({
	layout: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		alignItems: 'center'
	},
	header: {
		flex: '0 0 auto',
		padding: '0px 10px',
		'@media screen and (min-width: 410px)': {
			padding: '0px 30px'
		},
		width: '100%',
		zIndex: '15',
		backgroundColor: '#fff',
		borderBottom: '1px solid #e9ebee',
		boxShadow: '0px 0px 2px #e9ebee',
		'@media screen and (min-width: 992px)': {
			position: 'fixed',
			left: '0px',
			display: 'flex',
			justifyContent: 'center'
		}
	},
	transparent: {
		backgroundColor: 'transparent',
		borderBottom: '3px solid transparent',
		boxShadow: 'none',
	},
	main: {
		minHeight: '2000px',
		flex: '1 0 auto',
		width: '100%',
		marginTop: '-43px',
		'@media screen and (min-width: 992px)': {
			marginTop: '0',
			paddingTop: '0'
		},
	},
	footer: {
		flex: '0 0 auto',
		padding: '0px 10px',
		'@media screen and (min-width: 410px)': {
			padding: '0px 30px'
		},
		width: '100%',
		backgroundColor: theme.colors.mediumBlue,
		'@media screen and (min-width: 992px)': {
			display: 'flex',
			justifyContent: 'center'
		}
	},
});

export default styles
