import React from 'react'
import {Route} from 'react-router-dom'
import injectSheet from 'react-jss'
import classNames from 'classnames'

import App from '@prepler/core/lib/app'
import routesHelper from '@prepler/core/lib/routesHelper'

import Header from '../header'
import Footer from '../footer'
import Modals from './modals'
import Notifications from './notifications'
import SearchFilterPage from '../loadable/components/searchFilterPage'
import UkraineBanner from './ukraineBanner'
import styles from './styles'

const overrideMatchParams = (props) => {
	const {match: {path}} = props;
	if (path === routesHelper.bestUniversitiesByMajorRegexp()) {
		props.match.params.bestSection = 'major'; // necessary set to major since in this route bestSection is null
	}
	return props;
};

const SearchLayout = ({classes, routePath, canonicalUrl, ...rest}) => (
	<Route
		{...rest}
		render={(matchProps) => (
			(App.history = matchProps.history) && (
				<div className={classes.layout}>
					<Notifications />
					<header className={classes.header}>
						<Header showMenu={true} {...matchProps}/>
					</header>
					<UkraineBanner/>
					<main className={classNames(classes.main, classes.mainSearch)}>
						<SearchFilterPage
							routePath={routePath}
							canonicalUrl={canonicalUrl}
							{...overrideMatchParams(matchProps)}
						/>
					</main>
					<footer className={classes.footer}>
						<Footer/>
					</footer>
					<Modals history={matchProps.history}/>
				</div>
			)
		)}
	/>
);

export default injectSheet(styles)(SearchLayout)
