import React from 'react'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import classNames from 'classnames'

import star from '../../assets/images/star.svg'

const styles = {
	icon: {
		'& svg': {
			width: '20px',
			height: '20px'
		},
		'&$small svg': {
			width: '10px',
			height: '10px',
			'@media screen and (min-width: 768px)': {
				width: '12px',
				height: '12px',
			},
			'@media screen and (min-width: 992px)': {
				width: '14px',
				height: '14px',
			},
		},
		'&$big svg': {
			width: '26px',
			height: '26px'
		}
	},
	small: {},
	big: {}
};

const Star = ({classes, color, fill, small, big, className, ...props}) =>
	<ReactSVG
		src={star}
		beforeInjection={svg => fill && svg.children[0].children[0].setAttribute('fill', color)}
		{...props}
		style={{color}}
		className={classNames(classes.icon, className, {[classes.small]: small, [classes.big]: big})}
	/>;

Star.propTypes = {
	color: PropTypes.string,
	fill: PropTypes.bool,
	big: PropTypes.bool
};

Star.defaultProps = {
	color: '#ffa951',
	fill: true,
	big: false
};

export default injectSheet(styles)(Star)
