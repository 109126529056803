import injectSheet from 'react-jss'

const styles = theme => ({
	container: {
		height: '70px',
		color: theme.colors.white
	},
	link: {
		// margin: '0 18px',
		position: 'relative',
		'&:hover': {
			textDecoration: 'none'
		}
	},
	h5: {
		padding: '10px 0px 5px',
		color: theme.colors.white,
		textTransform: 'uppercase'
	},
	tooltip: {
		position: 'relative',
		margin: '0 -19px -14px',
		width: '38px',
		color: '#fff',
		borderRadius: '3px',
		fontWeight: theme.fontWeight.bold,
		fontSize: '15px',
		lineHeight: '25px',
		backgroundColor: theme.colors.primary,
	},
	activeGradient: {
		height: '3px',
		backgroundImage: 'linear-gradient(to right, #57d489 0%, #3e8bca 75%, #3a56c7 100%)'
	},
	solidGradient: {
		height: '3px',
		backgroundColor: theme.colors.primary
	},
	inactiveGradient: {
		height: '3px',
		marginTop: '-3px',
		backgroundColor: '#e9ebee'
	},
	captions: {
		display: 'flex',
		alignItems: 'flex-end'
	},
	number: {
		padding: '5px 18px',
		color: '#999'
	},
	slider: {
		flexGrow: '1',
		marginBottom: '14px'
	},
	your: {
		display: 'none',
		'@media screen and (min-width: 360px)': {
			display: 'inline'
		}
	},
	linkGet: {
		padding: '9px 19px',
		'@media screen and (min-width: 380px)': {
			padding: '19px 16px'
		},
		height: '70px',
		backgroundColor: theme.colors.darkBlue,
		'& a': {
			display: 'inline-block',
			marginBottom: '6px',
			padding: '5px 8px',
			borderRadius: '4px',
			fontSize: '12px',
			fontWeight: theme.fontWeight.bold,
			color: theme.colors.white,
			backgroundColor: theme.colors.primary,
			'&:hover': {
				backgroundColor: theme.colors.success,
				textDecoration: 'none'
			}
		}
	}
});

export default (component) => injectSheet(styles)(component);
