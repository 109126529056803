import React from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Hammer from 'hammerjs'
import styles from './style'

// eslint-disable-next-line react/no-deprecated
class Carousel extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			page: 0
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const {children, perPage} = nextProps;
		this.pageCount = children.length - perPage;
		if (this.pageCount < this.state.page) {
			this.setState({page: this.pageCount < 0 ? 0 : this.pageCount});
		}
	}

	UNSAFE_componentWillMount() {
		const {children, perPage} = this.props;
		this.pageCount = children.length - perPage;
	}

	componentDidMount() {
		const {enableSwipe} = this.props;
		if (enableSwipe) {
			this.hammer = Hammer(this.innerRef);
			this.hammer.on('swiperight', this.incrementPage.bind(this, -1));
			this.hammer.on('swipeleft', this.incrementPage.bind(this, 1))
		}
	}

	componentWillUnmount() {
		if (this.hammer) {
			this.hammer.off('swiperight swipeleft');
		}
	}

	incrementPage(step) {
		let {page} = this.state;
		if ((page === 0 && step < 0) || (page === this.pageCount && step > 0)) {
			return;
		}
		page += step * this.props.increment;
		page < 0 && (page = 0);
		page > this.pageCount && (page = this.pageCount);
		this.setState({page});
		this.props.onChange && this.props.onChange(page, page >= this.pageCount)
	}

	render() {
		const {classes, className, style, children, perPage, controlSize, showControls, showDots} = this.props;
		const {page} = this.state;
		const hasPrev = page > 0;
		const hasNext = page < this.pageCount;
		const itemWidth = 100 / perPage;
		const onePage = children.length < perPage;
		return (
			<div className={classNames(classes.root, className)} style={style}>
				{showControls && <div
					className={classNames(classes.control, classes[`control-size-${controlSize}`], {[classes.controlInactive]: !hasPrev})}
					onClick={this.incrementPage.bind(this, -1)}
				>
					<i className="ion-chevron-left"/>
				</div>}

				<div className={classes.container}>
					<div
						className={classNames(classes.inner, {[classes.center]: onePage})}
						ref={ref => this.innerRef = ref}
						style={{transform: `translate3d(-${page * itemWidth}%, 0, 0)`}}
					>
						{children.map((child, i) => (
							<div
								key={i}
								className={classNames(classes.item, {[classes.itemMany]: perPage > 1})}
								style={{minWidth: `${itemWidth}%`}}
							>
								{child}
							</div>
						))}
					</div>

					{showDots && <div className={classes.dots}>
						{[...Array(this.pageCount + 1)].map((_, i) => (
							<div key={i} className={classNames(classes.dot, {[classes.dotActive]: page === i})}/>
						))}
					</div>}
				</div>

				{showControls && <div
					className={classNames(classes.control, classes[`control-size-${controlSize}`], {[classes.controlInactive]: !hasNext})}
					onClick={this.incrementPage.bind(this, 1)}
				>
					<i className="ion-chevron-right"/>
				</div>}
			</div>
		)
	}
}

Carousel.propTypes = {
	classes: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	children: PropTypes.arrayOf(PropTypes.node).isRequired,
	perPage: PropTypes.number.isRequired,
	increment: PropTypes.number,
	controlSize: PropTypes.oneOf([20, 34, 48]),
	showControls: PropTypes.bool.isRequired,
	showDots: PropTypes.bool.isRequired,
	enableSwipe: PropTypes.bool.isRequired
};

Carousel.defaultProps = {
	perPage: 1,
	increment: 1,
	controlSize: 20,
	showControls: true,
	showDots: false,
	enableSwipe: true
};

export default injectSheet(styles)(Carousel)
