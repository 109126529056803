import React, {useCallback, useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {Link} from 'react-router-dom'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'

import routesHelper from '@prepler/core/lib/routesHelper'

import GraphqlError from '../dev/graphqlError'
import Helmet from '../common/helmet'
import Loader from '../common/loader'
import useHandleOutsideClick from '../../utils/useHandleOutsideClick'
import {largeHeight} from '../header/style'
import IconOverview, {active as overviewActive} from './icons/overview'
import IconAdmission, {active as admissionActive} from './icons/admission'
import IconTuition, {active as tuitionActive} from './icons/tuition'
import IconGpa, {active as gpaActive} from './icons/gpa'
import IconLoan, {active as loanActive} from './icons/loan'
import IconAcademics, {active as academicsActive} from './icons/academics'
import IconCourses, {active as coursesActive} from './icons/courses'
import IconStudentLife, {active as studentLifeActive} from './icons/studentLife'
import IconAfterGrad, {active as afterGradActive} from './icons/afterGrad'
import IconReviews, {active as reviewsActive} from './icons/reviews'
import UniversityHeader from './universityHeader'
import Breadcrumbs from './breadcrumbs'
import LinkingData from './linkingData'
import CalculatorPromotion from '../learning/calculator/promotion'

const useStyles = createUseStyles((theme) => ({
	container: {
		// display: 'flex',
		// flexDirection: 'row',
		position: 'relative',
		width: '100%',
		padding: '0 10px',
		'@media screen and (min-width: 410px)': {
			padding: '0 30px',
		},
		'@media screen and (min-width: 992px)': {
			marginTop: `${largeHeight + 1}px`,
			maxWidth: '1300px',
		},
		'@media screen and (min-width: 1200px)': {
			marginTop: `${largeHeight + 11}px`,
		},
	},
	footer: {
		display: 'flex',
		// justifyContent: 'space-around',
		alignItems: 'center',
		position: 'fixed',
		bottom: '0',
		left: '0',
		right: '0',
		height: '50px',
		padding: '10px 0',
		textAlign: 'center',
		fontSize: '16px',
		'& > i:first-child': {
			width: '70px',
			display: 'block',
		},
		'& > div': {
			flexGrow: '1',
			textAlign: 'center',
			 paddingRight: '70px',
		},
		'& > i': {
			display: 'none',
			width: '40px',
			flexGrow: '0',
			flexShrink: '0',
			fontSize: '24px',
		},
		color: '#fff',
		backgroundColor: theme.colors.primary,
		zIndex: '30',
		'@media screen and (min-width: 768px)': {
			display: 'none',
		}
	},
	menu: {
		display: 'flex',
		// paddingTop: '30px',
		justifyContent: 'space-between',
		// alignItems: 'baseline',
		// marginTop: '15px',
		position: 'fixed',
		zIndex: '5',
		backgroundColor: '#fff',
		bottom: '50px',
		left: '0px',
		marginTop: '0px',
		flexDirection: 'column',
		width: '220px',
		borderTop: '1px solid #e9ebee',
		borderRight: '1px solid #e9ebee',
		borderTopRightRadius: '5px',
		'&$hidden': {
			visibility: 'hidden',
			'@media screen and (min-width: 768px)': {
				visibility: 'visible',
			},
		},
		paddingTop: '5px',
		boxShadow: '0px 0px 8px #00000030',
		'@media screen and (min-width: 768px)': {
			paddingTop: '0px',
			boxShadow: 'none',
			position: 'sticky',
			alignItems: 'center',
			float: 'left',
			bottom: 'unset',
			left: 'unset',
			top: `${41+30}px`,
			width: '60px',
			borderTop: 'none',
			borderRight: 'none',
		},
		'@media screen and (min-width: 992px)': {
			top: `${46+30}px`,
			width: '70px',
		},
		'@media screen and (min-width: 1200px)': {
			top: `${56+27}px`,
			width: '90px',
		},
		'& > a': {
			textAlign: 'center',
			color: 'inherit',
			textDecoration: 'none',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			'@media screen and (min-width: 768px)': {
				maxWidth: '70px',
				flexDirection: 'column',
				marginBottom: '10px',
			},
			// '@media screen and (min-width: 992px)': {
			// 	marginBottom: '15px',
			// },
			// '@media screen and (min-width: 1200px)': {
			// 	marginBottom: '20px',
			// },
			'&$active, &$inActive:hover': {
				color: '#0c5e8e',
				'&$overview svg': {
					extend: [overviewActive]
				},
				'&$admission svg': {
					extend: [admissionActive]
				},
				'&$tuition svg': {
					extend: [tuitionActive]
				},
				'&$gpa svg': {
					extend: [gpaActive]
				},
				'&$loan svg': {
					extend: [loanActive]
				},
				'&$academics svg': {
					extend: [academicsActive]
				},
				'&$courses svg': {
					extend: [coursesActive]
				},
				'&$studentLife svg': {
					extend: [studentLifeActive]
				},
				'&$afterGrad svg': {
					extend: [afterGradActive]
				},
				'&$reviews svg': {
					extend: [reviewsActive]
				},
			},
			'& > img, & svg': {
				width: '40px',
				margin: '0px 15px 10px',
				flexShrink: '0',
				'@media screen and (min-width: 768px)': {
					margin: '0 auto',
					width: '30px',
				},
				'@media screen and (min-width: 992px)': {
					width: '40px',
				},
				'@media screen and (min-width: 1200px)': {
					width: '45px',
				},
			},
			'& > p': {
				fontWeight: theme.fontWeight.normal,
				lineHeight: '16px',
				fontSize: '14px',
				paddingTop: '0px',
				paddingBottom: '0px',
				'@media screen and (min-width: 768px)': {
					fontSize: '10px',
					paddingTop: '6px',
				},
				'@media screen and (min-width: 992px)': {
					fontSize: '11px',
					lineHeight: '15px',
				},
				'@media screen and (min-width: 1200px)': {
					fontSize: '14px',
				},
			},
		},
	},
	content: {
		// backgroundColor: '#fff',
		'@media screen and (min-width: 768px)': {
			minHeight: '860px',
			marginLeft: '80px',
		},
		'@media screen and (min-width: 992px)': {
			marginLeft: '90px',
		},
		'@media screen and (min-width: 1200px)': {
			marginLeft: '120px',
		},
	},
	hidden: {},
	active: {},
	inActive: {},
	overview: {},
	admission: {},
	tuition: {},
	gpa: {},
	loan: {},
	academics: {},
	courses: {},
	studentLife: {},
	afterGrad: {},
	reviews: {
		position: 'relative',
		'& span': {
			top: '3px',
			left: '38px',
			minWidth: '19px',
			height: '19px',
			fontSize: '9px',
			padding: '0px 5px',
			lineHeight: '15px',
			'@media screen and (min-width: 768px)': {
				top: '0px',
				left: 'unset',
				right: '-2px',
			},
			'@media screen and (min-width: 992px)': {
				top: '2px',
				right: '-2px',
				minWidth: '22px',
				height: '21px',
				fontSize: '10px',
				padding: '1px 5px'
			},
			'@media screen and (min-width: 1200px)': {
				top: '4px',
				right: '2px',
			},
			color: '#fff',
			backgroundColor: theme.colors.primary,
			border: '2px solid #fff',
			borderRadius: '11px',
			position: 'absolute',
			textAlign: 'center',
			verticalAlign: 'middle',
		}
	}
}));

const UNIVERSITY = gql`
	query University($slug: String!) {
		university(slug: $slug) {
			id
			slug
			calculatorEnabled
			name
			reviewsMeta
			...UniversityHeaderUniversity
			...BreadcrumbsUniversity
			...LinkingDataUniversity
		}
	}
	${UniversityHeader.fragments.university}
	${Breadcrumbs.fragments.university}
	${LinkingData.fragments.university}
`;

const activePathCaptions = {
	'/colleges/:id': 'Overview',
	'/colleges/:id/admission': 'Admission',
	'/colleges/:id/tuition': 'Tuition',
	'/colleges/:id/gpa-calculator': 'GPA calculator',
	'/colleges/:id/loan-calculator': 'Loan calculator',
	'/colleges/:id/academics': 'Academics',
	'/colleges/:id/courses': 'Course info',
	'/colleges/:id/student-life': 'Student life',
	'/colleges/:id/after-graduation': 'After graduation',
	'/colleges/:id/reviews': 'Reviews',
};

const UniversityPage = (
	{match: {path, params: {id: slug}}, location: {search}, component: Component, routePath}
) => {
	const classes = useStyles();

	const [showMenu, setShowMenu] = useState(false);
	const hideMenu = useCallback(() => {showMenu && setShowMenu(false)}, [showMenu]);
	const menuRef = useHandleOutsideClick(hideMenu);

	const {loading, error, data} = useQuery(UNIVERSITY, {variables: {
		slug
	}});

	if (loading) return <div className={classNames(classes.container)}><Loader/></div>;
	if (error) return <GraphqlError error={error}/>;

	const {university} = data;

	const debug = search === '?debug';
	const debugParam = debug ? '?debug' : '';

	return (
		<>
			<LinkingData university={university}/>
			<div className={classNames(classes.container)}>
				<div
					className={classNames(classes.menu, {[classes.hidden]: !showMenu})}
					ref={menuRef}
					onClick={() => setShowMenu(false)}
				>
					{[
						{
							key: 'overview',
							to: `${routesHelper.universityPath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id',
							Icon: IconOverview,
							title: 'Overview'
						},
						{
							key: 'admission',
							to: `${routesHelper.universityAdmissionPath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id/admission',
							Icon: IconAdmission,
							title: 'Admission'
						},
						{
							key: 'tuition',
							to: `${routesHelper.universityTuitionPath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id/tuition',
							Icon: IconTuition,
							title: 'Tuition'
						},
						{
							key: 'gpa',
							to: `${routesHelper.universityGpaCalculatorPath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id/gpa-calculator',
							Icon: IconGpa,
							title: 'GPA calculator'
						},
						{
							key: 'loan',
							to: `${routesHelper.universityLoanCalculatorPath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id/loan-calculator',
							Icon: IconLoan,
							title: 'Loan calculator'
						},
						{
							key: 'academics',
							to: `${routesHelper.universityAcademicsPath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id/academics',
							Icon: IconAcademics,
							title: 'Academics'
						},
						university && university.calculatorEnabled && {
							key: 'courses',
							to: `${routesHelper.universityCoursesPath({id: slug})}${debugParam}`,
							active: (path) => path.match('/colleges/:id/course-info'),
							Icon: IconCourses,
							title: 'Course info'
						},
						{
							key: 'studentLife',
							to: `${routesHelper.universityStudentLifePath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id/student-life',
							Icon: IconStudentLife,
							title: 'Student life'
						},
						debug && {
							key: 'afterGrad',
							to: `${routesHelper.universityAfterGraduationPath({id: slug})}${debugParam}`,
							active: (path) => path === '/colleges/:id/after-graduation',
							Icon: IconAfterGrad,
							title: 'After graduation'
						},
						{
							key: 'reviews',
							to: `${routesHelper.universityReviewsPath({id: slug})}${debugParam}`,
							active: (path) => path.match('/colleges/:id/reviews'),
							Icon: IconReviews,
							title: 'Reviews'
						}
					].filter((item) => item).map(({key, to, active, Icon, title}) =>
						<Link key={key} to={to} className={classNames(classes[key], active(path) ? classes.active : classes.inActive)}>
							<Icon/>
							{key === 'reviews' && university.reviewsMeta.total > 0 && (
								<span>{university.reviewsMeta.total}</span>
							)}
							<p>{title}</p>
						</Link>
					)}
				</div>
				<footer
					className={classes.footer}
					key={showMenu}
					onMouseDown={() => !showMenu && setShowMenu(true)}
				>
					<i className="ion-android-menu"/>
					<i className="ion-chevron-left"/>
					<div>{activePathCaptions[path]}</div>
					<i className="ion-chevron-right"/>
				</footer>
				<div className={classes.content}>
					<Component university={university} routePath={routePath} debug={debug} />
				</div>
			</div>
			<CalculatorPromotion/>
		</>
	);
};

export default UniversityPage;
