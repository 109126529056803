import injectSheet from 'react-jss'

export const styles = theme => ({
	accountSection: {
		padding: '14px 14px 24px 14px',
		'&:not(:last-child)': {
			borderBottom: '1px solid #ddd'
		},
		'&>.row': {
			paddingBottom: '12px'
		},
		'& h3': {
			margin: '5px 0px 15px 0px',
			fontSize: '22px'
		}
	},
	accountSectionTitle: {
		padding: '12px 0 24px',
		textAlign: 'center',
		fontSize: '120%',
		fontFamily: theme.fonts.brand,
		fontWeight: theme.fontWeight.normal
	},
	sortBy: {
		marginBottom: '14px',
	},
	sortable: {
		'& li': {
			display: 'flex',
			alignItems: 'center',
			'& span.ion': {
				flex: '0 0 auto',
				padding: '11px 5px 11px',
				fontSize: '18px',
				width: '28px',
				cursor: 'default',
				'&.ion-drag.drag-handle': {
					cursor: 'ns-resize'
				},
				'&.ion-plus': {
					padding: '11px 7px 11px'
				},
				'&.blank': {
					padding: '11px 3px 11px'
				}
			},
			'& label': {
				marginTop: '9px',
				marginBottom: '9px'
			},
			'& *': {
				flex: '1 1 auto'
			}
		}
	}
});

export default (component) => injectSheet(styles)(component);
