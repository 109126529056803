import React from 'react'

import AuthorizedUser from './authorizedUser'
import UnauthorizedUser from './unauthorizedUser'

const CurrentUser = ({authorized, className, implicit, user, profile, transparent}) => {
	return (
		authorized ? (
			<AuthorizedUser
				className={className}
				user={user}
				profile={profile}
				transparent={transparent}
			/>
		) : (
			<UnauthorizedUser
				className={className}
				implicit={implicit}
				user={user}
				transparent={transparent}
			/>
		)
	);
};

export default CurrentUser;
