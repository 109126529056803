import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'

const getTitle = (title, titleTemplate) => {
	if (title && titleTemplate) {
		return titleTemplate.replace(/%s/, title);
	}
	return title || undefined;
};

const HelmetWrapper = ({title: titlePage, titleTemplate, description, author, keywords, image, canonical, children, ...props}) => {
	const title = getTitle(titlePage, titleTemplate);
	return <Helmet {...props}>
		{title && <title>{title}</title>}
		{description && <meta name="description" content={description}/>}
		{author && <meta name="author" content={author}/>}
		{keywords && <meta name="keywords" content={keywords}/>}

		{title && <meta name="og:title" content={title}/>}
		{description && <meta name="og:description" content={description}/>}
		{author && <meta name="og:site_name" content={author}/>}
		{image && <meta name="og:image" content={image}/>}

		{title && <meta name="twitter:title" content={title}/>}
		{description && <meta name="twitter:description" content={description}/>}
		{author && <meta name="twitter:site" content={author}/>}
		{image && <meta name="twitter:image" content={image}/>}

		{title && <meta itemProp="name" content={title}/>}
		{description && <meta itemProp="description" content={description}/>}
		{image && <meta itemProp="image" content={image}/>}

		{canonical && <link rel="canonical" href={canonical}/>}

		{children}
	</Helmet>;
};

HelmetWrapper.propTypes = {
	titleTemplate: PropTypes.string
};

HelmetWrapper.defaultProps = {
	titleTemplate: '%s | Prepler'
};

export default HelmetWrapper;
