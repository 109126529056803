import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'

import routesHelper from '@prepler/core/lib/routesHelper'
import {setDeviceStorage} from '@prepler/core/lib/actions/deviceStorageActions'
import {deviceStorageSelector} from '@prepler/core/lib/reducers/deviceStorageReducer'

import {cookies} from '../../utils/cookies';

const useStyles = createUseStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		'@media screen and (min-width: 345px)': {
			flexDirection: 'row',
		},
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
		padding: '28px 30px',
		opacity: '.97',
		boxShadow: '0 -8px 20px 0 rgba(0, 0, 0, .2)',
		backgroundColor: 'rgba(71, 81, 84, .95)',
		zIndex: 1100,
		'& p': {
			flex: 1,
			display: 'inline-block',
			color: theme.colors.white,
			fontSize: '14px',
			'@media screen and (min-width: 640px)': {
				fontSize: '19px',
			},
			fontWeight: theme.fontWeight.normal,
			lineHeight: '1.4',
			'& a': {
				color: theme.colors.white,
				textDecoration: 'underline',
				display: 'inline'
			}
		},
		'& $buttonContainer': {
			display: 'flex',
			alignItems: 'center'
		}
	},
	hidden: {
		display: 'none',
	},
	buttonContainer: {},
	close: {
		position: 'absolute',
		top: '0px',
		right: '4px',
		color: theme.colors.white,
		fontSize: '30px',
		cursor: 'pointer',
		'&:hover, &:focus': {
			textDecoration: 'none'
		}
	}
}));

const cookieKey = '_cookies_agree_at';

const CookiesPopup = () => {
	const classes = useStyles();
	const {cookiesAgreeAt} = useSelector((state) => deviceStorageSelector(state));
	const dispatch = useDispatch();

	const agree = () => {
		const now = Date.now();
		cookies.set(cookieKey, now, {maxAge: 10 * 60 * 60 * 24 * 365});
		dispatch(setDeviceStorage({cookiesAgreeAt: now}))
	};

	const cookieValue = cookies.get(cookieKey);
	useEffect(() => {
		cookiesAgreeAt && !cookieValue && agree();
	}, [cookiesAgreeAt, cookieValue]);

	return (
		<div className={classNames(classes.container, {[classes.hidden]: cookiesAgreeAt})} key={`${cookiesAgreeAt}-${cookieValue}`}>
			<p>
				This site uses cookies. By continuing to use this site, closing this banner, or clicking &quot;I Agree&quot;, you agree to the use of cookies. <br/>
				Read our <Link to={{pathname: routesHelper.cookiesPolicyPath()}} target="_blank" rel="noopener noreferrer">cookies policy</Link> and <Link to={{pathname: routesHelper.privacyPolicyPath()}} target="_blank" rel="noopener noreferrer">privacy statement</Link> for more information.
			</p>
			<div className={classes.buttonContainer}>
				<button className="btn btn-lg btn-primary" onClick={agree}>I agree</button>
			</div>
			<a className={classes.close} onClick={agree}>×</a>
		</div>
	);
};

export default CookiesPopup;
