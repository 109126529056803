import React from 'react'
import {Route, Link} from 'react-router-dom'
import injectSheet from 'react-jss'
import Helmet from '../common/helmet'
import Header from '../header'
import Footer from '../footer'
import Notifications from './notifications'
import Modals from './modals'
import CollegesOnPrepler from '../collections/collegesOnPrepler'
// import {preloadBase, preloadLearning, preloadUniversities} from '../../preload'
import UkraineBanner from './ukraineBanner'
import styles from './styles'

const newStyles = theme => ({
	...styles(theme),
	container: {
		maxWidth: '1290px',
		width: '93.71%',
		margin: '0 auto',
		'@media screen and (min-width: 992px)': {
			margin: '50px auto 0px',
		}
	}
});

class ArticleLayout extends React.Component {
	// componentDidMount() {
	// 	setTimeout(preloadBase, 7000);
	// 	setTimeout(preloadUniversities, 8000);
	// 	setTimeout(preloadLearning, 9000);
	// }

	render() {
		const {classes, hideFooter, backgroundColor, popular, component: Component, canonicalUrl, ...rest} = this.props;

		return <Route {...rest} render={matchProps => (
			<div className={classes.layout}>
				<Notifications/>
				{canonicalUrl && <Helmet canonical={canonicalUrl()}/>}

				<header className={classes.header}>
					<Header matchProps={matchProps} dark={false}/>
				</header>
				<UkraineBanner/>

				<main className={classes.mainWide} style={{backgroundColor}}>
					<Component {...matchProps} className={classes.container}/>
					<div style={{width: '100%', backgroundColor: '#fff'}}>
						<div className={classes.container}>
							<CollegesOnPrepler/>
						</div>
					</div>
				</main>

				{!hideFooter && <footer className={classes.footer}>
					<Footer/>
				</footer>}

				<Modals history={matchProps.history}/>
			</div>
		)}/>;
	}
}

export default injectSheet(newStyles)(ArticleLayout)
