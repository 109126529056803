const styles = theme => ({
	notifications: {
		position: 'fixed',
		zIndex: 35,
		textAlign: 'center',
		top: '5px',
		left: '80px',
		width: 'calc(100% - 180px)',
		'@media screen and (min-width: 992px)': {
			top: '20px',
			left: '140px',
			width: 'calc(100% - 500px)'
		},
		'@media screen and (min-width: 1430px)': {
			left: 'calc(50% - 575px)',
			width: '930px'
		},
		'& .alert': {
			padding: '0px 15px'
		},
		'& .alert-dismissable .close, & .alert-dismissible .close': {
			top: '6px',
			right: '0px'
		}
	}
});

export default styles
