import React from 'react'
import Alert from 'react-bootstrap/es/Alert'
// import capitalize from 'lodash-es/capitalize'
// <p>{title !== false && <b>{title || capitalize(type)}{message && ':'}</b> }{message}</p>

const Notification = ({type, title, message, children, ...rest}) => (
	<Alert {...rest} bsStyle={type === 'error' ? 'danger' : type}>
		{children ? (
			children
		) : (
			<p>{title && <b>{title}{message && ':'}</b>}{message}</p>
		)}
	</Alert>
);

export default Notification;
