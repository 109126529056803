import React from 'react'
import classNames from 'classnames'
import ModalBootsrap from 'react-bootstrap/es/Modal'
import injectSheet from 'react-jss'

const styles = theme => ({
	modal: {
		[`@media (min-width: ${theme.point.sm})`]: {
			'& .modal-dialog:not(.modal-sm):not(.modal-lg)': {
				width: '500px'
			}
		},
		'& .modal-body': {
			padding: '20px 30px'
		},
		'& .modal-header': {
			padding: '0px 5px',
			borderBottom: 'none',
			'& .close': {
				fontSize: '30px',
				fontWeight: theme.fontWeight.normal,
				opacity: 0.3,
				'&:hover': {
					opacity: 0.5
				}
			}
		},
		'& .modal-title': {
			textAlign: 'center',
			paddingTop: '20px',
			fontSize: '24px',
			fontWeight: theme.fontWeight.normal
		},
		'& .modal-footer': {
			padding: '0px 30px 20px',
			borderTop: 'none'
		}
	},
	'@global body.modal-open.ios-caret-fix .modal': {
		position: 'absolute',
		bottom: 'initial'
	},
	'@global body.modal-open.ios-caret-fix #root': {
		overflow: 'hidden'
	}
});

class ModalComponent extends React.Component {
	componentDidMount() {
		const ua = navigator.userAgent;
		const iOS = /iPad|iPhone|iPod/.test(ua);
		const iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);
		if (iOS && iOS11) {
			document.body.classList.add('ios-caret-fix');
		}
	}

	componentWillUnmount() {
		document.body.classList.remove('ios-caret-fix');
	}

	render() {
		const {classes, theme, className, ...props} = this.props;
		return <ModalBootsrap {...props} className={classNames(classes.modal, className)}/>;
	}
}

export const Modal = injectSheet(styles)(ModalComponent);

Modal.propTypes = ModalBootsrap.propTypes;
Modal.defaultProps = ModalBootsrap.defaultProps;
Modal.childContextTypes = ModalBootsrap.childContextTypes;

Modal.Body = ModalBootsrap.Body;
Modal.Header = ModalBootsrap.Header;
Modal.Title = ModalBootsrap.Title;
Modal.Footer = ModalBootsrap.Footer;

Modal.Dialog = ModalBootsrap.ModalDialog;

Modal.TRANSITION_DURATION = ModalBootsrap.TRANSITION_DURATION;
Modal.BACKDROP_TRANSITION_DURATION = ModalBootsrap.BACKDROP_TRANSITION_DURATION;

export default Modal;
