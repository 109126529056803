import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {CURRENT_USER} from '../queries/useCurrentUser'
import {MY_SCHOOLS} from '../../components/account/profileMySchools'

const UNIVERSITY_FAVORITE_MUTATION = gql`
	mutation universityFavoriteMutation(
		$id: ID!
	) {
		universityFavorite(
			id: $id
		) {
			id
			favorite
		}
	}
`;

const UNIVERSITY_UNFAVORITE_MUTATION = gql`
	mutation universityUnfavoriteMutation(
		$id: ID!
	) {
		universityUnfavorite(
			id: $id
		) {
			id
			favorite
		}
	}
`;

export const useToggleFavoriteUniversity = (favorite, id) => {
	const [mutationAction] = useMutation(
		favorite ? UNIVERSITY_UNFAVORITE_MUTATION : UNIVERSITY_FAVORITE_MUTATION,
		{
			variables: {id},
			refetchQueries: [
				// 'MySchools', 'CurrentUser'
				{query: CURRENT_USER},
				{query: MY_SCHOOLS},
			]
		}
	);
	return mutationAction;
}
