import React from 'react'
import routesHelper from '@prepler/core/lib/routesHelper'
import SuggestionsSection from './suggestionsSection'

const Suggestions = ({query, suggestions, setTitleAndMinimize, perSection, listRef}) => {
	return (
		<ul ref={listRef}>
			<SuggestionsSection
				query={query}
				suggestions={suggestions.schools.slice(0, perSection)}
				sectionTitle="Schools"
				moreTitle="Show all results in Schools"
				setTitle={setTitleAndMinimize}
				pathFn={routesHelper.universityPath}
				section="name"
			/>
			<SuggestionsSection
				query={query}
				suggestions={suggestions.majors.slice(0, perSection)}
				sectionTitle="Majors"
				moreTitle="Show all results in Majors"
				setTitle={setTitleAndMinimize}
				pathFn={routesHelper.searchMajorPath}
				section="major"
			/>
			<SuggestionsSection
				query={query}
				suggestions={suggestions.careers.slice(0, perSection)}
				sectionTitle="Careers"
				moreTitle="Show all results in Careers"
				setTitle={setTitleAndMinimize}
				pathFn={routesHelper.searchCareerPath}
				section="career"
			/>
			<SuggestionsSection
				query={query}
				suggestions={suggestions.locations.slice(0, perSection)}
				sectionTitle="Locations"
				moreTitle="Show all results in Locations"
				setTitle={setTitleAndMinimize}
				pathFn={routesHelper.searchLocationPath}
				section="location"
			/>
		</ul>
	);
};

export default Suggestions;
