import React from 'react'
import theme from '../../theme'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const styles = {
	heart: {
		position: 'absolute',
		top: '0px',
		left: '0px',
		'&:before': {
			fontWeight: theme.fontWeight.bold
		},
		'&:first-child:before': {
			fontWeight: theme.fontWeight.normal
		}
	}
};

const HeartIcon = ({fillColor, fillOpacity, stroke, strokeColor, size, style, classes, ...props}) =>
	<div {...props} style={{...style, height: size, width: size, fontSize: size, position: 'relative'}}>
		<i
			style={{color: fillColor, opacity: fillOpacity}}
			className={classNames(classes.heart, 'ion-ios-heart')}
		/>
		{stroke && <i
			style={{color: strokeColor}}
			className={classNames(classes.heart, 'ion-ios-heart-outline')}
		/>}
	</div>;

HeartIcon.defaultProps = {
	fillColor: theme.colors.gray[5],
	fillOpacity: '0.75',
	strokeColor: theme.colors.white,
	stroke: true,
	role: 'img',
	size: '20px'
};

export default injectSheet(styles)(HeartIcon);
