import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as AfterGrad } from '../assets/menu/afterGrad.svg'

export const active = {
	'& .c1': {
		fill: '#f6d9ff'
	},
	'& .c2, & .c4': {
		fill: '#9e6be8'
	},
	'& .c3': {
		fill: '#ffb600'
	},
	'& .c8': {
		stroke: '#ffb600'
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .c1': {
			fill: '#e6e6e6'
		},
		'& .c1, & .c7': {
			stroke: '#707070',
			strokeWidth: '2px'
		},
		'& .c1, & .c7, & .c8': {
			strokeLinecap: 'round',
			strokeLinejoin: 'round'
		},
		'& .c1, & .c2, & .c6, & .c7': {
			fillRule: 'evenodd'
		},
		'& .c2, & .c4': {
			fill: '#888'
		},
		'& .c3': {
			fill: '#b8b8b8'
		},
		'& .c5, & .c6': {
			fill: '#fff'
		},
		'& .c7, & .c8': {
			fill: 'none'
		},
		'& .c8': {
			stroke: '#b8b8b8'
		},
		'& $active, &:hover': {
			extend: [active]
		}
	},
	active: {}
}));

const IconAfterGrad = ({active, className, ...props}) => {
	const classes = useStyles();
	return <AfterGrad {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconAfterGrad.propTypes = {
	active: PropTypes.bool,
};

IconAfterGrad.defaultProps = {
	active: false
};

export default IconAfterGrad
