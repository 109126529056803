import React from 'react'
import gql from 'graphql-tag'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import routesHelper, {goalNewPath} from '@prepler/core/lib/routesHelper'

import Breadcrumbs from './breadcrumbs'
import FavoriteHeart from './favoriteHeart'
import Rating from '../common/rating'
import useStyles from './styles'

const UniversityHeader = (
	{
		hidden, top, show, section, university, university: {
			id, slug, name, city, reviewsMeta, stateFull
		}}
) => {
	const classes = useStyles();
	const authorized = useSelector(({auth: {authorized}}) => authorized);

	return (
		<div className={classNames(classes.universityHeaderContainer, {[classes.hidden]: hidden, [classes.top]: top, [classes.show]: show})}>
			<Breadcrumbs university={university} />
			<div className={classNames(classes.universityHeader, classes.row, classes.alignCenter, {[classes.hidden]: hidden})}>
				<div className={classes.grow}>
					<h1 className={classes.universityName}>{name} {section}</h1>
					<p>
						{city}, {stateFull}
						{reviewsMeta && reviewsMeta.total > 0 && (
							<>
								<span className={classes.bullet}>&bull;</span>
								<Rating value={reviewsMeta.avg} readOnly={true} small color="#eee">
									{' '}&nbsp;{reviewsMeta.total}&nbsp;reviews
								</Rating>
							</>
						)}
					</p>
				</div>
				<div>
					{!section && (
						<Link
							className={classNames(classes.createDegreePlan, 'btn', 'btn-success')}
							to={authorized ? goalNewPath({university_id: slug || id}) : routesHelper.goalsPath({hash: 'modalSignUp'})}
						>
							Create Your Degree Plan
						</Link>
					)}
				</div>
				<div>
					<FavoriteHeart university={university} />
				</div>
			</div>
		</div>
	);
};

UniversityHeader.fragments = {
	university: gql`
		fragment UniversityHeaderUniversity on University {
			id
			slug
			name
			city
			favorite
			reviewsMeta
			stateFull
			...BreadcrumbsUniversity
		}
		${Breadcrumbs.fragments.university}
	`,
};

export default UniversityHeader;
