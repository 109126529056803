import loadable from '@loadable/component'
import Loading from '../common/loading'

export const AdminRoutes = loadable(() => import(/* webpackChunkName: 'admin_routes' */'../../adminRoutes'), {fallback: Loading});

// export const Chat = loadable(() => import(/* webpackChunkName: 'chat' */'../chat'), {fallback: Loading});
export const Confirmation = loadable(() => import(/* webpackChunkName: 'confirmation' */'../confirmation/confirmation'), {fallback: Loading});
export const About = loadable(() => import(/* webpackChunkName: 'about' */'../staticPages/about'), {fallback: Loading});
export const PrivacyPolicy = loadable(() => import(/* webpackChunkName: 'privacy_policy' */'../policyTerms/privacyPolicy'), {fallback: Loading});
export const CookiesPolicy = loadable(() => import(/* webpackChunkName: 'cookies_policy' */'../policyTerms/cookiesPolicy'), {fallback: Loading});
export const TermsOfUse = loadable(() => import(/* webpackChunkName: 'terms_of_use' */'../policyTerms/termsOfUse'), {fallback: Loading});
export const ContactUs = loadable(() => import(/* webpackChunkName: 'contact_us' */'../contactUs'), {fallback: Loading});
export const Promo = loadable(() => import(/* webpackChunkName: 'promo' */'../staticPages/promo'), {fallback: Loading});
export const NotFound = loadable(() => import(/* webpackChunkName: 'not_found' */'../errors/notFound'), {fallback: Loading});
export const NotFoundRedirect = loadable(() => import(/* webpackChunkName: 'not_found_redirect' */'../errors/notFoundRedirect'), {fallback: Loading});
export const AccountPage = loadable(() => import(/* webpackChunkName: 'account_page' */'../account/accountPage'), {fallback: Loading});
export const ArticlesList = loadable(() => import(/* webpackChunkName: 'articles_list' */'../articles/list'), {fallback: Loading});
export const ArticleShow = loadable(() => import(/* webpackChunkName: 'article_show' */'../articles/show'), {fallback: Loading});
export const FreeMembership = loadable(() => import(/* webpackChunkName: 'free_membership' */'../freeMembership'), {fallback: Loading});
export const UniversityOverview = loadable(() => import(/* webpackChunkName: 'university_overview' */'../universities/universityOverview'), {fallback: Loading});
export const UniversityAdmission = loadable(() => import(/* webpackChunkName: 'university_admission' */'../universities/universityAdmission'), {fallback: Loading});
export const UniversityTuition = loadable(() => import(/* webpackChunkName: 'university_tuition' */'../universities/universityTuition'), {fallback: Loading});
export const UniversityLoanCalculator = loadable(() => import(/* webpackChunkName: 'university_loan_calculator' */'../universities/universityLoanCalculator'), {fallback: Loading});
export const UniversityAcademics = loadable(() => import(/* webpackChunkName: 'university_academics' */'../universities/universityAcademics'), {fallback: Loading});
export const UniversityStudentLife = loadable(() => import(/* webpackChunkName: 'university_student_life' */'../universities/universityStudentLife'), {fallback: Loading});
export const UniversityAfterGraduation = loadable(() => import(/* webpackChunkName: 'university_after_graduation' */'../universities/universityAfterGraduation'), {fallback: Loading});
export const UniversityReviews = loadable(() => import(/* webpackChunkName: 'university_reviews' */'../universities/universityReviews'), {fallback: Loading});
export const UniversityReviewNew = loadable(() => import(/* webpackChunkName: 'university_review_new' */'../universities/universityReviews/new'), {fallback: Loading});
export const GpaCalculator = loadable(() => import(/* webpackChunkName: 'gpa_calculator' */'../gpaCalculator'), {fallback: Loading});
export const LoanCalculator = loadable(() => import(/* webpackChunkName: 'loan_calculator' */'../loanCalculator'), {fallback: Loading});
export const ForStudents = loadable(() => import(/* webpackChunkName: 'for_students' */'../forStudents'), {fallback: Loading});
export const ForParents = loadable(() => import(/* webpackChunkName: 'for_students' */'../forParents'), {fallback: Loading});
export const ForPartners = loadable(() => import(/* webpackChunkName: 'for_partner' */'../forPartners'), {fallback: Loading});
export const UniversityGpaCalculator = loadable(() => import(/* webpackChunkName: 'university_gpa_calculator' */'../universities/universityGpaCalculator'), {fallback: Loading});
export const UniversityCourses = loadable(() => import(/* webpackChunkName: 'university_courses' */'../universities/universityCourses'), {fallback: Loading});
export const Rankings = loadable(() => import(/* webpackChunkName: 'rankings' */'../rankings'), {fallback: Loading});
export const SportsList = loadable(() => import(/* webpackChunkName: 'sports_list' */'../sports/list'), {fallback: Loading});
export const SportsItem = loadable(() => import(/* webpackChunkName: 'sports_item' */'../sports/item'), {fallback: Loading});
// Learning
export const Goals = loadable(() => import(/* webpackChunkName: 'goals' */'../learning/goals'), {fallback: Loading});
export const GoalShow = loadable(() => import(/* webpackChunkName: 'goal_show' */'../learning/goals/show'), {fallback: Loading});
export const GoalNew = loadable(() => import(/* webpackChunkName: 'goal_new' */'../learning/goals/new'), {fallback: Loading});
export const GoalDuplicate = loadable(() => import(/* webpackChunkName: 'goal_duplicate' */'../learning/goals/duplicate'), {fallback: Loading});
export const Vote = loadable(() => import(/* webpackChunkName: 'votePage' */'../learning/goals/votePage'), {fallback: Loading});
export const ChatGPTConnect = loadable(() => import(/* webpackChunkName: 'votePage' */'../chatGPT/connect'), {fallback: Loading});
