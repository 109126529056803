// Override StaticRouter from import {StaticRouter} from 'react-router' to use wrapHistory
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/StaticRouter.js
import React from 'react'
import { createLocation, createPath } from 'history'
import invariant from 'tiny-invariant'

import {Router} from 'react-router'
import wrapHistory from './wrapHistory'

const addLeadingSlash = (path) => path.charAt(0) === '/' ? path : '/' + path;
const addBasename = (basename, location) => {
	if (!basename) return location;

	return {
		...location,
		pathname: addLeadingSlash(basename) + location.pathname
	};
};

const stripBasename = (basename, location) => {
	if (!basename) return location;

	const base = addLeadingSlash(basename);

	if (location.pathname.indexOf(base) !== 0) return location;

	return {
		...location,
		pathname: location.pathname.substr(base.length)
	};
};

const createURL = (location) => typeof location === 'string' ? location : createPath(location);

const staticHandler = (methodName) => () => invariant(false, 'You cannot %s with <StaticRouter>', methodName);

const noop = () => {};

/**
 * The public top-level API for a "static" <Router>, so-called because it
 * can't actually change the current location. Instead, it just records
 * location changes in a context object. Useful mainly in testing and
 * server-rendering scenarios.
 */
class StaticRouter extends React.Component {
	navigateTo(location, action) {
		const { basename = '', context = {} } = this.props;
		context.action = action;
		context.location = addBasename(basename, createLocation(location));
		context.url = createURL(context.location);
	}

	handlePush = location => this.navigateTo(location, 'PUSH');
	handleReplace = location => this.navigateTo(location, 'REPLACE');
	handleListen = () => noop;
	handleBlock = () => noop;

	render() {
		const { basename = '', context = {}, location = '/', ...rest } = this.props;

		const history = {
			createHref: path => addLeadingSlash(basename + createURL(path)),
			action: 'POP',
			location: stripBasename(basename, createLocation(location)),
			push: this.handlePush,
			replace: this.handleReplace,
			go: staticHandler('go'),
			goBack: staticHandler('goBack'),
			goForward: staticHandler('goForward'),
			listen: this.handleListen,
			block: this.handleBlock
		};

		return <Router {...rest} history={wrapHistory(history)} staticContext={context} />;
	}
}

export default StaticRouter;
