import React from 'react'
import {Route} from 'react-router-dom'
import injectSheet from 'react-jss'

import App from '@prepler/core/lib/app'

import Helmet from '../common/helmet'
import Header from '../header'
import Footer from '../footer'
import Notifications from './notifications'
import Modals from './modals'
// import {preloadBase, preloadLearning, preloadUniversities} from '../../preload'
import UkraineBanner from './ukraineBanner'
import styles from './styles'

class ApplicationLayout extends React.Component {
	// componentDidMount() {
	// 	setTimeout(preloadBase, 7000);
	// 	setTimeout(preloadUniversities, 8000);
	// 	setTimeout(preloadLearning, 9000);
	// }

	render() {
		const {classes, wide, hideFooter, backgroundColor, component: Component, canonicalUrl, ...rest} = this.props;

		return <Route {...rest} render={matchProps => (
			(App.history = matchProps.history) &&
			<div className={classes.layout}>
				{canonicalUrl && <Helmet canonical={canonicalUrl()}/>}
				<Notifications />

				<header className={classes.header}>
					<Header matchProps={matchProps} dark={false}/>
				</header>
				<UkraineBanner/>

				<main className={wide ? classes.mainWide : classes.main} style={{backgroundColor}}>
					<Component {...matchProps} />
				</main>

				{!hideFooter && <footer className={classes.footer}>
					<Footer/>
				</footer>}

				<Modals history={matchProps.history}/>
			</div>
		)} />;
	}
}

export default injectSheet(styles)(ApplicationLayout)
