import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as Gpa } from '../assets/menu/gpa.svg'

export const active = {
	'& .st0': {
		fill: '#04776C',
	},
	'& .st1': {
		fill: '#019587',
	},
	'& .st3': {
		fill: '#B8CE17',
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .st0': {
			fill: '#787878',
			stroke: '#707070',
			strokeWidth: '2',
			strokeMiterlimit: '10',
		},
		'& .st1': {
			fill: '#949494',
			stroke: '#FFFFFF',
			strokeWidth: '2',
			strokeMiterlimit: '10',
		},
		'& .st2': {
			fill: '#FFFFFF',
		},
		'& .st3': {
			fill: '#cfcfcf',
		},
		'& .st4': {
			fill: '#707070',
		},
		'& .st5': {
			fill: 'none',
			stroke: '#FFFFFF',
			strokeWidth: '0.5',
			strokeMiterlimit: '10',
		},
		'& .st6': {
			fill: 'none',
			stroke: '#FFFFFF',
			strokeLinecap: 'round',
			strokeMiterlimit: '10',
		},
		'& $active, &:hover': {
			extend: [active]
		}
	},
	active: {},
}));

const IconLoan = ({active, className, ...props}) => {
	const classes = useStyles();
	return <Gpa {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconLoan.propTypes = {
	active: PropTypes.bool,
};

IconLoan.defaultProps = {
	active: false
};

export default IconLoan
