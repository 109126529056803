import React from 'react'
import PropTypes from 'prop-types'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import { ReactComponent as Reviews } from '../assets/menu/reviews.svg'

export const active = {
	'& .c2': {
		fill: '#7d9ff4'
	},
	'& .c4': {
		fill: '#00bc00'
	},
	'& .c6': {
		fill: '#ff871c'
	}
};

const useStyles = createUseStyles((theme) => ({
	icon: {
		'& .c1, & .c3': {
			fill: 'none'
		},
		'& .c1': {
			stroke: '#707070',
			strokeWidth: '2px'
		},
		'& .c1, & .c3, & .c4': {
			strokeLinecap: 'round'
		},
		'& .c1, & .c4': {
			strokeLinejoin: 'round'
		},
		'& .c1, & .c2, & .c3, & .c4, & .c6': {
			fillRule: 'evenodd'
		},
		'& .c2': {
			fill: '#9e9e9e'
		},
		'& .c3, & .c4': {
			stroke: '#fff',
			strokeWidth: '3px'
		},
		'& .c3': {
			strokeMiterlimit: '10'
		},
		'& .c4': {
			fill: '#6f6f6f'
		},
		'& .c5': {
			fill: '#fff'
		},
		'& .c6': {
			fill: '#9f9f9f'
		},
		'& $active, &:hover': {
			extend: [active]
		}
	},
	active: {},
}));

const IconReviews = ({active, className, ...props}) => {
	const classes = useStyles();
	return <Reviews {...props} className={classNames(classes.icon, className, {[classes.active]: active})}/>;
};

IconReviews.propTypes = {
	active: PropTypes.bool,
};

IconReviews.defaultProps = {
	active: false
};

export default IconReviews
