import React from 'react'
import RequireAuth from '../common/requireAuth'

import {
	Error, SignInUp, ConfirmationCreate, ConfirmationSent, Password, PasswordSent, PasswordEdit, Social, HowItWorks, BecomePartner,
	// Admin Namespace
	CollectionNew, CollectionEdit, PolicyTermNew, PolicyTermEdit, UserEdit, UserNew, UserProfile, TariffModelNew,
	TariffModelEdit, CourseNew, CourseEdit, CalculationDetails, UniversityBrochure, UniversityPhotoNew, MajorBrochure,
	// Learning
	GoalIntro, GoalInfo, GoalRename, GoalActionRequired, GoalShare, GoalModalNew, Vote, AddCourse, SubstituteCourse,
	PeriodEdit, PeriodCourseDetails, PeriodCourseConfirm, PeriodCourseMark, PeriodCourseUnits, MyCourseNew, MyCourseEdit, MyCourseDetails, MyCourseMark
} from '../loadable/modals'

class Modals extends React.Component {
	handleClose() {
		const {history: {location, push}} = this.props;
		push(location.pathname + location.search);
	}

	params(modal) {
		return {modal: modal};
	}

	render() {
		const {history: {location, push}} = this.props;
		let components = {
			Error,
			SignIn: SignInUp,
			SignUp: SignInUp,
			ConfirmationCreate,
			ConfirmationSent,
			Password,
			PasswordSent,
			PasswordEdit,
			Social,
			HowItWorks,
			BecomePartner,
			RequestDemo: BecomePartner,
			// Admin namespace
			CollectionNew: RequireAuth(CollectionNew, 'admin'),
			CollectionEdit: RequireAuth(CollectionEdit, 'admin'),
			PolicyTermNew: RequireAuth(PolicyTermNew, 'admin'),
			PolicyTermEdit: RequireAuth(PolicyTermEdit, 'admin'),
			UserNew: RequireAuth(UserNew, 'admin'),
			UserEdit: RequireAuth(UserEdit, 'admin'),
			UserProfile: RequireAuth(UserProfile, 'admin'),
			TariffModelNew: RequireAuth(TariffModelNew, 'admin'),
			TariffModelEdit: RequireAuth(TariffModelEdit, 'admin'),
			CourseNew: RequireAuth(CourseNew, ['admin', 'operator']),
		  CourseEdit: RequireAuth(CourseEdit, ['admin', 'operator']),
			CalculationDetails: RequireAuth(CalculationDetails, ['admin', 'operator']),
			// Learning
			DegreePlan: GoalInfo,
			DegreePlanIntro: GoalIntro,
			DegreePlanRename: GoalRename,
			DegreePlanActionRequired: GoalActionRequired,
			DegreePlanShare: GoalShare,
			DegreePlanNew: GoalModalNew,
			Vote,
			AddCourse,
			SubstituteCourse,
			PeriodEdit,
			PeriodCourseDetails,
			PeriodCourseConfirm,
			PeriodCourseMark,
			PeriodCourseUnits,
			MyCourseNew,
			MyCourseEdit,
			MyCourseDetails,
			MyCourseMark,
			UniversityBrochure,
			UniversityPhotoNew,
			MajorBrochure
		};
		let modal = (location.state || {}).modal;
		let id = null;
		if (modal) {
			[, modal, id] = modal.match(/([^.]+)\.?(:?[^.]*)/);
		} else if (location.hash.match(/^#modal([^.]+)\.?(:?[^.]*)/)) {
			[, modal, id] = location.hash.match(/^#modal([^.]+)\.?(:?[^.]*)/);
		}
		return modal && components[modal] ? React.createElement(components[modal], {
			close: this.handleClose.bind(this),
			push,
			id,
			...location.state,
			...this.params(modal)
		}) : null;
	}
}

export default Modals
