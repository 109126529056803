import React, {useState, useEffect, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {createUseStyles} from 'react-jss'

import {goalNewPath} from '@prepler/core/lib/routesHelper'
import {setDeviceStorage} from '@prepler/core/lib/actions/deviceStorageActions'
import {deviceStorageSelector} from '@prepler/core/lib/reducers/deviceStorageReducer'

import {cookies} from '../../../utils/cookies'
import {Modal} from '../../common/modal'

import pic from '../../../assets/images/goals/promotion.png'
import pic2x from '../../../assets/images/goals/promotionx@2x.png'

const useStyles = createUseStyles((theme) => ({
	picture: {
		textAlign: 'center',
		width: '100%',
		'& > img': {
			width: '100%',
			maxWidth: '550px'
		},
	},
	footer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '88px',
		justifyContent: 'space-between'
	},
	modal: {
		[`@media (min-width: ${theme.point.sm})`]: {
			width: '600px !important'
		}
	}
}));

const cookieKey = '_calculator_promotion_closed_at';
const isValid = (value) => value && Math.round((Date.now() - parseInt(value)) / 1000) <= 60 * 60 * 24;

const CalculatorPromotion = (props) => {
	const [show, setShow] = useState(false);
	const {calculatorPromotionClosedAt} = useSelector((state) => deviceStorageSelector(state));
	const dispatch = useDispatch();
	const agree = useCallback(() => {
		const now = Date.now();
		cookies.set(cookieKey, now, {maxAge: 10 * 60 * 60 * 24 * 365});
		dispatch(setDeviceStorage({calculatorPromotionClosedAt: now}))
	}, [dispatch]);
	const cookieValue = cookies.get(cookieKey);
	useEffect(() => {isValid(calculatorPromotionClosedAt) && !isValid(cookieValue) && agree();}, [calculatorPromotionClosedAt, cookieValue]);
	useEffect(() => {!isValid(calculatorPromotionClosedAt) && setTimeout(() => setShow(true), 3000);}, []);
	const classes = useStyles();
	return <Modal show={show && !isValid(calculatorPromotionClosedAt)} onHide={agree} key={`${calculatorPromotionClosedAt}-${cookieValue}`} dialogClassName={classes.modal}>
		<Modal.Header closeButton>
			<Modal.Title>
				Try out a degree planner tool without&nbsp;registration
			</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className={classes.picture}>
				<img alt="Degree Plans" src={pic} srcSet={`${pic2x} 2x`}/>
			</div>
			<div className={classes.footer}>
				<Link to={goalNewPath()} className={classNames('btn', 'btn-lg', 'btn-success')} onClick={agree}>
					Create a Degree Plan
				</Link>
				<span>Free for students</span>
			</div>
		</Modal.Body>
	</Modal>;
};

export default CalculatorPromotion;
