import React from 'react'

const GraphqlError = (error) => {
	if (!error) return (
		<pre>
			Error!
		</pre>
	);

	const {error: {networkError, message}} = error;
	return (
		<pre>
			Error!
			<br/>
			{networkError && networkError.result ? networkError.result.error.message : message}
		</pre>
	);
};

export default GraphqlError;
