import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import routesHelper, {bestUniversitiesPath, goalNewPath} from '@prepler/core/lib/routesHelper'
// import medium from '../../assets/images/footer/medium.svg'
import {ReactComponent as IconTwitter} from '../../assets/images/footer/twitter.svg'
import {ReactComponent as IconLinkedin} from '../../assets/images/footer/linkedin.svg'
import {ReactComponent as IconFacebook} from '../../assets/images/footer/facebook.svg'
import {ReactComponent as IconPinterest} from '../../assets/images/footer/pinterest.svg'
import {ReactComponent as IconInstagram} from '../../assets/images/footer/instagram.svg'
import {ReactComponent as IconYoutube} from '../../assets/images/footer/youtube.svg'
import logoText from '../../assets/images/logo-white.svg'
import useStyles from './style'
import Helmet from '../common/helmet';

const twitterUrl = 'https://twitter.com/prepler';
const linkedinUrl = 'https://www.linkedin.com/company/prepler/';
const facebookUrl = 'https://www.facebook.com/prepler';
const pinterestUrl = 'https://www.pinterest.com/prepler/';
const instagramUrl = 'https://www.instagram.com/_prepler_/';
const youtubeUrl = 'https://www.youtube.com/channel/UC9BJpIjf81YIttD4_DRxdaA';

const Footer = () => {
	const classes = useStyles();
	const authorized = useSelector(({auth: {authorized}}) => authorized);

	const linkingData = {
		'@context': 'http://schema.org',
		'@type': 'WebSite',
		'name': 'Prepler',
		'sameAs': [
			twitterUrl,
			linkedinUrl,
			facebookUrl,
			pinterestUrl,
			instagramUrl,
			youtubeUrl
		],
		'url': 'https://www.prepler.com/'
	};

	return (
		<div className={classNames(classes.footer)}>
			<div className={classes.columns}>
				<div className={classes.talk}>
					Can Prepler help your campus?{' '}
					<Link to={routesHelper.demoPath()}>
						Let’s talk.
					</Link>
				</div>
				<div className={classes.logo}>
					<img src={logoText} alt="Prepler"/>
				</div>
			</div>
			<div className={classes.columns}>
				<div className={classes.links}>
					<Link to={routesHelper.aboutPath()}>About Prepler</Link>
					<Link to={routesHelper.partnerPath()}>Become a Partner</Link>
					<Link to={routesHelper.forStudentsPath()}>For Students</Link>
					<Link to={routesHelper.forParentsPath()}>For Parents</Link>
					<Link to={routesHelper.searchPath()}>Colleges</Link>
					<Link to={authorized ? goalNewPath() : routesHelper.goalsPath()}>Degree Planner</Link>
					<Link to={routesHelper.gpaCalculatorPath()}>College GPA Calculator</Link>
					<Link to={routesHelper.loanCalculatorPath()}>Student Loan Calculator</Link>
					<Link to={routesHelper.rankingsPath()}>College Ranking</Link>
					<Link to={routesHelper.privacyPolicyPath()}>Privacy Policy</Link>
					<Link to={routesHelper.termsOfUsePath()}>Terms of Use</Link>
					<Link to={routesHelper.contactUsPath()}>Careers</Link>
					<Link to={routesHelper.articlesPath()}>Blog</Link>
					{/* <Link to="https://www.dropbox.com/sh/jnux673ybdkt0xl/AAB5gXjXtj_WEunVBoijSX-oa?dl=0" target="_blank" rel="noopener noreferrer">Media Kit</Link>*/}
					<Link to={routesHelper.contactUsPath()}>Contact Us</Link>
					<Helmet>
						<script type="application/ld+json">
							{JSON.stringify(linkingData)}
						</script>
					</Helmet>
					<div className={classes.logos}>
						{/* <a href="" target="_blank" rel="noopener noreferrer"><img alt="medium" src={medium}/></a>*/}
						<a href={twitterUrl} target="_blank" rel="noopener noreferrer">
							<IconTwitter/>
						</a>
						<a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
							<IconLinkedin/>
						</a>
						<a href={facebookUrl} target="_blank" rel="noopener noreferrer">
							<IconFacebook/>
						</a>
						<a href={pinterestUrl} target="_blank" rel="noopener noreferrer">
							<IconPinterest/>
						</a>
						<a href={instagramUrl} target="_blank" rel="noopener noreferrer">
							<IconInstagram/>
						</a>
						<a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
							<IconYoutube/>
						</a>
					</div>
				</div>
				<div className={classes.links}>
					<Link to={bestUniversitiesPath()}>Best Colleges in America</Link>
					<Link to={bestUniversitiesPath({bestSection: 'liberal-arts-colleges'})}>Best Liberal Arts Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'private-colleges'})}>Best Private Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'for-profit-colleges'})}>Best For-Profit Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'public-4-year-colleges'})}>Best Public Four Year Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'public-colleges'})}>Best Public Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: '4-year-colleges'})}>Best Four Year Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'community-colleges'})}>Top Community Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'junior-colleges'})}>Top Junior Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'religious-colleges'})}>Top Religious Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'catholic-colleges'})}>Best Catholic Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'online-colleges'})}>Best Online Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'womens-colleges'})}>Best Women's Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'small-colleges'})}>Best Small Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'big-colleges'})}>Best Big Colleges</Link>
					<Link to={bestUniversitiesPath({bestSection: 'colleges-with-no-application-fee'})}>Best Colleges with No Application Fee</Link>
					<Link to={bestUniversitiesPath({bestSection: 'hbcu-colleges'})}>Best HBCU Schools</Link>
				</div>
				<div className={classes.links}>
					<Link to={bestUniversitiesPath({state: 'california'})}>Best Colleges in California</Link>
					<Link to={bestUniversitiesPath({state: 'florida'})}>Best Colleges in Florida</Link>
					<Link to={bestUniversitiesPath({state: 'texas'})}>Best Colleges in Texas</Link>
					<Link to={bestUniversitiesPath({state: 'new-york'})}>Best Colleges in New York</Link>
					<Link to={bestUniversitiesPath({state: 'georgia'})}>Best Colleges in Georgia</Link>
					<Link to={bestUniversitiesPath({state: 'virginia'})}>Best Colleges in Virginia</Link>
					<Link to={bestUniversitiesPath({state: 'new-jersey'})}>Best Colleges in New Jersey</Link>
					<Link to={bestUniversitiesPath({state: 'north-carolina'})}>Best Colleges in North Carolina</Link>
					<Link to={bestUniversitiesPath({state: 'pennsylvania'})}>Best Colleges in Pennsylvania</Link>
					<Link to={bestUniversitiesPath({state: 'colorado'})}>Best Colleges in Colorado</Link>
					<Link to={bestUniversitiesPath({state: 'ohio'})}>Best Colleges in Ohio</Link>
					<Link to={bestUniversitiesPath({state: 'illinois'})}>Best Colleges in Illinois</Link>
					<Link to={bestUniversitiesPath({state: 'massachusetts'})}>Best Colleges in Massachusetts</Link>
					<Link to={bestUniversitiesPath({state: 'maryland'})}>Best Colleges in Maryland</Link>
					<Link to={bestUniversitiesPath({state: 'michigan'})}>Best Colleges in Michigan</Link>
					<Link to={bestUniversitiesPath({state: 'south-carolina'})}>Best Colleges in South Carolina</Link>
					<Link to={bestUniversitiesPath({state: 'minnesota'})}>Best Colleges in Minnesota</Link>
				</div>
				<div className={classes.links}>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Engineering'})}>
						Best Engineering Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Information Technology'})}>
						Best Information Technology Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Nursing'})}>
						Best Nursing Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Music'})}>
						Best Music Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Political Science'})}>
						Best Political Science Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Architecture'})}>
						Top Architecture Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Psychology'})}>
						Best Psychology Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Art'})}>
						Best Art Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Photography'})}>
						Best Photography Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Graphic Design'})}>
						Best Graphic Design Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Computer Science'})}>
						Best Computer Science Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Criminal Justice'})}>
						Best Criminal Justice Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Communications'})}>
						Best Colleges for Communications
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Economics'})}>
						Best Colleges for Economics
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Biology'})}>
						Best Biology Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Performing Arts'})}>
						Best Performing Arts Colleges
					</Link>
					<Link to={bestUniversitiesPath({bestSection: 'major', rating_major_query: 'Chemistry'})}>
						Best Chemistry Colleges
					</Link>
				</div>
			</div>
			<div className={classNames(classes.fineText)}>
				<p>
					To help you, we use Machine Learning and Artificial Intelligence (AI) to analyze and process college and
					course
					data. All college data is sourced from the U.S. Department of Education National Center for Education
					Statistics
					survey. All course data is sourced directly from our partner Colleges and Universities.
					College reviews are based on the compilation of hundreds of college guidebooks and millions of online reviews.
				</p>
				<p>
					All personalized degree pathway calculations are based on the school's course catalog data,
					department degree requirements and general academic advising.
				</p>
			</div>
			<div className={classNames(classes.copy)}>
				<p>
					By accessing and using this page you agree to the Terms and Conditions.
				</p>
				<p>
					© {(new Date()).getFullYear()} Prepler.com. All rights reserved.
				</p>
			</div>
		</div>
	);
};

export default Footer;
