import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {createUseStyles} from 'react-jss'

import GraphqlError from '../dev/graphqlError'
import Loader from '../common/loader'
// import ScrollLoader from '../common/scrollLoader'
import SchoolItem from '../search/schoolItem'
// import SortBy from '../common/sortBy'
import {styles} from './profileStyles'

const useStyles = createUseStyles(styles);

const ProfileMySchoolsPresentation = ({universities, meta, filters, setSort, load}) => {
	const classes = useStyles();
	const emptyList = (meta.total_count === 0) || (meta.total_count > 0 && universities && universities.length === 0);

	return (
		<div className={classes.accountSection}>
			{emptyList ? (
				<p className={classes.accountSectionTitle}>Add favorite schools to start comparing</p>
			) : (
				<>
					{/*{universities.length > 0 && (*/}
					{/*	<SortBy className={classes.sortBy} value={filters.sort_by} onChange={setSort}/>*/}
					{/*)}*/}
					{universities.map((university) => (
						<SchoolItem key={university.id} university={university}/>
					))}
				</>
			)}
			{/*<ScrollLoader*/}
			{/*	meta={meta} id={filters.timestamp || Date.now()}*/}
			{/*	load={load}*/}
			{/*/>*/}
		</div>
	);
}

export const MY_SCHOOLS = gql`
	query MySchools {
		currentUser {
			id
			universities {
				id
				slug
				...SchoolItemUniversity
			}
		}
	}
	${SchoolItem.fragments.university}
`;

const ProfileMySchools = () => {
	const {loading, error, data} = useQuery(MY_SCHOOLS);

	if (loading) return <Loader/>;
	if (error) return <GraphqlError error={error}/>;

	const {currentUser: {universities}} = data;

	return (
		<ProfileMySchoolsPresentation
			universities={universities}
			meta={{total_count: 42}}
			filters={{sort_by: 'relevance'}}
			// setSort={setFavoriteUniversitySort}
			load={() => {}}
			// load={(params) => loadFavoriteUniversities({
			// 	...filters, ...params,
			// 	timestamp: undefined,
			// 	cancelToken: (cancel) => this.cancel = cancel
			// })}
		/>
	);
}

export default ProfileMySchools;
