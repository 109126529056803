import {useEffect, useRef} from 'react'

const useHandleOutsideClick = (handler) => {
	const ref = useRef();

	useEffect(() => {
		const handleClickOutside = ({target}) => ref.current && !ref.current.contains(target) && handler();

		document.querySelector('body').addEventListener('mousedown', handleClickOutside);

		return () => (
			document.querySelector('body').removeEventListener('mousedown', handleClickOutside)
		);
	}, [handler, ref]);

	return ref;
};

export default useHandleOutsideClick;
