import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import routesHelper from '@prepler/core/lib/routesHelper'
import logo from '../../assets/images/logo.svg'
import logoWhite from '../../assets/images/logo-white.svg'
import logoSmall from '../../assets/images/logo-small.png'

const useStyles = createUseStyles(theme => ({
	logo: {
		display: 'block',
		height: '100%',
		width: '72px',
		'@media screen and (min-width: 410px)': {
			width: '96px',
		},
		background: {
			color: 'transparent',
			image: `url(${logo})`,
			repeat: 'no-repeat',
			position: ['center', 'center'],
			size: '100%',
		},
		'&$transparent': {
			backgroundImage: `url(${logoWhite})`
		},
	},
	smallLogo: {
		width: '32px',
		height: '32px',
		background: {
			image: `url(${logoSmall})`,
			size: '32px 32px',
		}
	},
	transparent: {}
}));

const LogoLink = ({transparent, smallLogo}) => {
	const classes = useStyles();
	const {pathname} = useLocation();

	return pathname === '/' ? (
		<div
			className={classNames(classes.logo, {
				[classes.transparent]: transparent,
				[classes.smallLogo]: smallLogo
			})}
		/>
	) : (
		<Link
			to={routesHelper.rootPath()}
			className={classNames(classes.logo, {
				[classes.transparent]: transparent,
				[classes.smallLogo]: smallLogo
			})}
		/>
	);
};

export default LogoLink;
