import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import GraphqlError from '../dev/graphqlError'
import Collection from './collection'

const COLLEGES_ON_PREPLER = (type) => gql`
	query CollegesOnPrepler($slug: String!) {
		collegesOnPrepler: collection(slug: $slug) {
			id
			slug
			name
			universities {
				id
				...CollectionUniversity
			}
		}
	}
	${Collection.fragments.university(type)}
`;

const CollegesOnPrepler = ({type = 'partners'}) => {
	const {loading, error, data} = useQuery(COLLEGES_ON_PREPLER(type), {variables: {
		slug: 'colleges-on-prepler'
	}, context: {authPublic: true}}); // parters, aside only

	if (loading) return <Collection key="empty" type={type} collection={{universities: []}}/>;
	if (error) return <GraphqlError error={error}/>;

	const {collegesOnPrepler: collection} = data;

	return (
		<Collection type={type} showDescription={false} collection={collection}/>
	);
};

export default CollegesOnPrepler;
