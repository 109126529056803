import {createUseStyles} from 'react-jss'
import {largeHeight} from '../header/style'

const useStyles = createUseStyles(theme => ({
	container: {
		position: 'relative',
		boxShadow: '0px 8px 8px #00000030',
		borderRadius: '10px 10px 0px 0px',
		minHeight: '910px',
		paddingBottom: '60px',
		backgroundColor: theme.colors.background,
	},
	universityHeaderContainer: {
		margin: '0 -10px',
		position: 'sticky',
		backgroundColor: theme.colors.background,
		display: 'none',
		'&$show': {
			display: 'block',
		},
		padding: '0px 10px 0',
		'@media screen and (min-width: 768px)': {
			display: 'block',
		},
		'@media screen and (min-width: 992px)': {
			top: `${largeHeight + 1}px`,
		},
		'@media screen and (min-width: 1200px)': {
			top: `${largeHeight + 11}px`,
		},
		zIndex: '4',
		'&$hidden': {
			zIndex: '-1',
			'& > ul': {
				display: 'none',
			},
		},
		'&$top': {
			zIndex: '1',
			'& > * > *': {
				opacity: '0',
			},
		},
		'& h1': {
			textAlign: 'initial',
			color: '#fff',
			fontWeight: theme.fontWeight.normal,
			lineHeight: '38px',
			padding: '6px 0 0',
		},
		'& p': {
			padding: '2px 0 0',
			fontSize: '12px',
			'@media screen and (min-width: 768px)': {
				fontSize: '14px',
			},
		},
	},
	createDegreePlan: {
		fontWeight: theme.fontWeight.bold,
		backgroundColor: '#19bd19',
		display: 'inline-block',
		fontSize: '12px',
		'@media screen and (min-width: 768px)': {
			fontSize: '16px',
		},
	},
	show: {},
	top: {},
	hidden: {},
	universityHeader: {
		height: '126px',
		padding: '10px 20px 10px 20px',
		'@media screen and (min-width: 420px)': {
			height: '107px',
		},
		'@media screen and (min-width: 530px)': {
			height: '88px',
		},
		'@media screen and (min-width: 768px)': {
			padding: '10px 30px 10px 60px',
		},
		backgroundColor: '#123456',
		color: '#fff',
		boxShadow: '0px 2px 10px #00000048',
		borderRadius: '10px 10px 0px 0px',
		overflow: 'visible',
		'&$hidden': {
			overflow: 'hidden',
			backgroundColor: 'transparent',
		},
	},
	universityName: {
		fontSize: '18px',
		'@media screen and (min-width: 768px)': {
			fontSize: '22px',
		},
		'@media screen and (min-width: 992px)': {
			fontSize: '26px',
		},
		'@media screen and (min-width: 1200px)': {
			fontSize: '28px',
		},
	},
	heart: {
		position: 'absolute',
		top: '15px',
		right: '15px',
		'@media screen and (min-width: 992px)': {
			top: '20px',
			right: '20px',
		},
		'@media screen and (min-width: 1200px)': {
			top: '40px',
			right: '40px',
		},
	},
	image: {
		position: 'relative',
		backgroundColor: '#123456',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		boxShadow: '0px 4px 8px #00000020',
		borderRadius: '10px 10px 0px 0px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		zIndex: '2',
		height: '200px',
		'@media screen and (min-width: 768px)': {
			marginTop: '-88px',
		},
		'@media screen and (min-width: 992px)': {
			height: '340px',
		},
		'@media screen and (min-width: 1200px)': {
			height: '400px',
		},
	},
	overviewHeader: {
		backgroundColor: '#123456',
		alignItems: 'center',
	},
	logo: {
		display: 'none',
		zIndex: '2',
		overflow: 'hidden',
		'& > img': {
			maxWidth: '100%',
		},
		'@media screen and (min-width: 768px)': {
			display: 'flex',
			flexShrink: '0',
			backgroundColor: '#fff',
			borderRadius: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			color: '#3e8bca',
			fontSize: '50px',
			width: '80px',
			height: '80px',
			margin: '-30px 20px 5px 20px',
		},
		'@media screen and (min-width: 992px)': {
			fontSize: '60px',
			width: '100px',
			height: '100px',
			margin: '-40px 20px 5px 30px',
		},
		'@media screen and (min-width: 1200px)': {
			fontSize: '80px',
			width: '160px',
			height: '160px',
			margin: '-60px 20px 5px 40px',
		},
	},
	years: {
		position: 'relative',
		zIndex: '2',
		'& > p': {
			fontWeight: theme.fontWeight.normal,
			position: 'absolute',
			whiteSpace: 'nowrap',
			backgroundColor: '#ffffffd9',
			borderRadius: '5px',
			padding: '2px 15px',
			'& a': {
				color: 'inherit',
				cursor: 'pointer',
				'&:hover': {
					textDecoration: 'none',
				},
			},
			marginLeft: '20px',
			top: '-70px',
			'@media screen and (min-width: 768px)': {
				marginLeft: '0px',
				top: '-90px',
			},
			'@media screen and (min-width: 992px)': {
				top: '-80px',
			},
			'@media screen and (min-width: 1200px)': {
				top: '-95px',
			},
		},
	},
	yearsName: {
		flexGrow: '1',
		color: '#fff',
		padding: '7px 20px 0px 20px',
		'@media screen and (min-width: 768px)': {
			padding: '0px',
			marginRight: '240px',
		},
		'@media screen and (min-width: 992px)': {
			marginRight: '340px',
		},
		'& > h1': {
			padding: '4px 0px',
			fontWeight: theme.fontWeight.normal,
			color: '#fff',
			fontSize: '18px',
			'@media screen and (min-width: 992px)': {
				fontSize: '20px',
			},
			'@media screen and (min-width: 1200px)': {
				fontSize: '24px',
			},
		},
		'& > :last-child': {
			padding: '2px 0px 4px',
			fontSize: '10px',
			'@media screen and (min-width: 992px)': {
				fontSize: '13px',
			},
			'@media screen and (min-width: 1200px)': {
				fontSize: '16px',
			},
		},
	},
	bullet: {
		marginLeft: '15px',
		marginRight: '15px',
	},
	createPlan: {
		marginLeft: '10px',
		marginRight: '260px',
		'@media screen and (min-width: 992px)': {
			marginRight: '260px',
		},
		'@media screen and (min-width: 1200px)': {
			marginRight: '280px',
		},
		'& a': {
			fontSize: '10px',
			'@media screen and (min-width: 992px)': {
				fontSize: '12px',
			},
			'@media screen and (min-width: 1200px)': {
				fontSize: '14px',
			},
		},
	},
	acceptanceRate: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'column',
		'@media screen and (min-width: 768px)': {
			position: 'absolute',
			right: '0px',
			zIndex: '2',
			top: '110px',
		},
		'@media screen and (min-width: 992px)': {
			top: '130px',
		},
		'@media screen and (min-width: 1200px)': {
			top: '290px',
		},
		'& > div:first-child': {
			backgroundColor: '#ffffff',
			textAlign: 'center',
			'& strong': {
				fontSize: '15px',
				'@media screen and (min-width: 992px)': {
					fontSize: '18px',
				},
			},
			width: '100%',
			padding: '20px 20px 0px 20px',
			'@media screen and (min-width: 768px)': {
				width: '220px',
				boxShadow: '0px 4px 8px #00000020',
				marginRight: '10px',
				borderRadius: '10px',
			},
			'@media screen and (min-width: 992px)': {
				// height: '760px',
				width: '300px',
				padding: '30px 40px 10px',
				marginRight: '20px',
			},
			'@media screen and (min-width: 1200px)': {
				marginRight: '30px',
			},
		},
	},
	claimProfile: {
		textAlign: 'center',
		marginTop: '20px',
		'@media screen and (min-width: 768px)': {
			marginRight: '10px',
			width: '220px',
		},
		'@media screen and (min-width: 992px)': {
			marginRight: '30px',
			width: '300px',
		},
	},
	factsValue: {
		color: '#2e92d2',
		fontWeight: theme.fontWeight.bold,
		paddingTop: '0',
		fontSize: '17px',
		paddingBottom: '12px',
		'@media screen and (min-width: 768px)': {
			fontSize: '20px',
			paddingBottom: '20px',
		},
		'&$big': {
			paddingBottom: '12px',
			fontSize: '22px',
			lineHeight: '26px',
			'@media screen and (min-width: 768px)': {
				paddingBottom: '20px',
				fontSize: '28px',
				lineHeight: '32px',
			},
		},
	},
	big: {},
	social: {
		'& svg': {
			width: '40px',
			height: '40px'
		},
		paddingBottom: '20px'
	},
	inOutStateChooser: {
		display: 'flex',
		justifyContent: 'space-between',
		borderRadius: '12px',
		backgroundColor: '#e8e8e8',
		cursor: 'pointer',
		'& > *': {
			flexGrow: '1',
			flexBasis: '50%',
			fontSize: '13px',
		},
	},
	inOutStateActive: {
		color: '#fff',
		borderRadius: '12px',
		backgroundColor: '#2e92d2',
	},
	inOutStateSpace1: {
		marginTop: '6px',
		marginBottom: '15px',
	},
	inOutStateSpace2: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	live: {
		'&:before': {
			content: '""',
			border: '3px solid #f00',
			borderRadius: '3px',
			bottom: '5px',
			marginRight: '5px',
			position: 'relative',
			display: 'inline-flex',
		},
	},
	alumni: {
		display: 'flex',
		overflow: 'hidden',
		flexWrap: 'wrap',
		flexDirection: 'column',
		alignContent: 'flex-start',
		'& > div': {
			width: '240px',
			height: '240px',
			backgroundColor: '#ffff8f',
		},
		'& img': {
			pointerEvents: 'none'
		},
	},
	heading: {
		color: '#555',
		fontWeight: theme.fontWeight.normal,
		textAlign: 'left',
		marginTop: '30px',
		fontSize: '20px',
		lineHeight: '22px',
		'@media screen and (min-width: 768px)': {
			fontSize: '24px',
			lineHeight: '26px',
		},
		'@media screen and (min-width: 992px)': {
			fontSize: '30px',
			lineHeight: '36px',
		},
		'@media screen and (min-width: 1200px)': {
			fontSize: '36px',
			lineHeight: '40px',
		},
		'& > div:last-child': {
			width: '180px',
			marginBottom: '10px',
		},
		'h3&': {
			marginTop: '15px',
			fontSize: '16px',
			lineHeight: '18px',
			'@media screen and (min-width: 768px)': {
				fontSize: '20px',
				lineHeight: '22px',
			},
			'@media screen and (min-width: 992px)': {
				fontSize: '26px',
				lineHeight: '30px',
			},
			'@media screen and (min-width: 1200px)': {
				fontSize: '30px',
				lineHeight: '34px',
			},
		},
	},
	section: {
		overflow: 'auto',
		color: '#444',
		marginLeft: '0px',
		padding: '5px 20px',
		fontSize: '13px',
		'@media screen and (min-width: 768px)': {
			padding: '10px 20px 10px 30px',
			fontSize: '16px',
		},
		'@media screen and (min-width: 992px)': {
			padding: '10px 30px 10px 60px',
			fontSize: '17px',
		},
		'& p:last-of-type': {
			paddingBottom: '20px',
		},
		'& h3': {
			color: '#444',
		},
		'& $row': {
			'@media screen and (max-width: 767px)': {
				flexDirection: 'column',
				alignItems: 'center',
			},
		},
	},
	subSection: {
		fontSize: '14px',
	},
	sectionShadow: {
		margin: '20px 30px',
		'& > div': {
			padding: '20px 30px',
			border: '1px solid #eee',
			borderRadius: '15px',
			boxShadow: '0px 5px 10px #00000028',
			'& > $heading': {
				marginTop: '10px',
			},
		},
		'& $row': {
			'@media screen and (max-width: 767px)': {
				flexDirection: 'column',
				alignItems: 'center',
			},
		},
	},
	moreButton: {
		display: 'flex',
		justifyContent: 'center',
		// paddingRight: '50px',
	},
	prevNextButtons: {
		display: 'flex',
		justifyContent: 'space-between',
		'& > a': {
			minWidth: '100px',
			fontSize: '0.8em',
			'@media screen and (min-width: 480px)': {
				fontSize: '1em',
			},
			'&:first-child': {
				marginRight: '5px',
				'& > i.ion-chevron-left': {
					marginRight: '5px',
					'@media screen and (min-width: 480px)': {
						marginRight: '10px',
					},
				},
			},
			'&:last-child': {
				marginLeft: '5px',
				'& > i.ion-chevron-right': {
					marginLeft: '5px',
					'@media screen and (min-width: 480px)': {
						marginLeft: '10px',
					},
				},
			},
		},
	},
	prevNextButtonsLoanCalculator: {
		padding: '25px 0px 0 0px',
	},
	ratings: {
		marginTop: '30px !important',
		justifyContent: 'space-between !important',
		'& > div': {
			fontSize: '20px',
			width: '100%',
			cursor: 'pointer',
			textAlign: 'left !important',
			'@media screen and (min-width: 768px)': {
				width: 'initial',
				maxWidth: '280px',
			},
		},
	},
	ratingNumber: {
		fontSize: '16px'
	},
	aboutSection: {
		paddingRight: '10px',
		'& p:last-of-type': {
			paddingBottom: '6px',
		},
		'@media screen and (min-width: 768px)': {
			marginRight: '240px',
		},
		'@media screen and (min-width: 992px)': {
			marginRight: '340px',
		},
	},
	locationSection: {
		// marginRight: '300px',
		overflow: 'hidden',
		'& p': {
			'@media screen and (min-width: 768px)': {
				marginRight: '240px',
			},
			'@media screen and (min-width: 992px)': {
				marginRight: '340px',
			},
		},
	},
	graduationCharts: {
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: '20px',
		'@media screen and (min-width: 768px)': {
			flexDirection: 'row',
		},
		justifyContent: 'space-evenly',
		'& > div': {
			textAlign: 'center',
			fontSize: '16px',
			'@media screen and (min-width: 768px)': {
				fontSize: '20px',
			},
		},
	},
	mapLink: {
		display: 'inline-block',
		marginLeft: '10px',
		'& > i': {
			fontSize: '20px',
			'@media screen and (min-width: 768px)': {
				fontSize: '28px',
			},
		},
	},
	centered: {
		textAlign: 'center',
	},
	row: {
		display: 'flex',
	},
	grow: {
		flexGrow: '1',
	},
	alignCenter: {
		alignItems: 'center',
	},
	equalSize: {
		justifyContent: 'space-evenly',
		alignItems: 'center',
		margin: '0 auto',
		'& > div': {
			flexGrow: '1',
			textAlign: 'center',
			flexBasis: '50px',
			'@media screen and (min-width: 768px)': {
				flexBasis: '180px',
			},
		},
	},
	acceptanceBlock: {
		display: 'flex',
		flexDirection: 'column',
	},
	acceptanceNumbers: {
		flexGrow: '1',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& > div': {
			padding: '10px',
			textAlign: 'center',
		},
	},
	whenLarge: {
		display: 'none',
		'@media screen and (min-width: 768px)': {
			display: 'block',
		},
	},
	largeNumber: {
		color: '#2e92d2',
		fontSize: '28px',
		lineHeight: '30px',
		'@media screen and (min-width: 992px)': {
			fontSize: '36px',
			lineHeight: '38px',
		},
	},
	admissionChance: {
		justifyContent: 'space-evenly',
		'& > div': {
			width: '100%',
		},
		'& > div:first-child': {
			flexGrow: '1',
			flexBasis: '150px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: '16px',
			fontWeight: theme.fontWeight.normal,
		},
		'& > div:last-child': {
			flexGrow: '1',
			flexBasis: '300px',
		},
	},
	applicationFee: {
		padding: '20px 20px 10px',
		margin: '20px auto 0',
		maxWidth: '260px',
		'@media screen and (min-width: 992px)': {
			maxWidth: '320px',
		},
		'@media screen and (min-width: 1200px)': {
			margin: '20px auto 0',
		},
		'& strong': {
			fontSize: '22px',
			fontWeight: theme.fontWeight.normal,
		},
		'& $row': {
			marginTop: '20px',
		},
		'& $largeNumber': {
			color: '#35bfe5',
		},
	},
	shadowBox: {
		'@media screen and (min-width: 768px)': {
			borderRadius: '10px',
			boxShadow: '0px 2px 10px #00000048',
		},
	},
	requirementsTable: {
		marginRight: '15px',
		'& > div': {
			display: 'flex',
			// flexDirection: 'column',
			alignItems: 'center',
			padding: '10px 0',
			'&:not(:first-child)': {
				borderTop: '1px solid #d9d9d9',
			},
			'& > div:first-child': {
				paddingRight: '20px',
				'& > img': {
					width: '20px',
				},
			},
			'& > div:nth-child(2)': {
				flexGrow: '1',
				fontWeight: theme.fontWeight.normal,
				textAlign: 'left',
			},
			'& > div:last-child': {
				fontStyle: 'italic',
			},
		},
	},
	emphasis: {
		color: '#015283',
		fontSize: '18px',
		lineHeight: '20px',
		'@media screen and (min-width: 992px)': {
			fontSize: '21px',
			lineHeight: '24px',
		},
		'@media screen and (min-width: 1200px)': {
			fontSize: '24px',
			lineHeight: '27px',
		},
	},
	deadlines: {
		marginTop: '25px',
		marginBottom: '25px',
		'@media screen and (min-width: 768px)': {
			padding: '15px 20px',
		},
		'& table': {
			fontSize: '0.7em',
			'@media screen and (min-width: 480px)': {
				fontSize: '1em',
			},
			width: '100%',
			// '& thead': {
			// 	backgroundColor: '#eee',
			// },
			'& tr:first-child': {
				borderTop: 'none',
			},
			'& tbody tr': {
				borderTop: '1px solid #eee',
			},
			'& th, & td': {
				// width: '25%',
				padding: '5px',
				lineHeight: '15px',
				'@media screen and (min-width: 480px)': {
					lineHeight: '20px',
				},
				'@media screen and (min-width: 768px)': {
					lineHeight: '30px',
				},
			},
		},
	},
	catalogFiles: {
		'@media screen and (min-width: 768px)': {
			width: '70%',
		},
		'& > a': {
			padding: '10px 15px 10px 0px',
			width: 'fit-content',
			'& > svg': {
				display: 'inline-block',
				verticalAlign: 'bottom',
				paddingRight: '15px',
				width: '35px !important',
				height: '30px',
			},
		},
	},
	admissionResourcesValue: {
		color: '#458fcb',
		display: 'inline',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	tuitionState: {
		maxWidth: '600px',
	},
	loanCalculatorLink: {
		textAlign: 'center',
		'& a': {
			fontSize: '20px',
		},
	},
	financialAid: {
		flexGrow: '1',
		'& > div': {
			flexWrap: 'wrap',
			// width: '33%',
			paddingTop: '30px',
			alignItems: 'flex-start',
			'& > div': {
				maxWidth: '180px',
			},
		},
	},
	costProjection: {
		margin: '0px -5px',
		'@media screen and (min-width: 768px)': {
			maxWidth: '545px',
			margin: '20px auto 0',
			padding: '10px 25px',
		},
	},
	center: {
		textAlign: 'center',
	},
	bordered: {
		'& > div': {
			'@media screen and (min-width: 768px)': {
				borderLeft: '1px solid #d9d9d9',
			},
			'&:first-child': {
				borderLeft: 'none',
			},
		},
	},
	tinyBorder: {
		flexGrow: '0 !important',
		flexBasis: '0 !important',
		height: '50px',
		maxWidth: '0px',
		borderLeft: '1px solid #d9d9d9',
	},
	smallBorder: {
		flexGrow: '0 !important',
		flexBasis: '0 !important',
		height: '100px',
		maxWidth: '0px',
		borderLeft: '1px solid #d9d9d9',
	},
	academicCalendar: {
		'& $row': {
			marginTop: '10px',
			marginBottom: '10px',
			'@media screen and (min-width: 768px)': {
				marginTop: '40px',
				marginBottom: '30px',
			},
		},
	},
	faculty: {
		'& $row': {
			alignItems: 'center',
		},
	},
	ethnicityGender: {
		flexDirection: 'column',
		'@media screen and (min-width: 992px)': {
			flexDirection: 'row',
		},
	},
	housingTable: {
		flexGrow: '1',
		'& > $row': {
			maxWidth: '500px',
			'& > div': {
				flexGrow: '1',
				maxWidth: '250px',
				textAlign: 'center',
				'@media screen and (min-width: 768px)': {
					textAlign: 'left',
				},
				'&:first-child': {
					fontWeight: theme.fontWeight.normal,
					'@media screen and (min-width: 768px)': {
						paddingRight: '25px',
					},
				},
				'& p:last-of-type': {
					padding: '3px 0',
					'@media screen and (min-width: 768px)': {
						paddingBottom: '10px',
					},
				},
			},
		},
	},
	servicesTable: {
		flexGrow: '1',
		fontSize: '0.8em',
		'& > div:first-child': {
			padding: '10px 20px',
			borderBottom: '1px solid #d9d9d9',
			marginBottom: '10px',
		},
		'& > $row': {
			padding: '0 20px',
			width: '100%',
			flexDirection: 'row',
			'& > div': {
				padding: '3px 0',
				'&:first-child': {
					flexGrow: '1',
					// width: '250px',
					paddingRight: '10px',
				},
				'&:last-child': {
					textAlign: 'center',
					fontSize: '0.8em',
					'& > img': {
						width: '20px',
					},
					width: '30px',
					flexShrink: '0',
				},
			},
		},
	},
	activitiesTable: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '25px',
		padding: '10px 0',
		'& > $row': {
			flexDirection: 'row',
			padding: '0px 20px',
			width: '100%',
			maxWidth: '500px',
			margin: '0 auto',
			'@media screen and (min-width: 768px)': {
				width: '50%',
				maxWidth: 'none',
			},
			'& > div': {
				padding: '10px 0px',
			},
			'& > *:first-child': {
				flexGrow: '1',
				paddingRight: '10px',
				borderTop: '1px solid #d9d9d9',
			},
			'& > *:last-child': {
				width: '30px',
				flexShrink: '0',
				borderTop: '1px solid #d9d9d9',
			},
			'&:first-child > div, &:nth-child(2) > div': {
				borderTop: 'none',
			},
			'& img': {
				width: '20px',
			},
		},
	},
	disabled: {
		color: '#cecece',
	},
	sports: {
		'& > div': {
			padding: '0px 10px',
		},
		'& img': {
			width: '60px',
		},
	},
	reviewsContainer: {
		position: 'relative',
		padding: '10px 0',
	},
	reviewsCount: {
		position: 'absolute',
		top: '-50px',
		right: '20px',
		color: '#ffa951',
		fontSize: '18px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		'& > span': {
			color: '#888',
			marginRight: '10px',
		},
	},
	filters: {
		fontWeight: theme.fontWeight.normal,
		padding: '20px 25px 0 0',
		'& > .btn': {
			width: '100%',
			margin: '0 auto 0px',
		},
		textAlign: 'center',
		// alignItems: 'center',
		// '& > $row': {
		// 	flexWrap: 'wrap',
		// 	justifyContent: 'center',
		// },
		// '& > div:first-child': {
		// 	color: '#1691b6c5'
		// }
	},
	filter: {
		cursor: 'pointer',
		padding: '5px 30px',
		backgroundColor: '#1691b61a',
		borderRadius: '5px',
		marginTop: '15px',
		marginLeft: '15px',
		fontSize: '14px',
		fontWeight: theme.fontWeight.light,
		'&$active': {
			color: '#fff',
			backgroundColor: '#1691b6c5',
		},
	},
	categoryFilter: {
		margin: '50px 0 15px',
		textAlign: 'left',
	},
	reviews: {
		paddingTop: '20px',
	},
	active: {},
	review: {
		borderRadius: '10px',
		boxShadow: '0px 2px 10px #00000048',
		margin: '20px 0px',
		padding: '20px',
		'& p, & p:last-of-type': {
			padding: '6px 0',
		},
		'&:last-child': {
			paddingBottom: '0 !important',
		},
	},
	timeAgo: {
		color: '#888',
		fontSize: '0.8em',
	},
	author: {
		color: '#888',
		fontSize: '0.8em',
	},
	reviewTitle: {
		paddingBottom: '5px',
	},
	reviewStars: {
		marginTop: '10px',
		borderTop: '1px solid #ddd',
		paddingTop: '10px',
		'& > div': {
			maxWidth: '300px',
			margin: '5px 0',
			'& > :first-child': {
				fontWeight: theme.fontWeight.normal,
			},
		},
	},
	reviewTotal: {
		padding: '12px 10px',
	},
	reviewRecommendPercent: {
		color: '#1691b6c5'
	},
	reviewsByRatingContainer: {
		paddingBottom: '50px',
	},
	reviewsByRating: {
		display: 'flex',
		padding: '5px 0px',
		'& > span': {
			width: '10px',
			color: '#1691b6c5',
			textAlign: 'center'
		},
		'& > span, $reviewsByRatingStar': {
			paddingTop: '4px',
		},
		'& $reviewsByRatingStar': {
			marginRight: '4px',
		},
		'&$active': {
			'& $reviewsByRatingProgress': {
				'& > div:first-child': {
					background: '#1691b6c5'
				}
			}
		}
	},
	reviewsByRatingStar: {},
	reviewsByRatingProgress: {
		position: 'relative',
		width: '220px',
		height: '30px',
		border: `1px solid ${theme.colors.gray[2]}`,
		borderRadius: '10px',
		'& > div:first-child': {
			height: '28px',
			borderRadius: '9px',
			background: theme.colors.gray[2],
		},
		'& > div:last-child': {
			position: 'absolute',
			top: '2px',
			right: '10px'
		}
	},
	reviewsNewHeading: {
		color: '#555',
		margin: '30px 10px 10px',
		fontSize: '26px',
		lineHeight: '28px',
	},
	reviewsNewSubHeading: {},
	reviewsNewOverall: {
		marginTop: '10px',
		justifyContent: 'center',
		display: 'flex',
		'& label': {
			width: 'auto'
		}
	},
	reviewsNewRatings: {
		display: 'flex',
		justifyContent: 'space-around'
	},
	reviewsNewLine: {
		borderWidth: '2px'
	},
	reviewsNewSubmit: {
		display: 'flex',
		justifyContent: 'center',
		'& button': {
			minWidth: '150px'
		}
	},
	reviewsNewTextarea: {
		color: 'red',
		'& label textarea': {
			fontSize: 'inherit',
			fontWeight: theme.fontWeight.normal
		}
	},
	reviewsNewRecommendRow: {
		display: 'flex',
		margin: '30px 0px',
		'& > div:first-child': {
			justifyContent: 'center',
			display: 'flex',
			flexDirection: 'column'
		}
	},
	reviewsNewRecommend: {
		width: '250px',
		marginLeft: '20px',
		'& > div > div': {
			display: 'flex',
			justifyContent: 'space-around',
			'& button': {
				minWidth: '100px',
				color: '#ccc',
				backgroundColor: '#fff',
				borderColor: '#ccc',
				padding: '5px 0px 0px',
				'&:focus, &:active:focus, &.active:focus': {
					outline: 'none'
				},
				'&.active, &.active:focus': {
					// color: '#1691b6c5',
					// backgroundColor: '#fff',
					// borderColor: '#ccc',
					// boxShadow: 'none'
				},
				'& svg': {
					height: '34px'
				}
			}
		}
	},
	reviewsNewConnection: {
		'& > div > div': {
			display: 'flex',
			justifyContent: 'space-around',
			marginBottom: '20px',
			'& button': {
				minWidth: '220px',
				'&:focus, &:active:focus, &.active:focus': {
					outline: 'none'
				}
			}
		}
	},
	marginBottom20: {
		marginBottom: '20px'
	}
}));

export default useStyles;
