export const smallHeight = 40 + 36;
export const largeHeight = 45 + 36;
export const originalSmallHeight = 40;
export const originalLargeHeight = 45;

const styles = theme => ({
	header: {
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#fff',
		display: 'flex',
		flex: '1 0 auto',
		// flexWrap: 'wrap',
		minHeight: `${originalSmallHeight}px`,
		maxHeight: `${originalSmallHeight * 2}px`,
		position: 'relative',
		zIndex: 20,
		'@media screen and (min-width: 768px)': {
			flexWrap: 'nowrap',
			height: `${originalSmallHeight}px`,
		},
		'@media screen and (min-width: 992px)': {
			height: `${originalLargeHeight}px`,
			width: '100%',
			maxWidth: '1400px'
		},
		'@media screen and (min-width: 1200px)': {
			height: `${originalLargeHeight + 10}px`,
		},
		'& $userNavigationItem': {
			color: '#555',
			'&:hover, &:active, &:focus': {
				textDecoration: 'none'
			}
		}
	},
	middleMenu: {
		position: 'absolute',
		left: '138px',
		'@media screen and (min-width: 992px)': {
			left: '145px',
			justifyContent: 'space-evenly',
			width: '350px',
		},
		'@media screen and (min-width: 1170px)': {
			width: 'calc(100% - 760px)',
		},
		display: 'none',
		'@media screen and (min-width: 820px)': {
			display: 'flex',
		},
		height: '100%',
		alignItems: 'center',
		flexGrow: '1',
		flexWrap: 'wrap',
		// overflow: 'hidden',
		'& > a, & > span': {
			fontSize: '15px',
			marginLeft: '40px',
			'@media screen and (min-width: 992px)': {
				marginLeft: '0px',
			},
			// textTransform: 'uppercase',
		},
		'& > span': {
			color: theme.colors.primary,
		},
	},
	searchMenu: {
		display: 'none',
		'@media screen and (min-width: 560px)': {
			display: 'flex',
		},
		height: '100%',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flexGrow: '1',
		flexWrap: 'wrap',
	},
	menuLink: {
		fontWeight: theme.fontWeight.normal,
		color: '#555',
		display: 'none',
		'&:nth-child(1), &:nth-child(2)': {
			'@media screen and (min-width: 870px)': {
				display: 'block',
			},
		},
		'&:nth-child(3)': {
			'@media screen and (min-width: 1200px)': {
				display: 'block',
			},
		},
		'&:nth-child(4)': {
			'@media screen and (min-width: 1290px)': {
				display: 'block',
			},
		},
		// '&:nth-child(5)': {
		// 	'@media screen and (min-width: 1235px)': {
		// 		display: 'block',
		// 	},
		// },
	},
	toggle: {
		alignItems: 'center',
		borderLeft: '1px solid #e9ebee',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		height: `${originalSmallHeight - 2}px`,
		marginRight: -22,
		position: 'relative',
		width: 64,
		'@media screen and (min-width: 992px)': {
			display: 'none'
		}
	},
	iconBar: {
		backgroundColor: theme.colors.black,
		display: 'block',
		height: 2,
		left: '50%',
		marginLeft: '-11px',
		position: 'absolute',
		top: '50%',
		transition: '250ms ease-in-out',
		width: '22px',
		'&:nth-child(1)': {
			marginTop: '-6px'
		},
		'&:nth-child(2)': {
			marginTop: 0
		},
		'&:nth-child(3)': {
			marginTop: '6px'
		},
	},
	transparent: {
		backgroundColor: 'transparent',
		border: 'none',
		'& $userNavigationItem': {
			color: '#fff',
			'&:hover, &:active, &:focus': {
				textDecoration: 'none'
			},
		},
		'& $toggle': {
			borderLeft: 'none',
		},
		'& $iconBar': {
			backgroundColor: '#fff',
		},
		'& $menuLink': {
			color: '#fff',
		},
		'& $search': {
			'@media screen and (max-width: 767px)': {
				display: 'none',
			},
		},
		'&$header': {
			'@media screen and (max-width: 767px)': {
				height: `${originalSmallHeight}px`,
			},
		},
	},
	brand: {
		display: 'flex',
		alignItems: 'center',
		height: '40px',
		'@media screen and (min-width: 992px)': {
			height: '45px',
		},
	},
	search: {
		flex: '1 100%',
		'@media screen and (max-width: 767px)': {
			padding: '0',
			height: '40px',
			width: '100%',
			maxWidth: '100%',
		},
		'@media screen and (min-width: 768px)': {
			flex: '1 auto',
			order: 0,
		},
		padding: '0 20px',
		// margin: '0 auto',
		maxWidth: '540px',
		textAlign: 'left',
		order: 1,
	},
	searchLeft: {
		flexGrow: '1',
		marginLeft: '15px',
	},
	whenLarge: {
		display: 'none',
		'@media screen and (min-width: 575px)': {
			display: 'inline',
		},
	},
	navigation: {
		'@media screen and (max-width: 767px)': {
			height: '40px',
		},
		alignSelf: 'stretch',
		// flexGrow: '1',
		// justifyContent: 'flex-end',
		backgroundColor: 'transparent',
		display: 'flex',
		flexDirection: 'row',
		willChange: 'transform',
		zIndex: 1020,
	},
	open: {
		'& $navigation': {
			transform: 'scale(1, 1)'
		},
		'& $iconBar:nth-child(1)': {
			marginTop: 0,
			transform: 'rotate(45deg)'
		},
		'& $iconBar:nth-child(2)': {
			width: 0,
			opacity: 0
		},
		'& $iconBar:nth-child(3)': {
			marginTop: 0,
			transform: 'rotate(-45deg)'
		}
	},
	navigationItem: {
		alignItems: 'center',
		borderBottom: {
			color: '#999',
			style: 'solid',
			width: 1
		},
		color: '#000',
		display: 'flex',
		flex: '1 0 auto',
		fontFamily: theme.fonts.base,
		fontSize: 12,
		height: `${originalSmallHeight - 6}px`,
		padding: [0, 22],
		position: 'relative',
		transition: 'color 300ms linear',
		willChange: 'color',
		'&:before': {
			borderLeft: '4px solid transparent',
			borderRight: '4px solid transparent',
			borderTop: `4px solid ${theme.colors.primary}`,
			bottom: '50%',
			content: '""',
			display: 'block',
			height: 0,
			marginBottom: -2,
			position: 'absolute',
			right: 22,
			width: 0
		},
		'&:hover, &:active, &:focus': {
			color: theme.colors.white,
			textDecoration: 'none'
		},
		'@media screen and (min-width: 992px)': {
			border: 'none',
			flex: 'none',
			fontSize: 18,
			padding: '10px 15px',
			'&:before': {
				display: 'none'
			},
			'&+$navigationItem': {
				marginLeft: '5px'
			}
		}
	},
	userNavigationItem: {},
	userNavigation: {
		position: 'relative',
		alignItems: 'center',
		backgroundColor: 'transparent',
		display: 'flex',
		flexDirection: 'row'
	},
});

export default styles
