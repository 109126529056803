import React from 'react'
import {createUseStyles} from 'react-jss'
import {url} from '../layout/ukraineBanner'
import { ReactComponent as UkraineFlag } from '../../assets/images/ukraine_flaggg.svg'

const useStyles = createUseStyles(theme => ({
	container: {
		display: 'flex',
		color: theme.colors.white,
		alignItems: 'center',
		fontSize: '24px',
		padding: '6px',
		width: '100%',
		maxWidth: '430px',
		'@media screen and (min-width: 768px)': {
			maxWidth: '390px',
		},
		'@media screen and (min-width: 992px)': {
			maxWidth: '560px',
		},
		'@media screen and (min-width: 1200px)': {
			maxWidth: '590px',
		},
		'& > div': {
			display: 'flex',
			flexDirection: 'column',
			marginRight: '40px'
		}
	},
	button: {
		color: 'white',
		borderRadius: '20px',
		marginTop: '20px',
		textTransform: 'uppercase',
		background: 'none',
		borderColor: 'white',
		width: '110px',
		'&:hover': {
			color: 'white',
			background: 'none',
			borderColor: 'white'
		}
	}
}));

const UkraineBanner = () => {
	const classes = useStyles();
	return <div className={classes.container}>
		<div>
			Stand with Ukraine
			<a href={url} target="_blank" className={`btn ${classes.button}`}>Donate</a>
		</div>
		<UkraineFlag/>
	</div>;
}

export default UkraineBanner;
