import React from 'react'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

const styles = (theme) => ({
	suggestion: {
		'& span': {
			width: '70px',
			'@media screen and (min-width: 992px)': {
				width: '100px',
			},
			flexShrink: '0',
			fontWeight: theme.fontWeight.normal,
		},
		'& a': {
			color: '#555',
			display: 'block',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			width: '100%',
			padding: '6px 0',
			'@media screen and (min-width: 992px)': {
				padding: '8px 0'
			}
		},
		'&$current': {
			'& a': {
				color: '#fff'
			},
			backgroundColor: theme.colors.primary
		},
		'&:hover': {
			backgroundColor: '#f7f7f7',
			'&$current': {
				backgroundColor: theme.colors.primary
			}
		},
		fontFamily: theme.fonts.base,
		lineHeight: 1,
		alignItems: 'center',
		backgroundColor: '#fff',
		borderBottom: '1px solid transparent',
		'&$last': {
			borderBottom: '1px solid #c6cad0',
			'& a': {
				padding: '6px 0 9px',
				'@media screen and (min-width: 992px)': {
					padding: '13px 0 11px',
				}
			},
		},
		cursor: 'pointer',
		display: 'flex',
		fontSize: '12px',
		height: '40px',
		padding: '0 0 0 10px',
		'@media screen and (min-width: 992px)': {
			fontSize: '16px',
			height: '50px',
			padding: '0 0 0 15px',
		}
	},
	current: {},
	last: {},
	suggestionText: {
		'$suggestionRoot + &': {
			paddingTop: '4px',
			fontSize: '11px',
			'@media screen and (min-width: 992px)': {
				fontSize: '14px',
			}
		},
	},
	suggestionRoot: {
		fontWeight: theme.fontWeight.normal,
	},
	suggestionMore: {
		fontSize: '10px',
		marginLeft: '0px',
		'@media screen and (min-width: 700px)': {
			marginLeft: '70px',
		},
		'@media screen and (min-width: 992px)': {
			fontSize: '13px',
			marginLeft: '100px',
		},
		'& > i': {
			verticalAlign: 'super',
			color: '#3e8bca',
		},
		'& > b': {
			marginRight: '15px',
			fontSize: '24px',
			color: '#999',
		},
	},
});
const useStyles = createUseStyles(styles);

const SuggestionsItem = ({index, sectionTitle, title, rootTitle, moreTitle, path, current, onClick}) => {
	const classes = useStyles();
	return (
		<li className={classNames(classes.suggestion, {[classes.current]: current, [classes.last]: moreTitle})}>
			{sectionTitle && (
				<span>
					{index === 0 && <>{sectionTitle}</>}
				</span>
			)}
			<Link to={path} onClick={onClick}>
				{moreTitle && <div className={classes.suggestionMore}>
					<b className="ion-ios-search-strong"/>
					<i>{moreTitle}</i>
				</div>}
				{rootTitle && <div className={classes.suggestionRoot}>{rootTitle}</div>}
				{title && <div className={classes.suggestionText}>{title}</div>}
			</Link>
		</li>
	);
};

export default SuggestionsItem;
