import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {createUseStyles} from 'react-jss'

import routesHelper, {goalNewPath} from '@prepler/core/lib/routesHelper'

import pic1 from '../../assets/images/forStudentsHome/pic1h.png'
import pic1x2 from '../../assets/images/forStudentsHome/pic1hx2.png'
import pic2 from '../../assets/images/forStudentsHome/pic2.png'
import pic2x2 from '../../assets/images/forStudentsHome/pic2x2.png'
import pic3 from '../../assets/images/forStudentsHome/pic3.png'
import pic3x2 from '../../assets/images/forStudentsHome/pic3x2.png'

const useStyles = createUseStyles((theme) => ({
	section: {
		display: 'flex',
		flexDirection: 'column',
		'@media screen and (min-width: 992px)': {
			flexDirection: 'row',
		},
		maxWidth: '1360px',
		margin: '0px auto',
		padding: '0px 30px',
		alignItems: 'center',
		'& > div': {
			'& > p': {
				fontSize: '16px',
				textAlign: 'justify',
				margin: '20px 0',
			},
			'& > h2': {
				textAlign: 'left',
				fontSize: '23px',
				'@media screen and (min-width: 1200px)': {
					fontSize: '28px',
				},
				fontWeight: theme.fontWeight.light,
				lineHeight: '40px',
			},
		},
	},
	sectionText: {
		padding: '20px 0px 10px 0px',
		width: '100%',
		'@media screen and (min-width: 410px)': {
			padding: '60px 0px 30px 0px',
		},
		'@media screen and (min-width: 992px)': {
			paddingBottom: '60px',
			width: '50%',
		},
		'@media screen and (max-width: 991px)': {
			order: '1 !important',
		},
	},
	features: {
		textAlign: 'initial !important',
		padding: '3px 0',
		margin: '15px 0 0 !important',
		'& + &': {
			margin: '0 0 15px !important',
		},
		'& > strong': {
			color: '#666',
			textTransform: 'uppercase'
		},
		'& > span': {
			fontSize: '14px',
			marginRight: '12px',
			color: '#999',
			whiteSpace: 'nowrap',
			textDecoration: 'underline',
			'&:hover': {
				color: '#666'
			}
		}
	},
	sectionPicture: {
		textAlign: 'center',
		width: '100%',
		'@media screen and (min-width: 992px)': {
			width: '50%',
		},
		'@media screen and (max-width: 991px)': {
			order: '2 !important',
		},
		'& > img': {
			width: '100%',
		},
	},
	bottom: {
		'& $section': {
			alignItems: 'flex-end',
		},
	},
	odd: {
		backgroundColor: theme.colors.background,
		'& $sectionText': {
			order: '1',
			'@media screen and (min-width: 992px)': {
				paddingRight: '45px',
			},
		},
		'& $sectionPicture': {
			order: '2',
			'@media screen and (min-width: 992px)': {
				// paddingLeft: '35px',
			},
		},
	},
	even: {
		backgroundColor: theme.colors.backgroundGray,
		'& $sectionText': {
			order: '2',
			// textAlign: 'right',
			'@media screen and (min-width: 992px)': {
				paddingLeft: '45px',
			},
		},
		'& $sectionPicture': {
			order: '1',
			'@media screen and (min-width: 992px)': {
				// paddingRight: '35px',
			},
		},
	},
}));

const Sections = () => {
	const classes = useStyles();
	const authorized = useSelector(({auth: {authorized}}) => authorized);

	return (
		<>
			<section className={classNames(classes.odd)}>
				<div className={classes.section}>
					<div className={classNames(classes.sectionPicture)}>
						<img alt="Graduation Curve" src={pic3} srcSet={`${pic3x2} 2x`} style={{maxWidth: '500px', marginBottom: '20px'}}/>
					</div>
					<div className={classes.sectionText}>
						<h2>Get in the Fast Lane to Graduation by Understanding Degree Requirements</h2>
						<p>
							Not understanding your degree requirements and course sequencing,
							taking unnecessary courses, unawareness of alternatives (often
							easier courses) that satisfy the same requirement, lack of
							advising. All add time and expenses to your degree completion.
						</p>
						<p>
							Imagine knowing the steps you should take in advance - before
							registration, or even before applying to college, before you
							start veering off track, wasting irreplaceable
							time and accumulating more student debt.
						</p>
						<p className={classes.features}>
							<strong>Features</strong>
						</p>
						<p className={classes.features}>
							<span>Complete Major Requirements</span>{' '}
							<span>Course Sequencing</span>{' '}
							<span>Prerequisites Check</span>
						</p>
						{/*<Link to={authorized ? goalNewPath() : routesHelper.goalsPath()} className="btn btn-default">*/}
						{/*	Sample*/}
						{/*</Link>*/}
					</div>
				</div>
			</section>
			<section className={classNames(classes.even, classes.bottom)}>
				<div className={classes.section}>
					<div className={classNames(classes.sectionPicture)}>
						<img alt="Degree Plans" src={pic2} srcSet={`${pic2x2} 2x`} style={{maxWidth: '500px'}}/>
					</div>
					<div className={classes.sectionText}>
						<h2>Benefit from Calculating the Shortest Pathway to Your Degree</h2>
						<p>
							Prepler helps you visualize and experiment with various degree options.
							We enable students to make informed choices that help them create
							the shortest pathway towards graduation. Our product works for all
							students and can equally benefit: college applicants,
							freshmen, sophomores, juniors and seniors.
						</p>
						<p>
							By creating an intentional degree plan with us you will stay
							on course, graduate faster and avoid increasing your student debt.
						</p>
						<p className={classes.features}>
							<strong>Features</strong>
						</p>
						<p className={classes.features}>
							<span>Automated Degree Planner</span>{' '}
							<span>AI Course Mapping</span>{' '}
							<span>Customizable Dashboard</span>
						</p>
						{/*<Link to={routesHelper.goalsPath()} className="btn btn-default">*/}
						{/*	See Prepler AI at work*/}
						{/*</Link>*/}
					</div>
				</div>
			</section>
			<section className={classes.odd}>
				<div className={classes.section}>
					<div className={classNames(classes.sectionPicture)}>
						<img alt="Colleges" src={pic1} srcSet={`${pic1x2} 2x`} style={{maxWidth: '500px'}}/>
					</div>
					<div className={classes.sectionText}>
						<h2>Manage Your College Journey with Meaningful Analytics and AI</h2>
						<p>
							Harness the power of degree progression and campus course data.
							Increase your outcomes through making the right choices that often
							are time sensitive and can be very costly to you.
						</p>
						<p>
							Our Degree Planner gives you advice you’re looking for,
							in real-time. By keeping you informed of missteps and smart
							steps – before you start veering off track or before you
							face adding an avoidable semester.
						</p>
						<p className={classes.features}>
							<strong>Features</strong>
						</p>
						<p className={classes.features}>
							<span>Real-time Advising</span>{' '}
							<span>Progress Tracking</span>{' '}
							<span>At-Risk Class Alerts</span>
						</p>
						{/*<Link to={routesHelper.demoPath()} className="btn btn-default">*/}
						{/*	Request a Demo*/}
						{/*</Link>*/}
					</div>
				</div>
			</section>
		</>
	);
};

export default Sections;
