import injectSheet from 'react-jss'
// import defaultUniversity from '@prepler/core/lib/resources/default_university.jpg'

const styles = theme => ({
	collection: {
		padding: '40px 30px',
		minHeight: '213px',
		maxWidth: '1460px',
		width: '100%',
		margin: '0 auto',
		'& a:hover': {
			textDecoration: 'none'
		}
	},
	h2: {
		fontSize: '32px',
		fontWeight: theme.fontWeight.light,
		textAlign: 'center',
		lineHeight: '38px',
		color: 'initial',
	},
	h3: {
		fontSize: '15px',
		fontWeight: theme.fontWeight.normal,
		lineHeight: '1.5',
		textAlign: 'center',
		color: '#999',
	},
	h4: {
		padding: '0px 5px 30px',
		textAlign: 'center',
		fontSize: '21px',
		fontWeight: theme.fontWeight.normal,
		color: 'initial',
		'& a': {
			color: '#222',
			textDecoration: 'none'
		}
	},
	carousel: {
		marginTop: '25px',
	},
	placeholderTop: {
		position: 'relative',
		height: '250px',
		background: 'no-repeat center',
		backgroundSize: 'cover',
		// backgroundImage: `url('${defaultUniversity}')`,
		backgroundColor: '#eee',
	},
	placeholderBottom: {
		height: '70px',
		backgroundColor: theme.colors.darkBlue
	},
	placeholderGradient: {
		position: 'absolute',
		left: '0',
		right: '0',
		bottom: '0px',
		height: '130px',
		background: 'linear-gradient(to bottom, #00000000 0, #00000040 35%, #0000004d 100%)'
	},
	seeMore: {
		textAlign: 'center',
		marginTop: '2px'
	},
	logoImage: {
		maxWidth: '240px',
		width: '60%',
	},
	center: {
		textAlign: 'center',
	},
});

export default (component) => injectSheet(styles)(component);
